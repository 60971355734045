// =====================================================
// services/auth/AuthService.js
// =====================================================
// Hauptservice für die Authentifizierung
import { logger } from './Logger';

export class AuthService {
  constructor(options = {}) {
    this.tokenService = options.tokenService;
    this.cognitoService = options.cognitoService;
    this.realmService = options.realmService;
    this.apolloService = options.apolloService;

    // Optionen für Batch-Prozesse
    this.batchOptions = {
      maxBatchSize: options.maxBatchSize || 10,
      batchTimeout: options.batchTimeout || 50 // ms
    };

    // Status
    this.isAuthenticated = false;
    this.isAnonymous = true;
    this.isLoading = false;
    this.userId = null;
    this.userGroups = [];
    this.userInfo = null;
    this.idTokenPayload = null;
    this.hasOrganizerAccess = false;
    this.organizations = [];

    // Request-Batching
    this.pendingApiBatches = {};

    // EventBus für Auth-Ereignisse
    this.eventListeners = {};

    logger.debug('AuthService initialized');

    // Token-Refresh-Callback im TokenService registrieren
    if (this.tokenService) {
      this.tokenService.onTokenRefresh(this.refreshTokens.bind(this));
    }
  }

  // Auth-Status initialisieren
  async initialize() {
    try {
      logger.debug('Initializing auth service');
      this.isLoading = true;
      this.notifyStateChange();

      // Implementieren von Retry mit Backoff
      return await this.tokenService.retryWithBackoff(
        async () => {
          // Versuche, eine gültige Sitzung zu erhalten
          const session = await this.cognitoService.getSession();

          if (session) {
            // Auth-Daten aktualisieren
            await this.updateAuthData(session);

            // Bei Realm mit JWT anmelden
            await this.realmService.loginWithJwt(session.idToken.jwtToken);

            logger.debug(
              'Auth initialization successful with authenticated user'
            );
            this.isLoading = false;
            this.notifyStateChange();
            return true;
          } else {
            // Anonym anmelden, wenn keine Sitzung vorhanden ist
            await this.realmService.loginAnonymously();

            this.isAuthenticated = false;
            this.isAnonymous = true;
            this.isLoading = false;
            this.notifyStateChange();

            logger.debug('Auth initialization successful with anonymous user');
            return false;
          }
        },
        (error) => {
          // Nur bei Rate-Limit-Fehlern wiederholen
          return (
            error.name === 'TooManyRequestsException' ||
            (error.message && error.message.includes('rate'))
          );
        }
      );
    } catch (error) {
      logger.error('Error during auth initialization', error);

      // Fallback: anonym anmelden
      try {
        await this.realmService.loginAnonymously();

        this.isAuthenticated = false;
        this.isAnonymous = true;
      } catch (anonError) {
        logger.error('Failed to initialize anonymous login', anonError);
      }

      this.isLoading = false;
      this.notifyStateChange();
      return false;
    }
  }

  // Auth-Daten basierend auf Session aktualisieren
  async updateAuthData(session) {
    try {
      logger.debug('Updating auth data from session');

      const payload = session.idToken.payload;
      const groups = payload['cognito:groups'] || [];

      this.userId = payload['custom:userId'] || null;
      this.userGroups = groups;
      this.userInfo = session.user;
      this.idTokenPayload = payload;
      this.hasOrganizerAccess = this.checkOrganizerAccess(groups);
      this.organizations = this.extractOrganizationIds(groups);
      this.isAuthenticated = true;
      this.isAnonymous = false;

      this.notifyStateChange();

      // Organisationsnamen im Hintergrund abrufen
      if (this.organizations.length > 0) {
        this.fetchOrganizationNames(this.organizations);
      }

      logger.debug('Auth data updated successfully');
      return true;
    } catch (error) {
      logger.error('Error updating auth data', error);
      return false;
    }
  }

  // Login mit Benutzername und Passwort
  async login(username, password) {
    try {
      logger.debug('Login initiated');
      this.isLoading = true;
      this.notifyStateChange();

      // Bei Cognito anmelden
      const authResult = await this.cognitoService.login(username, password);

      // Auth-Daten aktualisieren
      await this.updateAuthData(authResult);

      // Bei Realm mit JWT anmelden
      await this.realmService.loginWithJwt(authResult.idToken.jwtToken);

      // Event für erfolgreichen Login auslösen
      this.notifyEvent('login:success', { userId: this.userId });

      this.isLoading = false;
      this.notifyStateChange();

      logger.debug('Login completed successfully');
      return authResult;
    } catch (error) {
      logger.error('Login error', error);

      // Event für fehlgeschlagenen Login auslösen
      this.notifyEvent('login:error', { error });

      this.isLoading = false;
      this.notifyStateChange();

      throw error;
    }
  }

  // Tokens aktualisieren
  async refreshTokens(forceRefresh = false) {
    try {
      if (this.isAnonymous && !forceRefresh) {
        logger.debug('Skip token refresh for anonymous user');
        return false;
      }

      logger.debug('Refreshing tokens');

      // Prüfen, ob das Cognito-Refresh-Token gültig ist
      const isCognitoValid = await this.cognitoService.isRefreshTokenValid();

      if (!isCognitoValid) {
        logger.debug('Cognito refresh token invalid, cannot refresh tokens');
        return false;
      }

      // Cognito-Sitzung aktualisieren
      const session = await this.cognitoService.refreshIdToken();

      // Auth-Daten aktualisieren
      const success = await this.updateAuthData(session);

      if (success) {
        // Realm-Token mit neuem JWT aktualisieren
        if (!this.isAnonymous && this.realmService.getCurrentUser()) {
          try {
            await this.realmService.loginWithJwt(session.idToken.jwtToken);
            logger.debug('Realm token refreshed with new Cognito token');
          } catch (realmError) {
            logger.error('Error updating Realm token', realmError);
            return false;
          }
        }

        logger.debug('Tokens refreshed successfully');
        return true;
      }

      logger.debug('Failed to update auth data with refreshed tokens');
      return false;
    } catch (error) {
      logger.error('Error refreshing tokens', error);

      // Prüfen auf abgelaufenes Refresh-Token
      if (
        error.toString().includes('Refresh Token has expired') ||
        error.toString().includes('Invalid refresh token')
      ) {
        logger.debug('Refresh token expired, logout required');
        // Event für abgelaufenes Token auslösen
        this.notifyEvent('token:expired', { error });
      }

      return false;
    }
  }

  // Sicherer Logout mit vollständiger Bereinigung
  async logout(redirectToLogin = false) {
    try {
      logger.debug('Performing secure logout');
      this.isLoading = true;
      this.notifyStateChange();

      // 1. Apollo-Client-Cache zurücksetzen (über Event)
      this.notifyEvent('apollo:resetCache');

      // 2. Realm-Benutzer abmelden
      await this.realmService.logout();

      // 3. Cognito-Benutzer abmelden
      await this.cognitoService.logout();

      // 4. Token-Cache leeren
      if (this.tokenService) {
        this.tokenService.clearAllTokens();
      }

      // 5. Lokale Zustände zurücksetzen
      this.isAuthenticated = false;
      this.isAnonymous = true;
      this.userId = null;
      this.userGroups = [];
      this.userInfo = null;
      this.idTokenPayload = null;
      this.hasOrganizerAccess = false;
      this.organizations = [];

      // 6. Anonym anmelden
      await this.realmService.loginAnonymously();

      // 7. Event für erfolgreichen Logout auslösen
      this.notifyEvent('logout:success');

      this.isLoading = false;
      this.notifyStateChange();

      // 8. Optional zur Login-Seite weiterleiten
      if (redirectToLogin) {
        logger.debug('Redirecting to login page');
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      }

      logger.debug('Secure logout completed');
      return true;
    } catch (error) {
      logger.error('Error during logout', error);

      // Bei Fehlern trotzdem Status zurücksetzen
      this.isAuthenticated = false;
      this.isAnonymous = true;
      this.isLoading = false;
      this.notifyStateChange();

      // Bei Fehlern trotzdem zur Login-Seite weiterleiten
      if (redirectToLogin) {
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      }

      return false;
    }
  }

  // Prüfen, ob der Benutzer Organisator-Zugriff hat
  checkOrganizerAccess(groups = []) {
    return groups.some(
      (group) =>
        group.startsWith('orga-admin-') || group.startsWith('event-admin-')
    );
  }

  // Organisations-IDs aus Benutzergruppen extrahieren
  extractOrganizationIds(groups = []) {
    return groups
      .filter((group) => group.startsWith('orga-admin-'))
      .map((group) => {
        const id = group.replace('orga-admin-', '');
        return { _id: id, name: '' }; // Initialisiere mit leeren Namen
      });
  }

  // Organisationsnamen abrufen (Batch-optimiert)
  async fetchOrganizationNames(orgs) {
    if (!orgs || orgs.length === 0) return;

    logger.debug(`Fetching names for ${orgs.length} organizations`);

    // Batch-Funktion erstellen
    const batchGetOrgNames = this.createBatchFunction(
      async (orgBatch) => {
        try {
          // Erstelle eine Kopie der aktuellen Organisationen
          const updatedOrgs = [...this.organizations];

          // Anfragen für alle Organisationen im Batch erstellen
          const promises = orgBatch.map(async (org) => {
            try {
              // Diese Funktion würde in deiner Implementierung stehen
              const response = await this.getOrganizationName(org._id);
              const orgName = response.organizationName;

              // Organisation im Array aktualisieren
              const index = updatedOrgs.findIndex((o) => o._id === org._id);
              if (index !== -1) {
                updatedOrgs[index] = {
                  ...updatedOrgs[index],
                  name: orgName
                };
              }

              return { id: org._id, name: orgName };
            } catch (error) {
              logger.error(
                `Error fetching name for organization ${org._id}`,
                error
              );
              return null;
            }
          });

          // Alle Anfragen ausführen
          const results = await Promise.allSettled(promises);

          // Erfolgreiche Ergebnisse filtern
          const successfulResults = results
            .filter((r) => r.status === 'fulfilled' && r.value)
            .map((r) => r.value);

          // Organisationen aktualisieren
          if (successfulResults.length > 0) {
            this.organizations = updatedOrgs;
            this.notifyStateChange();
          }

          return successfulResults;
        } catch (error) {
          logger.error('Error in organization name batch', error);
          throw error;
        }
      },
      this.batchOptions.maxBatchSize,
      this.batchOptions.batchTimeout
    );

    // Organisationen in Batches abrufen
    try {
      const results = await Promise.all(
        orgs.map((org) => batchGetOrgNames(org))
      );

      logger.debug(
        `Completed fetching organization names, received ${
          results.filter(Boolean).length
        } results`
      );
    } catch (error) {
      logger.error('Error fetching organization names', error);
    }
  }

  // API-Anfragen bündeln (Performance-Optimierung)
  createBatchFunction(processBatch, maxBatchSize = 10, timeout = 50) {
    const batches = {};
    let batchCounter = 0;

    return (item) => {
      return new Promise((resolve, reject) => {
        // Erstelle eine eindeutige Batch-ID
        const batchId = `batch_${Date.now()}_${batchCounter++}`;

        // Initialisiere den Batch, wenn er noch nicht existiert
        if (!batches[batchId]) {
          batches[batchId] = {
            items: [],
            callbacks: [],
            timeoutId: null
          };

          // Timeout-Funktion zum Verarbeiten des Batches
          batches[batchId].timeoutId = setTimeout(async () => {
            const { items, callbacks } = batches[batchId];
            delete batches[batchId];

            try {
              const results = await processBatch(items);

              // Ergebnisse an die entsprechenden Callbacks weitergeben
              if (Array.isArray(results)) {
                // Wenn die Ergebnisse als Array zurückgegeben werden
                items.forEach((item, index) => {
                  callbacks[index].resolve(results[index]);
                });
              } else {
                // Wenn die Ergebnisse als Objekt zurückgegeben werden
                callbacks.forEach((callback, index) => {
                  callback.resolve(results);
                });
              }
            } catch (error) {
              // Fehler an alle Callbacks weitergeben
              callbacks.forEach((callback) => {
                callback.reject(error);
              });
            }
          }, timeout);
        }

        // Item und Callbacks zum Batch hinzufügen
        batches[batchId].items.push(item);
        batches[batchId].callbacks.push({ resolve, reject });

        // Batch sofort verarbeiten, wenn maximale Größe erreicht ist
        if (batches[batchId].items.length >= maxBatchSize) {
          clearTimeout(batches[batchId].timeoutId);
          batches[batchId].timeoutId = setTimeout(() => {}, 0); // Sofort ausführen
        }
      });
    };
  }

  // Hilfsfunktion zum Abrufen eines Organisationsnamens
  // Diese Funktion muss an deine API angepasst werden
  async getOrganizationName(organizationId) {
    // Beispiel-Implementierung
    return {
      organizationId,
      organizationName: `Organization ${organizationId}`
    };
  }

  // Event Listener hinzufügen
  addEventListener(event, callback) {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }

    this.eventListeners[event].push(callback);

    // Cleanup-Funktion zurückgeben
    return () => {
      this.eventListeners[event] = this.eventListeners[event].filter(
        (cb) => cb !== callback
      );
    };
  }

  // Event auslösen
  notifyEvent(event, data = {}) {
    if (this.eventListeners[event]) {
      this.eventListeners[event].forEach((callback) => {
        try {
          callback(data);
        } catch (error) {
          logger.error(`Error in event listener for ${event}`, error);
        }
      });
    }
  }

  // Status-Änderung auslösen
  notifyStateChange() {
    this.notifyEvent('state:change', {
      isAuthenticated: this.isAuthenticated,
      isAnonymous: this.isAnonymous,
      isLoading: this.isLoading,
      userId: this.userId,
      userGroups: this.userGroups,
      hasOrganizerAccess: this.hasOrganizerAccess,
      organizations: this.organizations
    });
  }
}
