import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Conversations from './Conversations';

/**
 * EmailEditor-Komponente, die als Container für die Conversations-Komponente dient
 * @param {Object} props - Die Komponenteneigenschaften
 * @param {string} props.organizationId - Die ID der Organisation
 * @param {Function} props.onConversationSelected - Callback-Funktion, die aufgerufen wird, wenn eine Konversation ausgewählt wird
 * @param {Object} props.selectedEventInstance - Die ausgewählte EventInstance
 * @param {boolean} props.eventInstanceLoading - Gibt an, ob die EventInstance geladen wird
 * @returns {JSX.Element} - Die gerenderte Komponente
 */
function EmailEditor({
  organizationId
  // onConversationSelected,
  // selectedEventInstance,
  // eventInstanceLoading
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Conversations
        organizationId={organizationId}
        // onConversationSelected={onConversationSelected}
        // selectedEventInstance={selectedEventInstance}
        // eventInstanceLoading={eventInstanceLoading}
      />
    </Box>
  );
}

EmailEditor.propTypes = {
  organizationId: PropTypes.string
  // onConversationSelected: PropTypes.func,
  // selectedEventInstance: PropTypes.object,
  // eventInstanceLoading: PropTypes.bool
};

export default EmailEditor;
