const { gql } = require('@apollo/client');

export const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    _id
    completed
    status
    name
    started
    completedByTeamMember
    assignedToTeamMember
    assignedToUser
    creationDate
    description
    completedDate
    completedByUser
    dueDate
    completionPercentage
    organization
    referenceType
    referenceUrl
    createdByTeamMember
    createdBy
    startedDate
    referenceId
    collaboratingTeamMembers
    notes
    assignedToUser
    log {
      _id
      date
      user
      message
    }
    messages {
      _id
      date
      senderTeamMember
      senderUser
      senderName
      recipientTeamMember
      recipientUser
      recipientName
      message
    }
    superseedingTask
  }
`;
