import ArgonBox from 'components/ArgonBox';
import React from 'react';

export default function HorizontalDivider({
  color = 'rgba(255, 255, 255, .3)',
  mt = 2,
  mb = 3
}) {
  return (
    <ArgonBox
      mt={mt}
      mb={mb}
      style={{
        borderBottom: `1px solid ${color}`,
        width: '100%'
      }}
    />
  );
}
