/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Dropzone components
import Dropzone from 'dropzone';

// Dropzone styles
import 'assets/css/dropzone_button_only.css';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Custom styles for the ArgonDropzone
import ArgonDropzoneRoot from './ArgonDropzoneRoot';
import ArgonButton from 'components/ArgonButton';
import { getKbDocumentPresignedUploadUrl } from 'services/REST_API/ai/kb-manage-documents';

// ! prop {metaData} is stored in S3 with the file and required for the post-upload lambda function to match the file to the correct use / databse entity
// ! prop payloadPurpose tells the post-upload lambda function what to do with the file
// e.g.: metadata: { "eventId": "1234" } payloadPurpose: "event-image"
// metaData: { "eventId": "1234", workout: "5678" } payloadPurpose: "event-image"
const AiKnowledeBaseDropzone = ({
  clientId,
  apiKey,
  disabled,
  onFileAdded,
  options,
  label,
  variant = 'outlined',
  color = 'secondary',
  size = 'small'
}) => {
  const dropzoneRef = useRef();

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [dz, setDz] = useState({});

  // async function getPresignedS3Link(file) {
  //   const res = await getKbDocumentPresignedUploadUrl({
  //     clientId: '1234567890',
  //     apiKey: '1234567890',
  //     fileName: file.name,
  //     contentType: file.type,
  //     fileSize: file.size
  //   });

  //   console.log('result of getting presigned url', res);
  // }

  useEffect(() => {
    // get id token from session
    console.log('AiKnowledeBaseDropzone - useEffect');
    console.log('AiKnowledeBaseDropzone - clientId: ', clientId);
    console.log('AiKnowledeBaseDropzone - apiKey: ', apiKey);

    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    const dz = createDropzone();
    setDz(dz);

    dz.accept = function (file, done) {
      setUploading(true);

      file.status = Dropzone.ADDED;

      // get presigned link from api
      // console.log("accepted file", file);

      getKbDocumentPresignedUploadUrl({
        clientId: clientId,
        apiKey: apiKey,
        fileName: file.name,
        contentType: file.type,
        fileSize: file.size
      })
        .then((result) => {
          console.log('result of getting presigned url', result);
          file.uploadURL = result?.uploadUrl;
          file.documentProps = result?.document;
          done();
          // And process each file immediately
          setTimeout(() => dz.processFile(file));
        })
        .catch((err) => {
          done('Failed to get an S3 signed upload URL', err);
        });
    };

    // if (disabled) dz.disable();

    dz.on('sending', (file, xhr, formData) => {
      console.log('sending', file);

      let _send = xhr.send;
      xhr.send = function () {
        return _send.call(xhr, file);
      };
    });

    // Handler für den Upload-Fortschritt hinzufügen
    dz.on('uploadprogress', function (file, progress, bytesSent) {
      console.log('Upload progress:', progress, bytesSent);
      // // Stellen Sie sicher, dass file.upload existiert, bevor Sie versuchen, progress zu setzen
      // if (!file.upload) {
      //   file.upload = { progress: 0 };
      // }
      // file.upload.progress = progress;

      // // Fortschritt in Prozent in der State-Variable speichern

      if (file && file.upload) {
        file.upload.progress = progress;
        file.upload.bytesSent = bytesSent;
      } else {
        console.warn('File or file.upload is undefined', file);
      }

      const progressPercent = Math.round(progress);
      setUploadProgress(progressPercent);
    });

    dz.on('processing', (file) => {
      // dz.options.url = file.uploadURL;

      // console.log("processing", file);
      dz.options.url = file.uploadURL;
      dz.options.headers = {
        ...dz.options.headers,
        'Content-Type': file.type,
        'Content-Encoding': 'base64'
        // "X-Amz-Acl": "public-read",
      };
    });

    dz.on('success', (file) => {
      setUploading(false);
      setUploadProgress(0); // Fortschritt zurücksetzen
      console.log('upload successful', file);
      // console.log('New url', file.uploadURL.split('?')[0]);
      onFileAdded && onFileAdded(file.documentProps);
    });
    dz.on('successMultiple', (file) => {
      setUploading(false);
      setUploadProgress(0); // Fortschritt zurücksetzen
      onFileAdded && onFileAdded(file.uploadURL.split('?')[0], file.name);
      console.log('success multiple', file);
    });

    // Handler für Fehler hinzufügen
    dz.on('error', function (file, errorMessage, xhr) {
      console.error('Upload error:', errorMessage);
      setUploading(false);
      setUploadProgress(0); // Fortschritt zurücksetzen
    });

    return () => removeDropzone();
  }, []);

  return (
    <>
      <ArgonDropzoneRoot
        component="form"
        action="/file-upload"
        ref={dropzoneRef}
        className="form-control dropzone"
        style={{ height: '100%', display: 'none' }}>
        {/* <ArgonBox className="fallback">
        <ArgonBox component="input" name="file" type="file" multiple></ArgonBox>
      </ArgonBox> */}
      </ArgonDropzoneRoot>
      <ArgonButton
        variant={variant}
        color={color}
        size={size}
        onClick={() => {
          dropzoneRef.current.click();
        }}
        loading={uploading}
        loadingColor="secondary"
        disabled={disabled}>
        {uploading ? `${label} (${uploadProgress}%)` : label}
      </ArgonButton>
    </>
  );
};

// Typechecking props for the ArgonDropzone
AiKnowledeBaseDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AiKnowledeBaseDropzone;
