// contexts/AuthContext.js
import { createContext, useContext } from 'react';

// Auth Context erstellen
export const AuthContext = createContext(null);

// Custom Hook für einfachen Zugriff auf den Auth Context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
