import React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from 'components/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

import {
  Switch,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import selectData from 'options/selectData';
import ArgonDatePicker from 'components/ArgonDatePicker';

function OnlineQualifierSettings({ formik, formMinWidth = '200px' }) {
  const handleDatePickerChanged = async (fieldName, newValue) => {
    console.log('Setting date for: ', fieldName, newValue[0]);
    if (newValue.length > 0) {
      await formik.setFieldValue(fieldName, newValue[0], true);
    } else {
      console.error(
        'New picked date is an array with multiple date. Cannot update.'
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Table
      // sx={{ tableLayout: 'fixed' }}
      >
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: '200px' }} />
        </colgroup>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              Online Qualifier Settings
            </TableCell>
            <TableCell
              padding={[1, 1, 1, 0.5]}
              sx={{ width: formMinWidth }}></TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Enable video upload
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                    If set to yes, athletes can provide a link to a video to
                    prove their score. See workout for judging settings.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell
              align="end"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: '220px' }}>
              <Switch
                id="enableVideoUpload"
                name="enableVideoUpload"
                onChange={(event) => {
                  console.log('enableVideoUpload: ', event.target.checked);
                  formik.setFieldValue(
                    'enableVideoUpload',
                    event.target.checked
                  );
                }}
                checked={formik.values?.enableVideoUpload}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]} sx={{ verticalAlign: 'top' }}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Restrict video upload platforms
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  You can optionally restrict video upload to specific platforms
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox sx={{ width: formMinWidth, paddingLeft: '10px' }}>
                <List>
                  {selectData.videoPortals.map((option) => {
                    const isChecked =
                      formik.values.allowedVideoUploadPlatforms?.includes(
                        option.value
                      );

                    return (
                      <ListItem>
                        <ListItemText
                          primary={
                            <ArgonTypography
                              variant="caption"
                              color="secondary"
                              fontWeight="regular">
                              {option.label}
                            </ArgonTypography>
                          }
                        />
                        <ArgonBox mr={-1}>
                          <Switch
                            checked={isChecked}
                            onChange={(e) => {
                              const currentValues = [
                                ...(formik.values.allowedVideoUploadPlatforms ||
                                  [])
                              ];

                              if (e.target.checked) {
                                // Wenn "Do not restrict" ausgewählt wird, nur diesen Wert behalten
                                if (option.value === 'all') {
                                  formik.setFieldValue(
                                    'allowedVideoUploadPlatforms',
                                    ['all']
                                  );
                                } else {
                                  // Wenn ein anderer Wert ausgewählt wird, "all" entfernen falls vorhanden
                                  const newValues = currentValues.filter(
                                    (v) => v !== 'all'
                                  );
                                  newValues.push(option.value);
                                  formik.setFieldValue(
                                    'allowedVideoUploadPlatforms',
                                    newValues
                                  );
                                }
                              } else {
                                // Wert aus der Liste entfernen
                                const newValues = currentValues.filter(
                                  (v) => v !== option.value
                                );
                                formik.setFieldValue(
                                  'allowedVideoUploadPlatforms',
                                  newValues
                                );
                              }

                              console.log(
                                'allowedVideoUploadPlatforms: updated values',
                                formik.values.allowedVideoUploadPlatforms
                              );
                            }}
                          />
                        </ArgonBox>
                      </ListItem>

                      // <FormControlLabel
                      //   key={option.value}
                      //   labelPlacement="start"
                      //   control={
                      //     <Switch
                      //       checked={isChecked}
                      //       onChange={(e) => {
                      //         const currentValues = [
                      //           ...(formik.values.allowedVideoUploadPlatforms ||
                      //             [])
                      //         ];

                      //         if (e.target.checked) {
                      //           // Wenn "Do not restrict" ausgewählt wird, nur diesen Wert behalten
                      //           if (option.value === 'all') {
                      //             formik.setFieldValue(
                      //               'allowedVideoUploadPlatforms',
                      //               ['all']
                      //             );
                      //           } else {
                      //             // Wenn ein anderer Wert ausgewählt wird, "all" entfernen falls vorhanden
                      //             const newValues = currentValues.filter(
                      //               (v) => v !== 'all'
                      //             );
                      //             newValues.push(option.value);
                      //             formik.setFieldValue(
                      //               'allowedVideoUploadPlatforms',
                      //               newValues
                      //             );
                      //           }
                      //         } else {
                      //           // Wert aus der Liste entfernen
                      //           const newValues = currentValues.filter(
                      //             (v) => v !== option.value
                      //           );
                      //           formik.setFieldValue(
                      //             'allowedVideoUploadPlatforms',
                      //             newValues
                      //           );
                      //         }

                      //         console.log(
                      //           'allowedVideoUploadPlatforms: updated values',
                      //           formik.values.allowedVideoUploadPlatforms
                      //         );
                      //       }}
                      //       size="small"
                      //     />
                      //   }
                      //   label={
                      //     <ArgonTypography variant="caption">
                      //       {option.label}
                      //     </ArgonTypography>
                      //   }
                      // />
                    );
                  })}
                </List>
              </ArgonBox>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

export default OnlineQualifierSettings;
