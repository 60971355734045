// services/auth/RealmService.js
// Realm-spezifische Operationen
import * as Realm from 'realm-web';
import { logger } from './Logger';

export class RealmService {
  constructor(appId, options = {}) {
    this.appId = appId;
    this.app = new Realm.App(appId);
    this.tokenService = options.tokenService;
    this.anonymousMode = false;

    logger.debug(`RealmService initialized with app ID: ${appId}`);
  }

  // Aktuellen Realm-Benutzer abrufen
  getCurrentUser() {
    return this.app.currentUser;
  }

  // Prüfen, ob aktueller Benutzer anonym ist
  isAnonymous() {
    return this.app.currentUser?.providerType === 'anon-user';
  }

  // Bei Realm mit JWT anmelden
  async loginWithJwt(idToken) {
    if (!idToken) throw new Error('ID token is required for JWT login');

    try {
      logger.debug('Logging in to Realm with JWT token');

      // Wenn bereits angemeldet, Token-Credentials aktualisieren
      if (this.app.currentUser && !this.isAnonymous()) {
        logger.debug('Updating existing Realm user with new JWT');
        try {
          await this.app.currentUser.refreshCustomData();
          await this.app.currentUser.linkCredentials(
            Realm.Credentials.jwt(idToken)
          );
          logger.debug('Existing Realm user updated with new JWT');
          this.anonymousMode = false;
          return this.app.currentUser;
        } catch (refreshError) {
          logger.error('Error refreshing existing Realm user', refreshError);
          // Bei Fehler mit neuem JWT anmelden
        }
      }

      // Bei Realm mit JWT anmelden
      const user = await this.app.logIn(Realm.Credentials.jwt(idToken));
      logger.debug('JWT login successful', { userId: user.id });
      this.anonymousMode = false;

      // Access Token im TokenService cachen
      if (this.tokenService) {
        this.tokenService.setToken('accessToken', user.accessToken);
      }

      return user;
    } catch (error) {
      logger.error('JWT login failed', error);
      throw error;
    }
  }

  // Bei Realm anonym anmelden
  async loginAnonymously() {
    try {
      logger.debug('Logging in to Realm anonymously');

      // Wenn aktueller Benutzer nicht anonym ist, abmelden
      if (this.app.currentUser && !this.isAnonymous()) {
        logger.debug('Logging out non-anonymous user before anonymous login');
        await this.app.currentUser.logOut();
      }

      // Anonym anmelden
      const user = await this.app.logIn(Realm.Credentials.anonymous());
      logger.debug('Anonymous login successful', { userId: user.id });
      this.anonymousMode = true;

      // Access Token im TokenService cachen
      if (this.tokenService) {
        this.tokenService.setToken('accessToken', user.accessToken);
      }

      return user;
    } catch (error) {
      logger.error('Anonymous login failed', error);

      // Bei Problemen mit Token, sessionStorage und localStorage bereinigen
      if (error.message && error.message.includes('token')) {
        this.cleanupStorage();

        // Erneut versuchen
        logger.debug('Retrying anonymous login after storage cleanup');
        const user = await this.app.logIn(Realm.Credentials.anonymous());
        logger.debug('Anonymous login retry successful', { userId: user.id });
        this.anonymousMode = true;

        if (this.tokenService) {
          this.tokenService.setToken('accessToken', user.accessToken);
        }

        return user;
      }

      throw error;
    }
  }

  // Bei Realm abmelden
  async logout() {
    try {
      if (this.app.currentUser) {
        logger.debug('Logging out Realm user', {
          userId: this.app.currentUser.id
        });
        await this.app.currentUser.logOut();
        this.cleanupStorage();
        logger.debug('Realm logout successful');
      } else {
        logger.debug('No Realm user to log out');
      }
    } catch (error) {
      logger.error('Error logging out Realm user', error);
      this.cleanupStorage(); // Trotzdem Storage bereinigen
    }
  }

  // Access Token aktualisieren
  async refreshAccessToken() {
    if (!this.app.currentUser) {
      logger.debug('No Realm user to refresh access token');
      return null;
    }

    try {
      logger.debug('Refreshing Realm access token');
      await this.app.currentUser.refreshCustomData();

      // Aktualisiertes Token im TokenService cachen
      if (this.tokenService) {
        this.tokenService.setToken(
          'accessToken',
          this.app.currentUser.accessToken
        );
      }

      logger.debug('Realm access token refreshed successfully');
      return this.app.currentUser.accessToken;
    } catch (error) {
      logger.error('Error refreshing Realm access token', error);

      // Bei abgelaufenem Refresh-Token
      if (
        error.message &&
        (error.message.includes('Refresh Token has expired') ||
          error.message.includes('Invalid refresh token'))
      ) {
        logger.debug('Realm refresh token expired, needs re-authentication');
        throw new Error('REFRESH_TOKEN_EXPIRED');
      }

      throw error;
    }
  }

  // Realm-bezogene Daten im Speicher bereinigen
  cleanupStorage() {
    logger.debug('Cleaning up Realm-related storage data');

    try {
      // localStorage bereinigen
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (
          key &&
          (key.includes('realm') ||
            key.includes('Realm') ||
            key.includes('mongodb') ||
            key.includes('freshLoginDetected'))
        ) {
          keysToRemove.push(key);
        }
      }

      keysToRemove.forEach((key) => {
        logger.debug(`Removing localStorage key: ${key}`);
        localStorage.removeItem(key);
      });

      // Cookies bereinigen
      document.cookie.split(';').forEach((cookie) => {
        const [name] = cookie.trim().split('=');
        if (
          name &&
          (name.includes('realm') ||
            name.includes('Realm') ||
            name.includes('mongodb'))
        ) {
          logger.debug(`Removing cookie: ${name}`);
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      });

      logger.debug('Storage cleanup completed');
    } catch (error) {
      logger.error('Error during storage cleanup', error);
    }
  }

  // Gültigen Access Token für GraphQL-Anfragen abrufen
  async getValidAccessToken() {
    // Zuerst aus dem Cache abrufen
    if (this.tokenService) {
      const cachedToken = this.tokenService.getToken('accessToken');
      if (cachedToken) {
        logger.debug('Using cached access token');
        return cachedToken;
      }
    }

    // Wenn kein Benutzer vorhanden ist, anonym anmelden
    if (!this.app.currentUser) {
      logger.debug('No current user, logging in anonymously');
      const user = await this.loginAnonymously();
      return user.accessToken;
    }

    try {
      // Token aktualisieren
      logger.debug('Refreshing access token');
      return await this.refreshAccessToken();
    } catch (error) {
      if (error.message === 'REFRESH_TOKEN_EXPIRED') {
        // Bei abgelaufenem Refresh-Token neu anmelden
        logger.debug(
          'Access token refresh failed due to expired refresh token'
        );

        // Wenn wir nicht im anonymen Modus sind und ein ID-Token verfügbar ist
        if (!this.anonymousMode && this.tokenService) {
          const idToken = this.tokenService.getToken('idToken');
          if (idToken) {
            try {
              logger.debug('Attempting to log in with cached ID token');
              const user = await this.loginWithJwt(idToken);
              return user.accessToken;
            } catch (jwtError) {
              logger.error('Failed to login with cached ID token', jwtError);
            }
          }
        }

        // Fallback: anonym anmelden
        logger.debug('Falling back to anonymous login');
        const user = await this.loginAnonymously();
        return user.accessToken;
      }

      logger.error('Error getting valid access token', error);
      throw error;
    }
  }
}
