import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

const basePath = 'aikb';

export const queryKnowledgeBase = ({
  apiKey,
  query,
  queryPrefix = 'You are a helpful and very friendly, but concise assistant that can answer questions with the knowledge base. Show respect and politeness for the athletes sending in their questions.'
}) =>
  new Promise(async (resolve, reject) => {
    if (!apiKey) reject(new Error('API Key is required'));
    if (!query) reject(new Error('Query is required'));

    let customQuery = query;

    if (queryPrefix) {
      customQuery = queryPrefix + '\n\n' + query;
    }

    try {
      //   const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/query`,
        headers: {
          //   Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json',
          Authorization: apiKey
        },
        data: JSON.stringify({
          query: customQuery
        })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });

/**
 * Sendet eine Anfrage an den Knowledge Base API-Endpunkt und streamt die Antworten
 * über Server-Sent Events (SSE) oder verarbeitet einfache JSON-Antworten.
 * Bietet verbesserte Robustheit bei der Verarbeitung von Streams und unvollständigen JSON-Daten.
 *
 * @param {Object} options - Optionen für die Anfrage
 * @param {string} options.apiKey - API-Schlüssel für die Authentifizierung
 * @param {string} options.query - Die Anfrage an die Knowledge Base
 * @param {string} [options.queryPrefix] - Ein Präfix für die Anfrage (z.B. Systemanweisung)
 * @param {string} [options.clientId] - Eine eindeutige Client-ID für die Anfrage (optional)
 * @param {Function} options.onContent - Callback für eingehende Inhaltstokens
 * @param {Function} [options.onSources] - Callback für Quelleninformationen
 * @param {Function} [options.onEnd] - Callback für Stream-Ende
 * @param {Function} [options.onError] - Callback für Fehler
 * @returns {Object} - Ein Objekt mit einer close-Methode zum Beenden der Verbindung
 */
export const queryKnowledgeBaseStream = ({
  apiKey,
  query,
  queryPrefix = 'You are a helpful and very friendly, but concise assistant that can answer questions with the knowledge base. Show respect and politeness for the athletes sending in their questions.',
  clientId = 'web-client-' + Date.now().toString(36),
  onContent,
  onSources,
  onEnd,
  onError
}) => {
  if (!apiKey) throw new Error('API Key is required');
  if (!query) throw new Error('Query is required');
  if (!onContent) throw new Error('onContent callback is required');

  let customQuery = query;
  let startTime = Date.now();
  let chunkCount = 0;
  let responseRef = '';

  if (queryPrefix) {
    customQuery = queryPrefix + '\n\n' + query;
  }

  console.log(
    'queryKnowledgeBaseStream - Starting with query:',
    customQuery.substring(0, 50) + '...'
  );

  // Hilfsfunktion zum Verarbeiten eines Ereignisses
  function processEvent(event) {
    const eventType = event.type;

    if (!eventType) {
      console.warn('Event-Typ fehlt:', event);
      // Fallback: Wenn Daten vorhanden, als Inhalt behandeln
      if (event.data && onContent) {
        onContent(String(event.data));
      }
      return;
    }

    try {
      switch (eventType) {
        case 'sources':
          if (onSources) {
            console.log('Sources event received:', event.sources);
            onSources(event.sources);
          }
          break;

        case 'chunk':
          console.log(
            `Chunk event received: "${event.data?.substring(0, 30)}${
              event.data?.length > 30 ? '...' : ''
            }" (${chunkCount})`
          );
          try {
            if (typeof event.data !== 'string') {
              console.warn(
                'Warning: Content data is not a string:',
                event.data
              );
              onContent(String(event.data));
            } else {
              onContent(event.data);
            }
            chunkCount++;
          } catch (callbackError) {
            console.error('Error in onContent callback:', callbackError);
            if (onError) onError(callbackError);
          }
          break;

        case 'complete':
          console.log('Complete event received');
          if (onEnd) {
            onEnd({
              totalChunks: chunkCount,
              duration: Date.now() - startTime
            });
          }
          break;

        case 'error':
          console.error('Error event received:', event.message);
          if (onError)
            onError(new Error(event.message || 'Unbekannter Fehler'));
          break;

        default:
          console.warn('Unbekannter Event-Typ:', eventType, event);
          // Fallback: Wenn Daten vorhanden, als Inhalt behandeln
          if (event.data && onContent) {
            console.log('Treating untyped event as content');
            onContent(String(event.data));
          }
      }
    } catch (err) {
      console.error('Fehler beim Verarbeiten des Events:', err, event);
      if (onError) onError(err);
    }
  }

  // Funktion zur direkten Verarbeitung der Stream-Daten
  const processStreamingResponse = (progressEvent) => {
    try {
      // Überprüfe, ob wir neue Bytes erhalten haben
      if (!progressEvent || !progressEvent.bytes || progressEvent.bytes <= 0) {
        return; // Keine neuen Daten
      }

      // Da wir keine responseText-Eigenschaft haben, müssen wir die Daten direkt verarbeiten
      // Wir verwenden die Daten, die wir aus dem Beispiel der Stream-Antwort kennen

      // Extrahiere die Rohdaten aus dem Axios-Response-Objekt
      let rawData = null;

      // Versuche, die Daten aus verschiedenen möglichen Quellen zu extrahieren
      if (
        progressEvent.event &&
        progressEvent.event.target &&
        progressEvent.event.target.response
      ) {
        rawData = progressEvent.event.target.response;
      } else if (
        progressEvent.event &&
        progressEvent.event.currentTarget &&
        progressEvent.event.currentTarget.response
      ) {
        rawData = progressEvent.event.currentTarget.response;
      }

      if (rawData) {
        // Wenn wir Rohdaten haben, verarbeiten wir sie
        console.log(`Received raw data: ${rawData.length} bytes`);

        // Nur den neuen Text seit dem letzten Update verarbeiten
        const newText = rawData.substring(responseRef.length);
        responseRef = rawData;

        if (!newText) return;

        console.log(`Processing new text: ${newText.length} bytes`);

        // Verarbeite den neuen Text wie bisher
        const newLines = newText
          .split('\n')
          .filter((line) => line.trim() !== '');
        console.log(`Found ${newLines.length} lines in new text`);

        newLines.forEach((line) => {
          try {
            console.log(
              'Processing line:',
              line.substring(0, 50) + (line.length > 50 ? '...' : '')
            );
            const event = JSON.parse(line);
            processEvent(event);
          } catch (err) {
            console.warn('Error parsing line as JSON:', err);
          }
        });
      } else {
        // Wenn wir keine Rohdaten haben, versuchen wir, die Daten direkt aus dem progressEvent zu extrahieren
        // Dies ist ein Fallback und weniger zuverlässig

        // Wir wissen aus dem Beispiel, dass die Daten in JSON-Objekten mit type, data usw. geliefert werden
        // Wir können versuchen, diese Daten direkt zu verarbeiten

        console.log(
          'No raw data found, trying direct processing of progressEvent'
        );
        console.log('progressEvent:', progressEvent);

        // Wenn wir keine Daten extrahieren können, geben wir eine Warnung aus
        console.warn(
          'Could not extract data from progressEvent, skipping processing'
        );
      }
    } catch (err) {
      console.error('Error in processStreamingResponse:', err);
      if (onError) onError(err);
    }
  };

  // Connection-Objekt erstellen, das wir zurückgeben werden
  const controller = new AbortController();
  const connection = {
    close: () => {
      console.log('Aborting KB query connection...');
      controller.abort();
      console.log('KB query connection aborted');
    }
  };

  try {
    // Parameter für die API-Anfrage vorbereiten
    const url = process.env.REACT_APP_AIKB_STREAM_URL;
    console.log('Request URL:', url);
    console.log(
      'Using API Key (first 5 chars):',
      apiKey.substring(0, 5) + '...'
    );
    console.log(
      'Request body:',
      JSON.stringify({
        query: customQuery.substring(0, 50) + '...',
        clientId: clientId
      })
    );

    // Verwende Axios statt Fetch, ähnlich wie in der funktionierenden Testkomponente
    console.log('Using Axios for streaming request to avoid CORS issues');

    // Konfiguration für Axios
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey
      },
      responseType: 'text', // Wichtig für das Streaming!
      onDownloadProgress: processStreamingResponse,
      signal: controller.signal
    };

    // Request-Body
    const requestBody = {
      query: customQuery,
      clientId: clientId,
      limit: 5 // Optional, wie in der Testkomponente
    };

    // Streaming-Request ausführen
    axios
      .post(url, requestBody, config)
      .then(() => {
        console.log('Axios request completed successfully');
        if (onEnd) onEnd();
      })
      .catch((error) => {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
          console.log('Request aborted by user');
        } else {
          console.error('Error with Axios request:', error);
          if (onError) onError(error);
        }
      });

    return connection;
  } catch (error) {
    console.error('Error setting up connection:', error);
    if (onError) onError(error);
    throw error;
  }
};
