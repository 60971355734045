import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

export const inviteOrganizationTeamMember = ({
  organizationId,
  invitedUserEmail,
  invitedUserName,
  roles
}) =>
  new Promise(async (resolve, reject) => {
    if (!invitedUserEmail) reject(new Error('invitedUserEmail is required'));
    if (!roles) reject(new Error('roles is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/team/invitation/invite`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId,
        invitedUserEmail,
        invitedUserName,
        roles
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const acceptOrganizationTeamMemberInvitations = ({ invitationId }) =>
  new Promise(async (resolve, reject) => {
    if (!invitationId) reject(new Error('invitationId is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/team/invitation/accept`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ invitationId })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const cancelTeamMemberInvitation = ({ userInviteId }) =>
  new Promise(async (resolve, reject) => {
    // if (!organizationId) reject(new Error('OrganizationId is required'));
    if (!userInviteId) reject(new Error('userInviteId is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/team/invitation/cancel`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        userInviteId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const updateTeamMemberRoles = ({
  teamMemberEmail,
  rolesToAdd,
  rolesToRemove
}) =>
  new Promise(async (resolve, reject) => {
    // if (!organizationId) reject(new Error('OrganizationId is required'));
    if (!teamMemberEmail) reject(new Error('teamMemberEmail is required'));
    if (!rolesToAdd) reject(new Error('rolesToAdd is required'));
    if (!rolesToRemove) reject(new Error('rolesToRemove is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/team/roles/update`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        userName: teamMemberEmail,
        rolesToAdd,
        rolesToRemove
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const removeTeamMemberfromOrganization = ({
  teamMemberEmail,
  organizationId
}) =>
  new Promise(async (resolve, reject) => {
    // if (!organizationId) reject(new Error('OrganizationId is required'));
    if (!teamMemberEmail) reject(new Error('teamMemberEmail is required'));
    if (!organizationId) reject(new Error('organizationId is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/team/remove`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        userName: teamMemberEmail,
        organizationId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const createAllEuTaxRates = ({ organizationId }) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('OrganizationId is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/stripe/tax-rate/create-eu-rates`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const checkIfStripeTaxIsActivated = ({ organizationId }) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) reject(new Error('OrganizationId is required'));

    const session = await getSession();

    var config = {
      method: 'post',
      // method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/check-stripe-tax-activated`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId: organizationId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
