import axios from 'axios';
import { getSession, refreshIdToken } from './cognito/cognitoAccount';
import { loginToRealmWithCognitoIdTokenFromSession } from './realm';

// import { useQuery, useMutation, gql } from "@apollo/client";
// import { EVENT_GET_BY_ID } from "GraphQL/Events/queries";
// import { EVENT_DIVISION_CREATE, EVENT_UPDATE } from "GraphQL/Events/mutations";

export const registerToEventInstance = (eventData) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      // console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/event/register`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(eventData)
      };

      console.log('Now sending new event registration to backend...');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          // console.log(
          //   'Now refreshing id token to update cognito roles for new event...'
          // );
          // const refreshedSession = await refreshIdToken().catch((err) => {
          //   console.error(err);
          //   reject(err);
          // });
          // // console.log('Refreshed session:', refreshedSession);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const deleteRegistrationReservation = (registrationId) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      // console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/delete-reservation`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ registrationId })
      };

      console.log('Now sending new event registration to backend...');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const registerToEventInstanceAsOrganizer = (eventData) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      // console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/event/register-for-organizer`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(eventData)
      };

      console.log('Now sending new event registration to backend...');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          console.log(
            'Now refreshing id token to update cognito roles for new event...'
          );
          const refreshedSession = await refreshIdToken().catch((err) => {
            console.error(err);
            reject(err);
          });
          // console.log('Refreshed session:', refreshedSession);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const updateParticipant = (updatedData) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/update`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(updatedData)
      };

      console.log('Now sending updated participant data to backend...');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const withdrawRegistration = (
  registrationId,
  removeParticipants,
  keepOnLeaderboard
) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      // console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/withdraw`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          registrationId: registrationId,
          removeParticipants: removeParticipants,
          keepOnLeaderboard: keepOnLeaderboard
        })
      };

      console.log('Now sendingrequest for registration withdrawal to backend');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const markRegistrationShownInLeaderboard = (
  registrationId,
  showInLeaderboard
) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/show-on-leaderboard`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          registrationId: registrationId,
          showInLeaderboard: showInLeaderboard
        })
      };

      console.log('Now sendingrequest for registration withdrawal to backend');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const reinstateRegistration = (registrationId) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',

        url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/reinstate`,

        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ registrationId: registrationId })
      };

      console.log('Now sending request to reinstate registration to backend');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const createEvent = (eventData) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession().catch((err) => {
      console.error(err);
      reject(err);
    });
    console.log('Session for sending new event to backend:', session);

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/event/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(eventData)
    };

    console.log('Now sending new event to backend...');
    axios(config)
      .then(async function (response) {
        console.log(
          'Response from backend on Event created at backend:',
          JSON.stringify(response.data)
        );
        console.log(
          'Now refreshing id token to update cognito roles for new event...'
        );
        // const refreshedSession = await refreshIdToken().catch((err) => {
        //   console.error(err);
        //   reject(err);
        // });
        console.log('New event id:', response?.data?.event?._id);

        // now refreshong cognito token and re-login to realm to enable roles for this created event
        await loginToRealmWithCognitoIdTokenFromSession();

        // console.log('Refreshed session:', refreshedSession)
        // console.log(
        //   'Updated cognito groups:', refreshedSession?.CognitoUserSession,
        //   refreshedSession?.CognitoUserSession?.idToken?.payload[
        //     'cognito:groups'
        //   ]
        // );
        // console.log(
        //   'User is now authorized for new event:',
        //   refreshedSession?.cognitoUserSession?.idToken?.payload[
        //     'cognito:groups'
        //   ]?.includes('event-admin-' + response?.event?._id)
        // );

        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const createEventInstance = ({
  eventId,
  organizationId,
  type,
  isVirtualEvent
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession().catch((err) => {
      console.error(err);
      reject(err);
    });
    console.log('Session for sending new event to backend:', session);

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/event-instances/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ eventId, organizationId, type, isVirtualEvent })
    };

    console.log('Now sending new event to backend...');
    axios(config)
      .then(async function (response) {
        console.log(
          'Response from backend on Event Instance created at backend:',
          JSON.stringify(response.data)
        );
        console.log(
          'Now refreshing id token to update cognito roles for new event...'
        );
        const refreshedSession = await refreshIdToken().catch((err) => {
          console.error(err);
          reject(err);
        });
        console.log('Refreshed session:', refreshedSession);

        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const deleteEvent = (eventId) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/event/delete`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ eventId: eventId })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const createEventInstanceDivision = (params) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/division/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const createNewOrder = (params) =>
  // ##########################################
  // CreateNewOrder params: {
  //    organizationId: string,
  //    registrations: [ObjectId],
  //    tickets: [string], // TBD
  //    merchandise: [
  //      {
  //        productId: productId1,
  //        quantity: 1,
  //        color: ObjectId (colorId),
  //        size: ObjectId (sizeId),
  //      },
  //    ],
  //    successUrl: string (optional),
  //    cancelUrl: string (optional),
  //
  //    customerFirstName: string,
  //    customerLastName: string,
  //    customerEmail: string,
  //    customerAdress1: string,
  //    customerAdress2: string,
  //    customerZip: string,
  //    customerCity: string,
  //    customerCountry: string,
  //
  //    shippintFirstName: string,
  //    shippingLastName: string,
  //    shippingEmail: string,
  //    shippingAdress1: string,
  //    shippingAdress2: string,
  //    shippingZip: string,
  //    shippingCity: string,
  //    shippingCountry: string,

  // }
  // ##########################################
  new Promise(async (resolve, reject) => {
    const { organizationId } = params;
    if (!organizationId) throw new Error('No organizationId provided');

    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/order/create`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const getOrganizationName = ({ organizationId }) =>
  new Promise(async (resolve, reject) => {
    if (!organizationId) throw new Error('No organizationId provided');

    const session = await getSession();

    var config = {
      method: 'GET',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/get-name/${organizationId}`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ organizationid: organizationId })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

// export const updateEvent = (eventId, eventObject) =>
//   new Promise(async (resolve, reject) => {
//     const [gqlUpdateEvent] = useMutation(EVENT_UPDATE);
//     gqlUpdateEvent({
//       variables: {
//         eventId: eventId,
//         data: eventObject,
//       },
//     })
//       .then((result) => {
//         resolve();
//       })
//       .catch((error) => {
//         console.error(error);
//         reject(error);
//       });
//   });

// export const createDivision = (eventId, divisionData) =>
//   new Promise(async (resolve, reject) => {
//     const [gqlCreateDivision] = useMutation(EVENT_DIVISION_CREATE, {
//       refetchQueries: [
//         {
//           query: EVENT_GET_BY_ID,
//           variables: { eventId: eventId },
//         },
//       ],
//     });

//     gqlCreateDivision({
//       variables: {
//         data: {
//           eventId: eventId,
//           division: divisionData,
//         },
//       },
//     })
//       .then((result) => {
//         resolve();
//       })
//       .catch((error) => {
//         console.error(error);
//         reject(error);
//       });
//   });

// initiate a checkout session
export const stripeCreateCheckoutSession = async ({
  orderId,
  successUrl,
  cancelUrl,
  sendViaEmail
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/payments/create-checkout-session`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ orderId, successUrl, cancelUrl, sendViaEmail })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

// create payment intent with secret key
export const stripeCreatePaymentIntent = async (
  registrationId,
  productIdArray
) =>
  new Promise(async (resolve, reject) => {
    console.log('Registration ID for creating payment intent:', registrationId);

    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/payments/create-payment-intent`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ registrationId, productIds: productIdArray })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const stripeGetPublishableKey = async () =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/payments/get-publishable-key`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({})
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

// create payment intent with secret key
export const refundPayment = async ({
  registrationId,
  orderId,
  amount,
  note,
  refundSystemFee
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/payments/refund-payment`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        registrationId,
        orderId,
        amount,
        note,
        refundSystemFee
      })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const logEventView = (eventId) =>
  new Promise(async (resolve, reject) => {
    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/event/views/log-view`,

      data: JSON.stringify({ eventId: eventId })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const checkVatId = (vatId) =>
  new Promise(async (resolve, reject) => {
    console.log('Testing VAT ID:', vatId);

    const countryCode = vatId.substring(0, 2);
    const vatNumber = vatId.substring(2);

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/check-vat-id`,

      data: JSON.stringify({ countryCode, vatNumber })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const createNewOrganization = ({
  name,
  type,
  adress1,
  adress2,
  zip,
  city,
  country,
  email,
  phone,
  website,
  vatId
}) =>
  new Promise(async (resolve, reject) => {
    console.log('Testing VAT ID:', vatId);

    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/onboarding/create-organization`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        name,
        type,
        adress1,
        adress2,
        zip,
        city,
        country,
        email,
        phone,
        website,
        vatId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const createNewStripeAccount = ({
  organizationId,
  email,
  country,
  businessType,
  organizationName,
  adress1,
  adress2,
  vatId
}) =>
  new Promise(async (resolve, reject) => {
    console.log('Testing VAT ID:', vatId);

    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/organization/onboarding/create-stripe-account`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId,
        email,
        country,
        businessType,
        organizationName,
        adress1,
        adress2,
        vatId,
        return_Url: window.location.href
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

/**
 * Posts a new score to the backend API.
 * @param {Object} params - The parameters for the score.
 * @param {string} params.workoutId - The ID of the workout.
 * @param {string} params.eventId - The ID of the event.
 * @param {string} params.eventInstanceId - The ID of the event instance.
 * @param {number} params.postedScore - The posted score.
 * @param {string} params.registrationId - The ID of the registration.
 * @param {string} params.videoUrl - The URL of the video.
 * @returns {Promise<Object>} - A promise that resolves with the response data from the API.
 */
export const postNewScore = ({
  workoutId,
  postedScore,
  postedTimeCapReached,
  registrationId,
  videoUrl
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/online-qualifier/athlete-post-score`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        workoutId: workoutId,
        postedScore,
        postedTimeCapReached,
        registrationId: registrationId,
        videoUrl: videoUrl
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

/**
 * Updates the posted score for a given score ID.
 * @param {Object} options - The options for updating the score.
 * @param {string} options.scoreId - The ID of the score to update.
 * @param {number} options.newScore - The new score value.
 * @returns {Promise<any>} - A promise that resolves with the updated score data.
 */
export const updatePostedScore = ({
  scoreId,
  newScore,
  postedTimeCapReached
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/online-qualifier/update-posted-score`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId,
        postedScore: newScore,
        postedTimeCapReached: postedTimeCapReached
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

/**
 * Sends a judged score to the backend API.
 *
 * @param {Object} params - The parameters for the judged score.
 * @param {string} params.scoreId - The ID of the score.
 * @param {number} params.judgedScore - The judged score.
 * @param {boolean} params.judgedTimeCapReached - Whether the time cap was reached.
 * @param {string} params.judgingNotes - The judging notes.
 * @param {string} params.judgingPenalties - The judging penalties.
 *
 * @returns {Promise<Object>} A promise that resolves with the response data from the API.
 */
export const postJudgedScore = ({
  scoreId,
  judgedScore,
  judgedTimeCapReached,
  judgingNotes,
  judgingPenalties
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/online-qualifier/judge-score`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId,
        judgedScore,
        judgedTimeCapReached,
        judgingNotes,
        judgingPenalties
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const removeJudgedScore = ({ scoreId, newStatus }) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/online-qualifier/remove-judged-score`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId,
        newStatus
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getScoreVideoDownloadLink = (scoreId) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    // var config = {
    //   method: 'get',

    //   url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/video/get-download-url/${scoreId}`,

    //   headers: {
    //     Authorization: `Bearer ${session.idToken.jwtToken}`,
    //     'Content-Type': 'application/json'
    //   }
    // };

    // axios(config)
    //   .then(function (response) {
    //     console.log('data from axios request', response.data);
    //     resolve(response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //     reject(error);
    //   });

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/online-qualifier/video/get-download-url/${scoreId}`,

        {
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`
          }
        }
      )
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const onSiteEventPostScore = ({
  workoutId,
  eventId,
  eventInstanceId,
  postedScore,
  postedBy,
  // divisionId,
  registrationId,
  postedTimeCapReached
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/on-site/post-score`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        workoutId: workoutId,
        eventId: eventId,
        eventInstanceId: eventInstanceId,
        postedScore,
        postedBy,
        // division: divisionId,
        registrationId: registrationId,
        postedTimeCapReached
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const onSiteEventDeleteScore = ({ scoreId }) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/on-site/delete-score`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getLeaderboardColumns = ({
  eventInstanceId,
  resultStyle,
  showUnreleasedWorkouts,
  showAffiliate
}) =>
  // resultStyle: score (default) || scoreWithRank || scoreWithPoints || scoreWithRankAndPoints
  new Promise(async (resolve, reject) => {
    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns`,

      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        resultStyle,
        showUnreleasedWorkouts,
        showAffiliate
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getLeaderboardData = ({
  eventInstanceId,
  divisionId,
  pageOffset,
  pageSize,
  showUnreleasedWorkouts,
  refresh // ask for a fresh leaderboard instead of a stale 20-60 sec old one
}) =>
  new Promise(async (resolve, reject) => {
    console.log('Rest API divisionID receved', divisionId);

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-data`,

      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        divisionId,
        pageOffset,
        pageSize,
        showUnreleasedWorkouts,
        refresh
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const updateRegistration = ({
  registrationId,
  divisionId,
  teamName,
  affiliateName,
  customRequiredFields
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/update`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        registrationId: registrationId,
        divisionId,
        teamName,
        affiliateName,
        customRequiredFields
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const removeParticipantFromRegistration = ({
  registrationId,
  participantId
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/remove`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        registrationId: registrationId,
        participantId: participantId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const addParticipantToRegistration = ({
  registrationId,
  firstName,
  lastName,
  email,
  dateOfBirth,
  gender,
  affiliate,
  dataIsRestricted,
  customRequiredFields
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/add`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        registrationId,
        firstName,
        lastName,
        email,
        dateOfBirth,
        gender,
        affiliate,
        dataIsRestricted,
        customRequiredFields
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const editParticipantAtRegistration = ({
  registrationId,
  participantId,
  firstName,
  lastName,
  email,
  dateOfBirth,
  gender,
  affiliate,
  customRequiredFields
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/participant/update`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        registrationId,
        participantId,
        firstName,
        lastName,
        email,
        dateOfBirth,
        gender,
        affiliate,
        customRequiredFields
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getAllRegistrationsForEventInstance = ({
  eventInstanceId,
  page,
  pageSize,
  searchText,
  divisionId
}) =>
  new Promise(async (resolve, reject) => {
    console.log(
      'getAllRegistraitonsForEventInstance parameters:',
      eventInstanceId,
      page,
      pageSize,
      searchText,
      divisionId
    );

    const session = await getSession();
    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/get-all-for-eventInstance`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        page,
        pageSize,
        searchText,
        divisionId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getAvailableSpotsForDivision = ({ eventInstanceId, divisionId }) =>
  // resultStyle: score (default) || scoreWithRank || scoreWithPoints || scoreWithRankAndPoints
  new Promise(async (resolve, reject) => {
    if (!eventInstanceId) {
      reject(new Error('eventInstanceId is required'));
      return;
    }

    if (!divisionId) {
      reject(new Error('divisionId is required'));
      return;
    }

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/get-available-spots`,

      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        divisionId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getAvailableSpotsAllDivisionsByEventInstance = ({
  eventInstanceId
}) =>
  // resultStyle: score (default) || scoreWithRank || scoreWithPoints || scoreWithRankAndPoints

  new Promise(async (resolve, reject) => {
    if (!eventInstanceId) {
      reject(new Error('eventInstanceId is required'));
      return;
    }

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/registration/get-available-spots`,

      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

// ########### STATISTICS ###########

export const getRegistrationsOverTime = ({
  eventInstanceId,
  statusFilter, //active, withdrawn...
  groupBy, //day, week
  fromDate,
  toDate
}) =>
  // resultStyle: score (default) || scoreWithRank || scoreWithPoints || scoreWithRankAndPoints
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/event-instance/registrations-over-time`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },

      data: JSON.stringify({
        eventInstanceId,
        status: statusFilter,
        groupBy,
        fromDate,
        toDate
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getFinancialsForEventInstance = ({
  eventInstanceId,
  query // "registrations"
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/event-instance/financials`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },

      data: JSON.stringify({
        eventInstanceId,
        query
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getRegistrationsByDivisionsForEventInstance = ({
  eventInstanceId,
  fromDate,
  toDate
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/event-instance/registrations-per-division`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },

      data: JSON.stringify({
        eventInstanceId,
        fromDate,
        toDate
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getTotalRegistrationsForEventInstance = ({
  eventInstanceId,
  status, // "active" (default), "withdrawn", "" for all
  paymentStatus
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/event-instance/total-registrations`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },

      data: JSON.stringify({
        eventInstanceId,
        status,
        paymentStatus
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getTotalRegistrationsForOrganization = ({
  organizationId,
  status, // "active" (default), "withdrawn", "" for all
  fromDate,
  toDate
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/organization/total-registrations`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },

      data: JSON.stringify({
        organizationId,
        status,
        fromDate,
        toDate
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getTotalRevenueForOrganization = ({
  organizationId,
  fromDate,
  toDate,
  groupBy // undefined = total values, "day", "week", "month"
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'POST',
      // url: `${process.env.REACT_APP_BACKEND_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      url: `${process.env.REACT_APP_BACKEND_URI}/v1/statistics/organization/revenue`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },

      data: JSON.stringify({
        organizationId,
        fromDate,
        toDate,
        groupBy
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
