import { InputAdornment } from '@mui/material';
import Loader from 'Loader';
import ArgonBox from 'components/ArgonBox';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTypography from 'components/ArgonTypography';
import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useAuth } from 'context/AuthContext';

export default function SelectOrganization({
  title,
  required,
  showLoader,
  size = 'regular',
  onChange,
  initialOrganizationId,
  enableAllOrganizations,
  disabled,
  ...rest
}) {
  const { organizations, isLoadingOrganizationNames } = useAuth();
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [formattedOrganizations, setFormattedOrganizations] = useState([]);

  useEffect(() => {
    if (organizations && organizations.length > 0) {
      console.log('User organizations from auth state: ', organizations);

      let temp = organizations.map((org) => ({
        label: org.name || org._id,
        value: org._id
      }));

      // if allowed, add option to select all organizations
      if (enableAllOrganizations)
        temp.unshift({ label: 'All organizations', value: null });

      console.log('Formatted organizations: ', temp);
      setFormattedOrganizations(temp);

      if (initialOrganizationId) {
        const initialOrganization = temp.find(
          (org) => org.value === initialOrganizationId.toString()
        );
        console.log(
          'SelectOrganization: initialOrganization found: ',
          initialOrganization
        );

        setSelectedOrganization(initialOrganization);
        onChange && onChange(initialOrganization);
      } else if (temp.length > 0) {
        setSelectedOrganization(temp[0]);
        onChange && onChange(temp[0]);
      }
    }
  }, [organizations, initialOrganizationId, enableAllOrganizations]);

  // useEffect(() => {
  //   setSelectedOrganization(value);
  // }, [value]);

  const handleSetSelectedOrganization = (organization) => {
    console.log('Selected organization: ', organization);
    setSelectedOrganization(organization);
    onChange && onChange(organization);
  };

  if (showLoader && isLoadingOrganizationNames) return <Loader />;

  return (
    <>
      <ArgonBox mb={0}>
        {title && (
          <ArgonBox mb={0} ml={0.5}>
            <ArgonTypography variant="caption" fontWeight="bold">
              {title}
              {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
            </ArgonTypography>
          </ArgonBox>
        )}
        <ArgonSelect
          id="organization-select"
          name="organization-select"
          size={size}
          placeHolder="Select Organization"
          options={formattedOrganizations}
          onChange={handleSetSelectedOrganization}
          value={selectedOrganization}
          overFlow
          isLoading={isLoadingOrganizationNames}
          isDisabled={disabled}
          {...rest}
          success={selectedOrganization?.value !== null}
        />
      </ArgonBox>
    </>
  );
}
