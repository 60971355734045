import { Alert, AlertTitle, Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import ArgonBox from 'components/ArgonBox';
import { useEffect, useState } from 'react';
import { checkIfStripeTaxIsActivated } from 'services/REST_API/organization';
import Loader from 'Loader';

/**
 * @description Eine Komponente, die eine Warnmeldung anzeigt, wenn Stripe Tax für eine Organisation nicht aktiviert ist.
 * Die Komponente prüft automatisch den Stripe Tax Status für die angegebene Organisation und
 * zeigt die Warnung nur an, wenn Stripe Tax nicht aktiviert ist.
 *
 * @param {Object} props - Die Komponenten-Props
 * @param {string} props.organizationId - Die ID der Organisation, für die der Stripe Tax Status geprüft werden soll
 * @param {boolean} [props.showLoader=false] - Optional. Wenn true, wird ein Loader während der Statusprüfung angezeigt
 *
 * @example
 * // Basis-Verwendung
 * <StripeTaxAlert organizationId="org-123" />
 *
 * // Mit Loader während des Ladens
 * <StripeTaxAlert organizationId="org-123" showLoader={true} />
 *
 * @returns {JSX.Element|null} Eine Alert-Komponente wenn Stripe Tax nicht aktiviert ist,
 * einen Loader wenn noch geladen wird (und showLoader true ist),
 * oder null wenn Stripe Tax aktiviert ist
 */
function StripeTaxAlert({
  organizationId,
  showLoader = false,
  infoHeadline = 'Stripe Tax is not activated for this organization',
  infoText = (
    <>
      You will be limited in selling products to your customers. Please check
      the{' '}
      <u>
        <a
          style={{ textDecoration: 'underline', color: 'inherit' }}
          href="/organizer/organization/settings/payments">
          Payment Settings
        </a>
      </u>{' '}
      to learn more.
    </>
  ),
  style,
  onCheckStripeTaxStatusFetched = () => {}
}) {
  const [stripeTaxLoading, setStripeTaxLoading] = useState(false);
  const [stripeTaxIsActivated, setStripeTaxIsActivated] = useState(false);
  useEffect(() => {
    setStripeTaxLoading(true);
    checkIfStripeTaxIsActivated({ organizationId: organizationId })
      .then((res) => {
        console.log('checkIfStripeTaxIsActivated: ', res);
        setStripeTaxIsActivated(res.isTaxEnabled);
        onCheckStripeTaxStatusFetched &&
          onCheckStripeTaxStatusFetched(res.isTaxEnabled);
      })
      .catch((err) => {
        console.error('checkIfStripeTaxIsActivated: ', err);
      })
      .finally(() => {
        setStripeTaxLoading(false);
      });
  }, [organizationId]);

  if (stripeTaxLoading && showLoader) return <Loader />;

  if (stripeTaxIsActivated) return null;

  return (
    <ArgonBox style={style}>
      <Collapse in={stripeTaxIsActivated === false}>
        <ArgonBox mb={3}>
          <Alert severity="info">
            <AlertTitle>{infoHeadline}</AlertTitle>
            {infoText}
          </Alert>
        </ArgonBox>
      </Collapse>
    </ArgonBox>
  );
}

export default StripeTaxAlert;
