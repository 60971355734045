/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-select components
import Select, { components } from 'react-select';

// MUI components
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Argon Dashboard 2 PRO MUI base styles
import colors from 'assets/theme/base/colors';

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from 'context';

// Custom styles for ArgonSelect
import styles from 'components/ArgonSelect/styles';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import TooltipWrapper from 'components/TooltipWrapper';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ArgonSelect = forwardRef(
  (
    {
      id,
      name,
      label,
      tooltip,
      required,
      placeholder,
      size,
      error,
      success,
      helperText,

      isDisabled,
      overflow,
      labelColor,
      startAdornment,
      // endAdornment,
      inputProps,
      width,
      ...rest
    },
    ref
  ) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const { light, dark } = colors;

    return (
      <>
        <ArgonBox sx={{ width: width }}>
          {/* {label && (
          <>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                color={labelColor || (darkMode ? dark.main : light.main)}
                textTransform="capitalize">
                {label}
                {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
              </ArgonTypography>
            </ArgonBox>
          </>
        )} */}

          {(label || tooltip) && (
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <TooltipWrapper tooltip={tooltip}>
                <ArgonTypography
                  // component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  color={labelColor}>
                  {label}&nbsp;
                  {required && (
                    // <sup>
                    <span style={{ color: 'red' }}>*</span>
                    // </sup>
                  )}
                  {tooltip && (
                    <ArgonBox mt={0} ml={1} display="inline-block">
                      <ArgonTypography variant="regular">
                        <InfoOutlinedIcon color="secondary" />
                      </ArgonTypography>
                    </ArgonBox>
                  )}
                </ArgonTypography>
              </TooltipWrapper>
            </ArgonBox>
          )}

          <Select
            id={id}
            inputId={id}
            name={name}
            menuPortalTarget={overflow && document.querySelector('body')}
            // menuPortalTarget={overflow && document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
              ...styles(size, error, success, darkMode, isDisabled)
            }}
            success={success}
            inputProps={{
              id: id,
              placeholder,
              ...inputProps,
              selectSuccess: success,
              success: success
            }}
            placeholder={placeholder}
            isDisabled={isDisabled}
            {...rest}
            ref={ref}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: light.main,
                primary: light.main
              }
            })}
            components={{
              Control: ({ children, ...props }) => (
                <components.Control {...props}>
                  <span style={{ marginRight: '0px', marginLeft: '8px' }}>
                    {startAdornment}
                  </span>
                  {children}
                  {/* {required && props.hasValue && (
                  <span
                    style={{
                      marginRight: '8px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <CheckCircleIcon
                      sx={{ color: 'success.main', fontSize: '20px' }}
                    />
                  </span>
                )} */}
                </components.Control>
              ),
              Option: ({ children, ...props }) => (
                <components.Option {...props}>
                  <div onTouchStart={(e) => e.stopPropagation()}>
                    {children}
                  </div>
                </components.Option>
              )
            }}
          />
          {helperText && (
            <>
              <ArgonBox mt={-1.4} ml={0.5}>
                <ArgonTypography
                  component="label"
                  color="error"
                  variant="caption"
                  fontWeight="regular"
                  textTransform="capitalize">
                  {helperText}
                </ArgonTypography>
              </ArgonBox>
            </>
          )}
        </ArgonBox>
      </>
    );
  }
);

// Setting default values for the props of ArgonSelect
ArgonSelect.defaultProps = {
  size: 'medium',
  error: false,
  success: false
};

// Typechecking props for the ArgonSelect
ArgonSelect.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  error: PropTypes.bool,
  success: PropTypes.bool
};

export default ArgonSelect;
