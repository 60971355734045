import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import { styled } from '@mui/material/styles';
import RenderSafeHtml from 'services/RenderSafeHtml';
import colors from 'assets/theme/base/colors';

// Styled components
const MessageBubble = styled(Box)(({ theme, isOutgoing, format }) => ({
  backgroundColor:
    format === 'html'
      ? colors?.white?.main
      : isOutgoing
      ? theme.palette.primary.light
      : theme.palette.grey[100],
  color: isOutgoing
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: '12px',
  border: format === 'html' ? '1px solid rgba(0, 0, 0, 0.1)' : null,
  padding: theme.spacing(2),
  maxWidth: '80%',
  marginLeft: isOutgoing ? 'auto' : '0',
  marginRight: isOutgoing ? '0' : 'auto',
  marginBottom: theme.spacing(2),
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    [isOutgoing ? 'right' : 'left']: '-10px',
    width: '0',
    height: '0',
    border: '10px solid transparent',
    borderTopColor: isOutgoing
      ? theme.palette.primary.light
      : theme.palette.grey[100],
    borderBottom: '0',
    marginBottom: '-10px'
  }
}));

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};

/**
 * ConversationMessages-Komponente, die die Nachrichten einer Konversation anzeigt
 * @param {Object} props - Die Komponenteneigenschaften
 * @param {Object} props.conversationDetails - Die Details der Konversation
 * @param {boolean} props.loading - Gibt an, ob die Daten geladen werden
 * @returns {JSX.Element} - Die gerenderte Komponente
 */
function ConversationMessages({ conversationDetails, loading }) {
  return (
    <ArgonBox
      p={3}
      pb={5} // Zusätzliches Padding am unteren Rand
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100%',
        paddingBottom: '40px', // Noch mehr Padding am unteren Rand
        overflowY: 'auto' // Explizit vertikales Scrollen aktivieren
      }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
          <CircularProgress />
        </Box>
      ) : conversationDetails?.messages?.length > 0 ? (
        conversationDetails.messages.map((message, index) => (
          <div key={message._id || index}>
            <MessageBubble
              isOutgoing={message.direction === 'outgoing'}
              format={message.format}>
              <ArgonTypography variant="body2">
                {message.format === 'html' ? (
                  <RenderSafeHtml rawHTML={message.content} />
                ) : (
                  message.content
                )}
              </ArgonTypography>
              <ArgonTypography variant="caption" color="text.secondary">
                Via {conversationDetails?.channel || 'Unknown'}
              </ArgonTypography>
              <ArgonTypography variant="caption" color="text.secondary">
                {formatDate(message?.sentAt)}
              </ArgonTypography>
            </MessageBubble>
            {message.format === 'html' && (
              <ArgonTypography
                variant="caption"
                color="text.secondary"
                sx={{
                  display: 'block',
                  textAlign:
                    message.direction === 'outgoing' ? 'right' : 'left',
                  mt: 1
                }}>
                The HTML message above was sanitized for security reasons.
                Therefore the formatting might not fully reflect the original
                message.
              </ArgonTypography>
            )}
          </div>
        ))
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
          <ArgonTypography variant="body2" color="text.secondary">
            Keine Nachrichten gefunden
          </ArgonTypography>
        </Box>
      )}
    </ArgonBox>
  );
}

ConversationMessages.propTypes = {
  conversationDetails: PropTypes.object,
  loading: PropTypes.bool
};

export default ConversationMessages;
