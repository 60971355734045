/**
 * Route Protection Components
 *
 * Diese Komponenten schützen Routen basierend auf Authentifizierung und Berechtigungen.
 * - AuthenticatedRoutes: Nur für authentifizierte Benutzer zugänglich
 * - OrganizerRoutes: Nur für Benutzer mit Organisator-Zugriff zugänglich
 */

import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../context/AuthContext';
import Loader from '../Loader';

/**
 * AuthenticatedRoutes
 *
 * Schützt Routen, sodass nur authentifizierte Benutzer darauf zugreifen können.
 * Nicht authentifizierte Benutzer werden zur Login-Seite weitergeleitet.
 */
export const AuthenticatedRoutes = ({ redirectPath = '/login' }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  // Während der Authentifizierungsstatus geladen wird, zeigen wir einen Loader an
  if (isLoading) {
    return <Loader />;
  }

  // Wenn der Benutzer nicht authentifiziert ist, leiten wir ihn zur Login-Seite weiter
  // und speichern den ursprünglichen Pfad, um nach dem Login dorthin zurückzukehren
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  // Wenn der Benutzer authentifiziert ist, rendern wir die geschützten Routen
  return <Outlet />;
};

AuthenticatedRoutes.propTypes = {
  redirectPath: PropTypes.string
};

/**
 * OrganizerRoutes
 *
 * Schützt Routen, sodass nur Benutzer mit Organisator-Zugriff darauf zugreifen können.
 * Benutzer ohne Organisator-Zugriff werden zur angegebenen Seite weitergeleitet.
 */
export const OrganizerRoutes = ({ redirectPath = '/' }) => {
  const { isAuthenticated, isLoading, hasOrganizerAccess } = useAuth();
  const location = useLocation();

  // Während der Authentifizierungsstatus geladen wird, zeigen wir einen Loader an
  if (isLoading) {
    return <Loader />;
  }

  // Wenn der Benutzer nicht authentifiziert ist oder keinen Organisator-Zugriff hat,
  // leiten wir ihn zur angegebenen Seite weiter
  if (!isAuthenticated || !hasOrganizerAccess) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  // Wenn der Benutzer authentifiziert ist und Organisator-Zugriff hat,
  // rendern wir die geschützten Routen
  return <Outlet />;
};

OrganizerRoutes.propTypes = {
  redirectPath: PropTypes.string
};
