import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

const basePath = 'aikb';

export const getKbDocumentPresignedUploadUrl = ({
  clientId,
  apiKey,
  fileName,
  contentType,
  fileSize
}) =>
  new Promise(async (resolve, reject) => {
    if (!clientId) reject(new Error('Client ID is required'));
    if (!apiKey) reject(new Error('API Key is required'));
    if (!fileName) reject(new Error('File name is required'));
    if (!contentType) reject(new Error('Content type is required'));
    if (!fileSize) reject(new Error('File size is required'));

    try {
      const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/clients/${clientId}/documents`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        data: JSON.stringify({
          filename: fileName,
          contentType,
          fileSize
        })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });

export const listDocuments = ({ clientId, apiKey }) =>
  new Promise(async (resolve, reject) => {
    if (!clientId) reject(new Error('Client ID is required'));
    if (!apiKey) reject(new Error('API Key is required'));

    try {
      const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/clients/${clientId}/documents`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        }
        //   data: JSON.stringify({
        //     fileName,
        //     contentType,
        //     fileSize
        //   })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });

export const deleteDocument = ({ clientId, apiKey, documentId }) =>
  new Promise(async (resolve, reject) => {
    if (!clientId) reject(new Error('Client ID is required'));
    if (!apiKey) reject(new Error('API Key is required'));
    if (!documentId) reject(new Error('Document ID is required'));

    try {
      const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'delete',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/clients/${clientId}/documents/${documentId}`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        }
        //   data: JSON.stringify({
        //     fileName,
        //     contentType,
        //     fileSize
        //   })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });

// should be called after uploading a document; otherwise content will not be available to the knowledge base
export const vectorizeUploadedDocument = ({ clientId, apiKey, documentId }) =>
  new Promise(async (resolve, reject) => {
    if (!clientId) reject(new Error('Client ID is required'));
    if (!apiKey) reject(new Error('API Key is required'));
    if (!documentId) reject(new Error('Document ID is required'));

    try {
      const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/clients/${clientId}/documents/${documentId}/process`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        }
        // data: JSON.stringify({
        //   filename: fileName,
        //   contentType,
        //   fileSize
        // })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });
