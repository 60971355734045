import React from 'react';
import PropTypes from 'prop-types';

// import * as DOMPurify from 'dompurify';
import DOMPurify from 'dompurify';

function RenderSafeHtml({ rawHTML }) {
  return (
    <div
      style={{ width: '100%' }}
      className="argonEditorRender"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawHTML) }}
    />
  );
}

RenderSafeHtml.propTypes = {
  rawHTML: PropTypes.string.isRequired
};

export function extractTextFromHtml(htmlString) {
  if (!htmlString) return '';

  // Alle HTML-Tags entfernen, nur Text behalten
  const textOnly = DOMPurify.sanitize(htmlString, { ALLOWED_TAGS: [] });

  // Whitespace bereinigen
  return textOnly.replace(/\s+/g, ' ').trim();
}

export default RenderSafeHtml;
