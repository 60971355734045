import { gql } from '@apollo/client';

import { EVENTINSTANCE_FRAGMENT } from 'GraphQL/EventInstances/fragments';
import { EVENT_FRAGMENT } from 'GraphQL/Events/fragments';

export const WORKOUT_FRAGMENT = gql`
  ${EVENT_FRAGMENT}
  ${EVENTINSTANCE_FRAGMENT}
  fragment WorkoutFragment on Workout {
    _id
    # event {
    #   ...EventFragment
    # }
    organization
    event
    eventInstance {
      ...EventInstanceFragment
    }
    name
    aliasName
    scoreType
    timeFormat
    movementsPerDivision {
      _id
      division
      description
    }
    attachments {
      _id
      fileName
      fileType
      description
      url
    }

    videoLinks {
      _id
      url
      title
      description
      divisionId
    }

    publicationDate
    workoutReleased
    scoreSubmissionStart
    scoreSubmissionEnd
    instructionsForJudging
    scoringWeight
    availableLanes
    timeCap
    sequence
    breakBetweenHeats
    startTime
    endTime

    judgesAssigned
    judgesAssignmentMethod
    judgesAutoAssignmentDate
    judgementSelectionMethod
    headJudge
    judgesLastAssignedDate
    judgementDueDate

    heatPlanning
    heatPlanningPublished
    heatPlanninglastPublishedDate

    laneSettings {
      laneIndex
      laneName
      divisionId
      divisionName
      judgeUserId
      judgeName
    }
  }
`;
