// =====================================================
// contexts/ApolloContext.js
// =====================================================
// Context für den Apollo Client
import { createContext, useContext } from 'react';

// Apollo Context erstellen
export const ApolloContext = createContext(null);

// Custom Hook für einfachen Zugriff auf den Apollo Context
export const useApollo = () => {
  const context = useContext(ApolloContext);
  if (!context) {
    throw new Error('useApollo must be used within an ApolloProvider');
  }
  return context;
};
