import React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableCell from 'components/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';
import ArgonDatePicker from 'components/ArgonDatePicker';
import FormikNumberInput from 'components/FormikNumberInput';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StripeTaxRateSelector from 'Routes/Organizer/Organization/Settings/Payments/StripeTaxRates/Selector';

function Parameters({ formik, event, paymentsEnabled, fm, formMinWidth }) {
  const { t, i18n } = useTranslation();

  const handleDatePickerChanged = async (fieldName, newValue) => {
    if (newValue.length > 0) {
      await formik.setFieldValue(fieldName, newValue[0], true);
    }
  };

  const calculatedSystemFee = new Intl.NumberFormat(i18n.language, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(
    formik.values?.registrationFee == '0'
      ? 0
      : Number(process.env.REACT_APP_TDU_FIXED_FEE) +
          Number(
            (process.env.REACT_APP_TDU_PERCENTAGE_FEE / 100) *
              fm.un(formik.values?.registrationFee)
          )
  );

  return (
    <Grid item xs={12} sm={12}>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              Event Instance Parameters
            </TableCell>
            <TableCell
              width="100%"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: formMinWidth }}>
              {' '}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Is a virtual event
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  Competitors will have to submit scores and videos online
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <Switch
                id="isVirtualEvent"
                name="isVirtualEvent"
                onChange={formik.handleChange}
                checked={formik.values.isVirtualEvent}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Open registration
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                    Everyone can register if set to open. If not set to open you
                    will have to invite competitors manually from the
                    participants tab.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell
              align="end"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: '220px' }}>
              <Switch
                id="isOpenRegistration"
                name="isOpenRegistration"
                onChange={(event) => {
                  formik.setFieldValue(
                    'isOpenRegistration',
                    event.target.checked
                  );
                }}
                checked={Boolean(formik.values?.isOpenRegistration) || false}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Limit of participants / teams across all divisions
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                    Usually, participants / team limits will be set per
                    division. Select yes if you choose to limit the number of
                    participants (or teams) per event instance.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell
              align="end"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: '220px' }}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                alignItems="end"
                gap={1}>
                <Switch
                  id="setLimitPerEventInstance"
                  name="setLimitPerEventInstance"
                  onChange={formik.handleChange}
                  checked={formik.values?.setLimitPerEventInstance}
                />
                <Collapse in={formik.values?.setLimitPerEventInstance}>
                  <FormikNumberInput
                    style={{ minWidth: formMinWidth }}
                    id="maxParticipants"
                    name="maxParticipants"
                    label=""
                    placeholder=""
                    formik={formik}
                    size="small"
                    prefix="# "
                  />
                </Collapse>
              </ArgonBox>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Registration fee
                </ArgonTypography>
                <Collapse in={!paymentsEnabled}>
                  <ArgonBox mt={1} mb={1}>
                    <ArgonTypography
                      variant="caption"
                      color="warning"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center">
                      <ErrorOutlineIcon fontSize="xs" />
                      &nbsp;You have not connected a stripe account to accept
                      payments. Go to&nbsp;
                      <u>
                        <Link
                          to="/organizer/organization/settings/payments"
                          style={{
                            textDecoration: 'undeline',
                            color: 'inherit'
                          }}>
                          Setup / Payments & Shop
                        </Link>
                      </u>
                      &nbsp;to fix.
                    </ArgonTypography>
                  </ArgonBox>
                </Collapse>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                    You can set a fee for registering for this event. You an
                    also define an individual fee per disivion.
                    <br />
                    Your payout will be reduced by the payment provider's
                    transaction fees for the used payment method (see{' '}
                    <a
                      href="https://stripe.com/en-de/pricing"
                      style={{ textDecoration: 'none', color: 'inherit' }}>
                      Stripe pricing
                    </a>
                    ).
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <ArgonCurrencyInput
                id="registrationFee"
                name="registrationFee"
                size="small"
                intlConfig={{
                  locale: i18n.language,
                  currency: event.currency || 'EUR'
                }}
                allowNegativeValue={false}
                placeholder=""
                defaultValue={0}
                decimalsLimit={2}
                value={formik?.values?.registrationFee}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value, true);
                }}
                error={
                  formik.touched.registrationFee &&
                  Boolean(formik.errors.registrationFee)
                }
                sx={{ minWidth: formMinWidth }}
                disabled={!paymentsEnabled}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Applicable VAT rate
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <Collapse
                    in={!paymentsEnabled && formik.values.registrationFee > 0}>
                    <ArgonBox mt={1} mb={1}>
                      <ArgonTypography
                        variant="caption"
                        color="warning"
                        fontWeight="bold"
                        display="flex"
                        alignItems="center">
                        <ErrorOutlineIcon fontSize="xs" />
                        &nbsp;You have not connected a stripe account to accept
                        payments. Go to&nbsp;
                        <u>
                          <Link
                            to="/organizer/organization/settings/payments"
                            style={{
                              textDecoration: 'undeline',
                              color: 'inherit'
                            }}>
                            Setup / Payments & Shop
                          </Link>
                        </u>
                        &nbsp;to fix.
                      </ArgonTypography>
                    </ArgonBox>
                  </Collapse>
                  <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                    Select / add the vat tax rate that applies to the
                    registration fees according to our local tax requirements.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <StripeTaxRateSelector
                disabled={
                  !paymentsEnabled || !formik.values.registrationFee > 0
                }
                organization={event?.organization}
                selectedTaxRateId={formik.values.stripeTaxRateId}
                onChange={(taxRateOption) => {
                  formik.setFieldValue('stripeTaxRateId', taxRateOption?.value);
                }}
                size="small"
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  System fee
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  This fee will be added to the registration fee and
                  automatically forwarded to{' '}
                  {process.env.REACT_APP_PLATTFORM_NAME}
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="start" padding={[1, 1, 1, 0.5]}>
              <ArgonBox width="100%">
                <ArgonCurrencyInput
                  id="systemFee"
                  name="systemFee"
                  size="small"
                  intlConfig={{
                    locale: i18n.language,
                    currency: event.currency || 'EUR'
                  }}
                  disabled
                  allowNegativeValue={false}
                  placeholder=""
                  defaultValue={0}
                  decimalsLimit={2}
                  value={calculatedSystemFee}
                  error={
                    formik.touched.registrationFee &&
                    Boolean(formik.errors.registrationFee)
                  }
                />
              </ArgonBox>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Registration deadline
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  Date until which participants can register
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <ArgonDatePicker
                id="registrationDeadline"
                name="registrationDeadline"
                options={{
                  allowInput: true,
                  enableTime: true,
                  time_24hr: true,
                  defaultHour: 22
                }}
                input={{
                  size: 'small',
                  placeholder: 'Select a date',
                  error:
                    formik.touched.registrationDeadline &&
                    Boolean(formik.errors.registrationDeadline),
                  helperText:
                    formik.touched.registrationDeadline &&
                    formik.errors.registrationDeadline
                }}
                onChange={async (date) => {
                  await handleDatePickerChanged('registrationDeadline', date);
                }}
                value={formik.values.registrationDeadline}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Event start date{' '}
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <ArgonDatePicker
                id="startDate"
                name="startDate"
                options={{
                  allowInput: true,
                  enableTime: true,
                  time_24hr: true,
                  defaultHour: 8
                }}
                input={{
                  size: 'small',
                  placeholder: 'Select a date',
                  error:
                    formik.touched.startDate &&
                    Boolean(formik.errors.startDate),
                  helperText:
                    formik.touched.startDate && formik.errors.startDate
                }}
                onChange={async (date) => {
                  await handleDatePickerChanged('startDate', date);
                }}
                value={formik.values.startDate}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Event end date
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <ArgonDatePicker
                id="endDate"
                name="endDate"
                options={{
                  allowInput: true,
                  enableTime: true,
                  time_24hr: true,
                  defaultHour: 18
                }}
                input={{
                  size: 'small',
                  placeholder: 'Select a date',
                  error:
                    formik.touched.endDate && Boolean(formik.errors.endDate),
                  helperText: formik.touched.endDate && formik.errors.endDate
                }}
                onChange={async (date) => {
                  await handleDatePickerChanged('endDate', date);
                  await handleDatePickerChanged(
                    'spectatorTicketSalesEndDate',
                    date
                  );
                }}
                value={formik.values.endDate}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Leaderboard publish date
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  When you specify a date, the leaderboard will be not be
                  visible to athletes until this date; otherwise it will be
                  visible immediately.
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <ArgonDatePicker
                id="leaderboardPublishDate"
                name="leaderboardPublishDate"
                options={{
                  allowInput: true,
                  enableTime: true,
                  time_24hr: true,
                  defaultHour: 18
                }}
                input={{
                  size: 'small',
                  placeholder: 'Select a date',
                  error:
                    formik.touched.endDate && Boolean(formik.errors.endDate),
                  helperText: formik.touched.endDate && formik.errors.endDate
                }}
                onChange={async (date) => {
                  await handleDatePickerChanged('endDate', date);
                  await handleDatePickerChanged(
                    'spectatorTicketSalesEndDate',
                    date
                  );
                }}
                value={formik.values.endDate}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              Request Additional Information From Participants
            </TableCell>
            <TableCell
              width="100%"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: formMinWidth }}>
              {' '}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Country
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  Require each participant to provide the country he wants to
                  represent
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <Switch
                id="askForCountry"
                name="askForCountry"
                onChange={formik.handleChange}
                checked={formik.values?.askForCountry}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  T-shirt size
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  Require each participant to provide his t-shirt size
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}>
              <Switch
                id="askForTShirtSize"
                name="askForTShirtSize"
                onChange={formik.handleChange}
                checked={formik.values?.askForTShirtSize}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

export default Parameters;
