/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-chartjs-2 components
import { Doughnut } from 'react-chartjs-2';

// @mui material components
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';

// Argon Dashboard 2 PRO MUI example components
import ReportsDoughnutChartItem from './ReportsDoughnutChartItem';

// ReportsDoughnutChart configurations
import configs from './configs/index';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function ReportsDoughnutChart({
  title,
  count,
  chart,
  hasCard = true,
  tooltip = '',
  isPercentage = false,
  width = '100%',
  maxWidth = '100%',
  height = '100%',
  maxHeight = '100%',
  chartWidth = '100%',
  chartHeight = '100%',
  maxGridWidth = '100%'
}) {
  const { data, options } = configs(chart?.labels || [], chart?.datasets || {});

  const renderItems =
    chart?.labels && chart?.datasets
      ? chart?.labels.map((label, key) => (
          <ReportsDoughnutChartItem
            color={
              chart?.datasets?.backgroundColors
                ? chart?.datasets?.backgroundColors[key]
                : 'dark'
            }
            title={label}
            key={label}
            count={chart?.datasets?.data ? chart?.datasets?.data[key] : 0}
            isPercentage={isPercentage}
            hasBorder={key !== chart?.labels?.length - 1}
          />
        ))
      : null;

  const CardWrapper = ({ children }) =>
    hasCard ? <Card>{children}</Card> : <ArgonBox>{children}</ArgonBox>;

  return (
    // <ArgonBox width={width} height={height}>
    // <Card style={{ width: width, height: height }}>
    <CardWrapper>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
        maxWidth={maxWidth}
        width={width}>
        <ArgonTypography variant="h6" textTransform="capitalize">
          {title}
        </ArgonTypography>
        {tooltip && (
          <Tooltip title={tooltip} placement="bottom" arrow>
            {/* <ArgonButton
            variant="text"
            color="secondary"
            size="regular"
            circular
            iconOnly> */}
            <InfoOutlinedIcon />
            {/* </ArgonButton> */}
          </Tooltip>
        )}
      </ArgonBox>

      <ArgonBox p={2} width={width} height={height}>
        {useMemo(
          () => (
            <Grid
              container
              spacing={2}
              sx={{
                height: height,
                width: width,
                maxWidth: maxWidth,
                maxHeight: maxHeight
              }}>
              <Grid item xs={12} sm={5}>
                <ArgonBox height="100%" textAlign="center" position="relative">
                  <ArgonBox
                    height={{ xs: '65%', sm: '100%' }}
                    mt={{ xs: 6, sm: 0 }}>
                    <Doughnut
                      id="doughnut-chart"
                      data={data}
                      options={options}
                      // height={'110px'}
                      height={chartHeight}
                      // width={'110px'}
                      width={chartWidth}
                    />
                  </ArgonBox>
                  <ArgonBox
                    mt={{ xs: 0, sm: -12.5 }}
                    position="relative"
                    top={{ xs: '-7.25rem', sm: 0 }}>
                    <ArgonTypography
                      variant="h4"
                      fontWeight="medium"
                      lineHeight={1}>
                      {count?.number}
                    </ArgonTypography>
                    <ArgonBox mt={-1}>
                      <ArgonTypography
                        lineHeight={1}
                        variant="button"
                        color="text"
                        textTransform="uppercase"
                        fontWeight="medium">
                        {count?.text}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={7}>
                {renderItems}
              </Grid>
            </Grid>
          ),
          [chart]
        )}
      </ArgonBox>
    </CardWrapper>
    // </Card>
    // </ArgonBox>
  );
}

// Typechecking props for the ReportsDoughnutChart
ReportsDoughnutChart.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string.isRequired
  }).isRequired,
  chart: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
    ).isRequired
  }).isRequired,
  tooltip: PropTypes.string
};

export default ReportsDoughnutChart;
