// services/auth/CognitoService.js
// Cognito-spezifische Operationen
import { logger } from './Logger';

import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

export class CognitoService {
  constructor(userPool, options = {}) {
    this.userPool = userPool;
    this.tokenService = options.tokenService;

    logger.debug('CognitoService initialized');
  }

  // Aktuellen Cognito-Benutzer abrufen
  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  // Prüfen, ob ein Cognito-Benutzer angemeldet ist
  isAuthenticated() {
    return !!this.getCurrentUser();
  }

  // Prüfen, ob das Refresh-Token gültig ist
  async isRefreshTokenValid() {
    const user = this.getCurrentUser();
    if (!user) {
      logger.debug('No Cognito user, refresh token is invalid');
      return false;
    }

    try {
      logger.debug('Checking if Cognito refresh token is valid');

      // Session abrufen und prüfen
      const session = await this._getUserSession(user);
      const isValid = session && session.isValid();

      logger.debug(`Cognito refresh token is ${isValid ? 'valid' : 'invalid'}`);
      return isValid;
    } catch (error) {
      logger.error('Error checking Cognito refresh token', error);
      return false;
    }
  }

  // Cognito-Sitzung abrufen
  async getSession() {
    const user = this.getCurrentUser();
    if (!user) {
      logger.debug('No Cognito user, cannot get session');
      return null;
    }

    try {
      logger.debug('Getting Cognito session');
      const session = await this._getUserSession(user);

      if (session) {
        // Tokens im TokenService cachen
        if (this.tokenService) {
          const idToken = session.getIdToken();
          this.tokenService.setToken(
            'idToken',
            idToken.getJwtToken(),
            idToken.decodePayload()
          );

          if (session.getAccessToken()) {
            this.tokenService.setToken(
              'accessToken',
              session.getAccessToken().getJwtToken()
            );
          }
        }

        logger.debug('Cognito session retrieved successfully');
        return {
          idToken: {
            jwtToken: session.getIdToken().getJwtToken(),
            payload: session.getIdToken().decodePayload()
          },
          user
        };
      }

      logger.debug('No valid Cognito session found');
      return null;
    } catch (error) {
      logger.error('Error getting Cognito session', error);
      throw error;
    }
  }

  // ID-Token aktualisieren
  async refreshIdToken() {
    const user = this.getCurrentUser();
    if (!user) {
      logger.debug('No Cognito user, cannot refresh ID token');
      throw new Error('No authenticated user');
    }

    try {
      logger.debug('Refreshing Cognito ID token');

      // Aktuelle Session abrufen
      const currentSession = await this._getUserSession(user);

      if (!currentSession) {
        logger.debug('No valid session to refresh');
        throw new Error('No valid session to refresh');
      }

      // Session mit Refresh-Token aktualisieren
      const refreshToken = currentSession.getRefreshToken();

      return new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            logger.error('Error refreshing session', err);
            reject(err);
            return;
          }

          // Tokens im TokenService cachen
          if (this.tokenService) {
            const idToken = session.getIdToken();
            this.tokenService.setToken(
              'idToken',
              idToken.getJwtToken(),
              idToken.decodePayload()
            );

            if (session.getAccessToken()) {
              this.tokenService.setToken(
                'accessToken',
                session.getAccessToken().getJwtToken()
              );
            }
          }

          logger.debug('Cognito ID token refreshed successfully');
          resolve({
            idToken: {
              jwtToken: session.getIdToken().getJwtToken(),
              payload: session.getIdToken().decodePayload()
            },
            user
          });
        });
      });
    } catch (error) {
      logger.error('Error refreshing ID token', error);
      throw error;
    }
  }

  // Bei Cognito anmelden
  async login(username, password) {
    try {
      logger.debug('Authenticating with Cognito');

      logger.debug('Authenticating with Cognito');

      return new Promise((resolve, reject) => {
        // Hier die Klassen direkt verwenden (nicht über this.userPool)
        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password
        });

        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: this.userPool // Das userPool-Objekt wird korrekt verwendet
        });

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (session) => {
            logger.debug('Cognito authentication successful');

            // Tokens im TokenService cachen
            if (this.tokenService) {
              const idToken = session.getIdToken();
              this.tokenService.setToken(
                'idToken',
                idToken.getJwtToken(),
                idToken.decodePayload()
              );

              if (session.getAccessToken()) {
                this.tokenService.setToken(
                  'accessToken',
                  session.getAccessToken().getJwtToken()
                );
              }
            }

            // Fresh login flag setzen
            localStorage.setItem('freshLoginDetected', 'true');

            resolve({
              idToken: {
                jwtToken: session.getIdToken().getJwtToken(),
                payload: session.getIdToken().decodePayload()
              },
              user: cognitoUser
            });
          },
          onFailure: (err) => {
            logger.error('Cognito authentication failed', err);
            reject(err);
          }
        });
      });
    } catch (error) {
      logger.error('Error during Cognito login', error);
      throw error;
    }
  }

  // Von Cognito abmelden
  async logout() {
    const user = this.getCurrentUser();
    if (user) {
      logger.debug('Logging out Cognito user');

      return new Promise((resolve) => {
        user.signOut(() => {
          logger.debug('Cognito logout successful');
          resolve();
        });
      });
    } else {
      logger.debug('No Cognito user to log out');
      return Promise.resolve();
    }
  }

  // Hilfsfunktion zum Abrufen der Cognito-Sitzung
  _getUserSession(user) {
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          logger.error('Error getting Cognito user session', err);
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }
}
