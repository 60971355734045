// services/auth/ApolloAuthService.js
// Apollo Client Integration für Authentication
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
  Observable,
  fromPromise
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { logger } from './Logger';

export class ApolloAuthService {
  constructor(options = {}) {
    this.graphqlEndpoint = options.graphqlEndpoint;
    this.realmService = options.realmService;
    this.tokenService = options.tokenService;
    this.onAuthError = options.onAuthError || (() => {});
    this.pendingRequests = [];
    this.isRefreshing = false;

    logger.debug('ApolloAuthService initialized');
  }

  // Apollo Client erstellen
  createApolloClient() {
    logger.debug('Creating Apollo client');

    // 1. HTTP-Link für GraphQL-Anfragen
    const httpLink = new HttpLink({
      uri: this.graphqlEndpoint,
      fetch: async (uri, options) => {
        try {
          // Gültigen Access Token abrufen
          const accessToken = await this.realmService.getValidAccessToken();

          // Authorization-Header mit Access Token hinzufügen
          options.headers.Authorization = `Bearer ${accessToken}`;

          // Anfrage ausführen
          return fetch(uri, options);
        } catch (error) {
          logger.error('Error in Apollo fetch handler', error);
          throw error;
        }
      }
    });

    // 2. Error-Link für die Behandlung von Auth-Fehlern
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        // Auth-Fehler im GraphQL-Response
        if (graphQLErrors) {
          const authError = graphQLErrors.find(
            (error) =>
              error.extensions?.code === 'UNAUTHENTICATED' ||
              error.message.includes('auth') ||
              error.message.includes('token') ||
              error.message.includes('unauthorized')
          );

          if (authError) {
            logger.debug('GraphQL auth error detected:', authError.message);

            // Token-Aktualisierung mit Warteschlange
            return this._handleTokenRefresh(operation, forward);
          }
        }

        // Network-Fehler prüfen (z.B. 401 Unauthorized)
        if (
          networkError &&
          'statusCode' in networkError &&
          (networkError.statusCode === 401 || networkError.statusCode === 403)
        ) {
          logger.debug(`Network ${networkError.statusCode} error detected`);

          // Token-Aktualisierung mit Warteschlange
          return this._handleTokenRefresh(operation, forward);
        }
      }
    );

    // 3. Request-Link für Pre-Flight Token-Prüfung
    const requestLink = new ApolloLink((operation, forward) => {
      // Prüfen, ob Tokens aktualisiert werden müssen
      if (
        this.tokenService &&
        this.tokenService.shouldRefreshToken('accessToken')
      ) {
        logger.debug('Token refresh needed before request');

        // Wenn bereits eine Aktualisierung läuft, zur Warteschlange hinzufügen
        if (this.isRefreshing) {
          logger.debug('Token refresh already in progress, queueing request');

          return new Observable((observer) => {
            this.pendingRequests.push(() => {
              const subscription = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              });

              return () => subscription.unsubscribe();
            });
          });
        }

        this.isRefreshing = true;

        // Token aktualisieren und dann fortfahren
        return fromPromise(
          this.tokenService.refreshTokens().finally(() => {
            this.isRefreshing = false;
            this._resolvePendingRequests();
          })
        ).flatMap(() => forward(operation));
      }

      // Direkt fortfahren, wenn keine Aktualisierung nötig ist
      return forward(operation);
    });

    // 4. Apollo-Client erstellen
    return new ApolloClient({
      link: from([errorLink, requestLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all'
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        },
        mutate: {
          errorPolicy: 'all'
        }
      },
      // Performance-Optimierungen
      assumeImmutableResults: true, // Reduziert unnötige Vergleichsoperationen
      queryDeduplication: true // Verhindert doppelte Anfragen
    });
  }

  // Hilfsmethode zur Behandlung von Token-Aktualisierungen bei Auth-Fehlern
  _handleTokenRefresh(operation, forward) {
    // Wenn bereits eine Aktualisierung läuft, zur Warteschlange hinzufügen
    if (this.isRefreshing) {
      logger.debug('Token refresh already in progress, queueing request');

      return new Observable((observer) => {
        this.pendingRequests.push(() => {
          const subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });

          return () => subscription.unsubscribe();
        });
      });
    }

    this.isRefreshing = true;

    return fromPromise(
      this.tokenService
        .refreshTokens(true) // Erzwungene Aktualisierung
        .then((success) => {
          logger.debug(`Token refresh completed, success: ${success}`);

          if (!success) {
            // Bei Fehlschlag Auth-Error-Callback aufrufen
            this.onAuthError();
            throw new Error('Authentication failed');
          }

          // Access Token in den Header der Operation setzen
          const accessToken = this.realmService.getCurrentUser()?.accessToken;
          if (accessToken) {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`
              }
            }));
          }

          return true;
        })
        .finally(() => {
          this.isRefreshing = false;
          this._resolvePendingRequests();
        })
    ).flatMap(() => forward(operation));
  }

  // Hilfsmethode zum Fortsetzen ausstehender Anfragen
  _resolvePendingRequests() {
    logger.debug(`Resolving ${this.pendingRequests.length} pending requests`);

    this.pendingRequests.forEach((callback) => callback());
    this.pendingRequests = [];
  }
}
