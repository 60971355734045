import { gql } from '@apollo/client';

import { TASK_FRAGMENT } from './fragments';

export const TASKS_GET = gql`
  ${TASK_FRAGMENT}
  query getTasks($query: TaskQueryInput) {
    tasks(query: $query) {
      ...TaskFragment
    }
  }
`;

export const TASK_GET = gql`
  ${TASK_FRAGMENT}
  query getTask($query: TaskQueryInput) {
    task(query: $query) {
      ...TaskFragment
    }
  }
`;
