import React, { useState, useEffect } from 'react';
import DashboardLayout from 'Layout/DashboardLayout';
import DashboardNavbar from 'Layout/DashboardNavbar';
import ArgonBox from 'components/ArgonBox';
import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailEditor from './EmailEditor';
import SelectOrganization from '../Components/SelectOrganization';
import { useQuery } from '@apollo/client';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';

/**
 * ConversationsPage-Komponente, die als Hauptseite für die Konversationen dient
 * @returns {JSX.Element} - Die gerenderte Komponente
 */
export default function ConversationsPage() {
  const navigate = useNavigate();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  // const [selectedConversation, setSelectedConversation] = useState(null);
  // const [selectedEventInstanceId, setSelectedEventInstanceId] = useState(null);

  // // Apollo-Query für EventInstance
  // const { data: eventInstanceData, loading: eventInstanceLoading } = useQuery(
  //   EVENTINSTANCE_GET_BY_ID,
  //   {
  //     variables: { eventInstanceId: selectedEventInstanceId },
  //     skip: !selectedEventInstanceId,
  //     fetchPolicy: 'network-only'
  //   }
  // );

  // Callback-Funktion, die von EmailEditor aufgerufen wird, wenn eine Konversation ausgewählt wird
  // const handleConversationSelected = (conversation) => {
  //   setSelectedConversation(conversation);

  //   // Wenn die Konversation eine eventInstanceId hat, setzen wir diese für die Apollo-Abfrage
  //   if (conversation?.eventInstanceId) {
  //     setSelectedEventInstanceId(conversation.eventInstanceId);
  //   } else {
  //     setSelectedEventInstanceId(null);
  //   }
  // };

  return (
    <DashboardLayout bgColor="#466B72">
      <DashboardNavbar title="Conversations" />
      <ArgonBox mt={3} mb={1}>
        <ArgonBox mb={3}>
          <SelectOrganization
            onChange={(orga) => setSelectedOrganizationId(orga.value)}
          />
        </ArgonBox>
        {/* <Card> */}
        <ArgonBox p={0} display="flex" flexDirection="column">
          <EmailEditor
            key={selectedOrganizationId}
            organizationId={selectedOrganizationId}
            // onConversationSelected={handleConversationSelected}
            // selectedEventInstance={eventInstanceData?.eventInstance}
            // eventInstanceLoading={eventInstanceLoading}
          />
        </ArgonBox>
        {/* </Card> */}
      </ArgonBox>
    </DashboardLayout>
  );
}
