import 'assets/css/ka-table.css';

import React, { useEffect, useState } from 'react';

import { Card } from '@mui/material';
import ArgonBox from 'components/ArgonBox';

import { useNavigate } from 'react-router-dom';
import { getSession } from 'services/cognito/cognitoAccount';

import LoaderWrap from 'LoaderWrap';

import ArgonBadge from 'components/ArgonBadge';

import {
  ActionType,
  DataType,
  FilteringMode,
  PagingPosition,
  SortingMode,
  Table,
  useTable
} from 'ka-table';

import { useTranslation } from 'react-i18next';

import ArgonSelect from 'components/ArgonSelect';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { TASKS_GET } from 'GraphQL/Tasks/queries';
import { updateData } from 'ka-table/actionCreators';

const DueTasksList = (props) => {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const [showExportCsvDialog, setShowExportCsvDialog] = useState(false);

  const [organizationId, setOrganizationId] = useState(null);

  const [showFilters, setShowFilters] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [searchInputText, setSearchInputText] = React.useState('');

  const [pageIndex, setPageIndex] = useState(0);
  // const [downloadPageIndex, setdownloadPageIndex] = useState(0);

  const [preparedData, setPreparedData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [paymentStatusFilterOption, setPaymentStatusFilterOption] = useState({
    value: undefined,
    label: 'all'
  });
  const [orderStatusFilterOption, setOrderStatusFilterOption] = useState({
    value: undefined,
    label: 'all'
  });

  //  const [exportData, setExportData] = useState([]);

  // remembers input field value
  const handleSearchInputTextChange = (event) => {
    setSearchInputText(event.target.value);
  };

  // transfers input field value to search text to fire search
  const handleSearchInputTextSubmit = (event) => {
    setSearchText(searchInputText);
  };

  const [pageSizeOption, setPageSizeOption] = useState(5);

  const pageSizeOptions = [10, 25, 50];

  const [totalPagesCount, setTotalPagesCount] = useState(0);

  const table = useTable({
    onDispatch: async (action) => {
      console.log('action', action);
      if (action.type === ActionType.UpdatePageIndex) {
        console.log('Showing page index:', action.pageIndex);
        setPageIndex(action.pageIndex);
      }

      if (action.type === ActionType.UpdatePageSize) {
        console.log('Updating page size:', action.pageSize);
        setPageSizeOption(action.pageSize);
      }
    }
  });

  const prepareData = (data) => {
    const preparedTasks = data?.map((task) => {
      let referenceType;
      switch (task?.referenceType) {
        case 'product':
          referenceType = 'Product';
          break;
        case 'order':
          referenceType = 'Order';
          break;
        case 'score':
          referenceType = 'Score';
          break;
        default:
          referenceType = 'Unknown';
          break;
      }

      let status = 'open';
      if (task?.completedDate) status = 'done';
      if (task?.startedDate) status = 'started';

      // Berechnung der verbleibenden Tage bis zum Fälligkeitsdatum
      let dueInDays = '';
      let dueDateDiffMs = Number.MAX_SAFE_INTEGER; // Default-Wert für Sortierung

      if (task?.dueDate) {
        // Aktuelles Datum (lokale Zeit)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Setze Zeit auf Mitternacht für genaue Tagesberechnung

        // Fälligkeitsdatum (lokale Zeit)
        const dueDate = new Date(task.dueDate);
        dueDate.setHours(0, 0, 0, 0); // Setze Zeit auf Mitternacht für genaue Tagesberechnung

        // Berechne Differenz in Millisekunden und konvertiere zu Tagen
        const diffTime = dueDate.getTime() - today.getTime();
        dueDateDiffMs = diffTime; // Speichere die Differenz in Millisekunden für Sortierung
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
          dueInDays = 'today';
        } else if (diffDays === 1) {
          dueInDays = 'tomorrow';
        } else if (diffDays < 0) {
          dueInDays = 'overdue';
        } else {
          dueInDays = `${diffDays} days`;
        }
      }

      return {
        ...task,
        status: status,
        referenceType: referenceType,
        dueInDays: dueInDays,
        dueDateDiffMs: dueDateDiffMs // Speichere die Differenz für Sortierung
      };
    });

    // Sortiere die Aufgaben nach Fälligkeitsdatum (aufsteigend)
    // Überfällige zuerst, dann heute fällige, dann morgen, usw.
    return preparedTasks?.sort((a, b) => a.dueDateDiffMs - b.dueDateDiffMs);
  };

  const [
    fetchTasks,
    { loading: tasksLoading, data: tasksData, error: tasksError }
  ] = useLazyQuery(TASKS_GET, {
    onCompleted: (data) => {
      console.log('TaskListTable: tasks fetched from database:', data);
      setPreparedData(prepareData(data?.tasks));
      // setPreparedData(data?.tasks);
      table.dispatch(updateData(prepareData(data?.tasks)));

      prepareData(data?.tasks);
      setTotalPagesCount(data?.tasks?.totalCount);
    },
    onError: (error) => {
      console.log(
        'TaskListTable: error fetching products from database:',
        error
      );
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    // fetch data from REST API
    setLoading(true);
    fetchTasks({
      organizationId: organizationId,
      status: 'open',
      dueDate: new Date().toISOString()
    }).then((res) => {
      setLoading(false);
    });
    // getTasksPaginated({
    //   pageOffset: pageIndex,
    //   pageSize: pageSizeOption,
    //   // sort: 'creationDate',
    //   sortDirection: -1,
    //   filterPaymentStatus: paymentStatusFilterOption.value,
    //   // filterOrderStatus: orderStatusFilterOption.value,
    //   filterOrderStatus: 'open',
    //   searchText
    // })
    //   .then((res) => {
    //     prepareData(res?.data.orders);
    //     setTotalPagesCount(res?.data?.totalCount);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }, [
    searchText,
    pageIndex,
    pageSizeOption,
    paymentStatusFilterOption,
    orderStatusFilterOption
  ]);

  const handlePaymentStatusFilterChange = (option) => {
    console.log('Payment status filter changed:', option);
    setPaymentStatusFilterOption(option);
  };

  const handleOrderStatusFilterChange = (option) => {
    console.log('Order status filter changed:', option);
    setOrderStatusFilterOption(option);
  };

  // const prepareData = (orderData) => {
  //   const tempData = orderData?.map((order) => ({
  //     ...order,
  //     formattedAmountDue: new Intl.NumberFormat(i18n.language, {
  //       style: 'currency',
  //       currency: order.currency || 'EUR',
  //       minimumFractionDigits: 2
  //     }).format(order.totalAmountDue / 100),
  //     customerName:
  //       order?.customerLastName &&
  //       order?.customerFirstName &&
  //       order?.customerLastName + ', ' + order?.customerFirstName
  //   }));

  //   console.log('Prepared data: ', tempData);

  //   setPreparedData(tempData);
  // };

  const handleExportCsvButtonClicked = () => {
    setShowExportCsvDialog(true);
  };

  return (
    <Card sx={{ maxHeight: '19.25rem', height: '100%' }}>
      <LoaderWrap show={!preparedData}>
        <ArgonBox p={3}>
          <ArgonBox display="flex" justifyContent="space-between" mb={1}>
            <ArgonTypography variant="h6" textTransform="capitalize">
              Open tasks due shortly
            </ArgonTypography>
            <ArgonBox>
              <ArgonButton
                variant="text"
                color="secondary"
                size="small"
                component={Link}
                to="/organizer/tasks">
                See all tasks
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>

          <ArgonBox mt={0} pb={0} sx={{ overflow: 'scroll', height: '100%' }}>
            {/* <ArgonBox
            display="flex"
            alignItems="center"
            mb={1}
            justifyContent="end">
            <ArgonBox display="flex" alignItems="center" mr={2}>
              <ArgonTypography
                variant="regular"
                // fontWeight="bold"
                color="secondary">
                Payment status:
              </ArgonTypography>
              <ArgonBox ml={1}>
                <ArgonSelect
                  id="paymentStatusSelection"
                  name="paymentStatusSelection"
                  size="small"
                  options={[
                    { value: undefined, label: 'all' },
                    { value: 'open', label: 'open' },
                    { value: 'paid', label: 'paid' },
                    { value: 'free', label: 'free' }
                  ]}
                  value={paymentStatusFilterOption}
                  onChange={handlePaymentStatusFilterChange}
                />
              </ArgonBox>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center" mr={2}>
              <ArgonTypography
                variant="regular"
                // fontWeight="bold"
                color="secondary">
                Fulfilment status:
              </ArgonTypography>
              <ArgonBox ml={1}>
                <ArgonSelect
                  id="orderStatusSelection"
                  name="orderStatusSelection"
                  size="small"
                  options={[
                    { value: undefined, label: 'all' },
                    { value: 'open', label: 'open' },
                    { value: 'completed', label: 'completed' }
                  ]}
                  value={orderStatusFilterOption}
                  onChange={handleOrderStatusFilterChange}
                />
              </ArgonBox>
            </ArgonBox>
          </ArgonBox> */}
            <div className="ka-table_transparent_dark_font_small_paddings">
              <Table
                // key={showFilters}
                table={table}
                columns={[
                  {
                    key: 'dueInDays',
                    title: 'Due in',
                    dataType: DataType.String,
                    isEditable: false,
                    width: 90
                  },
                  {
                    key: 'name',
                    title: 'Name',
                    dataType: DataType.String,
                    // width: 650,
                    isEditable: false
                  }
                  // {
                  //   key: 'status',
                  //   title: 'Status',
                  //   dataType: DataType.String,
                  //   // width: 100,
                  //   isEditable: false
                  // }
                ]}
                data={preparedData}
                paging={{
                  enabled: true,
                  pageIndex,
                  pageSize: pageSizeOption,
                  // pageSizes: pageSizeOptions,
                  pagesCount: Math.ceil(totalPagesCount / pageSizeOption) || 0,
                  position: PagingPosition.Bottom
                }}
                // editingMode={EditingMode.Cell}
                filteringMode={showFilters && FilteringMode.FilterRow}
                rowKeyField={'_id'}
                sortingMode={SortingMode.Single}
                // search={({ searchText: searchTextValue, rowData, column }) => {
                //   if (column.key === "passed") {
                //     return (
                //       (searchTextValue === "false" && !rowData.passed) ||
                //       (searchTextValue === "true" && rowData.passed)
                //     );
                //   }
                // }}
                searchText={searchText}
                loading={{
                  enabled: loading,
                  text: 'Loading data'
                }}
                noData={{
                  text: 'No Data Found'
                }}
                format={({ column, value }) => {
                  if (
                    column.key === 'dueDate' ||
                    column.key === 'startedDate'
                  ) {
                    console.log('date: ', value);
                    if (value == null) return '';
                    else
                      return new Intl.DateTimeFormat(i18n.language, {
                        dateStyle: 'short',
                        timeStyle: 'short'
                      }).format(new Date(value));
                  }

                  if (column.key === 'dueInDays') {
                    // Bestimme die Farbe basierend auf dem Wert
                    let color = 'secondary'; // Standard-Farbe für die meisten Fälle

                    if (value === 'overdue') {
                      color = 'error'; // Rot für überfällige Aufgaben
                    } else if (value === 'today' || value === 'tomorrow') {
                      color = 'warning'; // Gelb für heute oder morgen fällige Aufgaben
                    } else if (value && value.includes('days')) {
                      // Extrahiere die Anzahl der Tage aus dem Text
                      const daysMatch = value.match(/^(\d+) days$/);
                      if (daysMatch && daysMatch[1]) {
                        const days = parseInt(daysMatch[1], 10);
                        if (days <= 3) {
                          color = 'warning'; // Gelb für 2-3 Tage
                        }
                      }
                    }

                    return (
                      <ArgonBadge
                        badgeContent={value}
                        color={color}
                        variant="contained"
                        size="xs"
                        container
                      />
                    );
                  }

                  if (column.key === 'status') {
                    if (value === 'open')
                      return (
                        <>
                          <ArgonBadge
                            badgeContent="open"
                            color="secondary"
                            variant="gradient"
                            size="xs"
                            container
                          />
                        </>
                      );
                    if (value === 'done')
                      return (
                        <ArgonBadge
                          badgeContent="done"
                          color="success"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );

                    if (value === 'started')
                      return (
                        <ArgonBadge
                          badgeContent="started"
                          color="info"
                          variant="gradient"
                          size="xs"
                          container
                        />
                      );

                    return value;
                  }

                  return value;
                }}
                childComponents={{
                  dataRow: {
                    elementAttributes: () => ({
                      onClick: (event, extendedEvent) => {
                        console.log(
                          'dataRow: onClick: extendedEvent',
                          extendedEvent
                        );

                        table.selectSingleRow(
                          extendedEvent.childProps.rowKeyValue
                        );

                        navigate(
                          `/organizer/tasks/${extendedEvent.childProps.rowKeyValue}`
                        );
                        //! here nvigate to order details page...
                        // setSelectedOrderId(
                        //   extendedEvent.childProps.rowKeyValue
                        // );
                        // setOrderDetailsOpen(true);
                      }
                    })
                  }
                }}
              />
            </div>
          </ArgonBox>
        </ArgonBox>
      </LoaderWrap>
    </Card>
  );
};

export default DueTasksList;
