/**
=========================================================
* Argon Dashboard 2 PRO MUI - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for ArgonButton
import ArgonButtonRoot from 'components/ArgonButton/ArgonButtonRoot';
import { CircularProgress, Tooltip } from '@mui/material';
import ArgonBox from 'components/ArgonBox';

// import ArgonBox from 'components/ArgonBox';

const ArgonButton = forwardRef(
  (
    {
      color,
      variant,
      size,
      circular,
      iconOnly,
      children,
      loading,
      loadingColor,
      disabled,
      tooltip,
      sx,
      ...rest
    },
    ref
  ) => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const ref2 = useRef(null);
    // get currenct button size

    // ref = useCallback((node) => {
    //   if (node !== null) {
    //     setHeight(Math.ceil(node.getBoundingClientRect().height));
    //     setWidth(Math.ceil(node.getBoundingClientRect().width));
    //     // console.log('Button size:', node.getBoundingClientRect());
    //   }
    // }, []);
    // console.log('Button loading: ', loading);

    // console.log('Button height: ', height);
    // console.log('Button width: ', width);
    // const ref = useRef(null);

    // useLayoutEffect(() => {
    useEffect(() => {
      setHeight(ref2.current.getBoundingClientRect()?.height);
      setWidth(ref2.current.getBoundingClientRect()?.width);
    }, []);

    // console.log('Button height: ', height);
    // console.log('Button width: ', width);

    const TooltipWrapper = ({ title, children }) =>
      title ? <Tooltip title={title}>{children}</Tooltip> : children;

    return (
      <TooltipWrapper title={tooltip}>
        <ArgonButtonRoot
          {...rest}
          ref={ref2}
          color="primary"
          variant={variant === 'gradient' ? 'contained' : variant}
          size={size}
          ownerState={{ color, variant, size, circular, iconOnly }}
          disabled={loading || disabled}
          sx={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .loading-indicator': {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            '& .button-content': {
              visibility: loading ? 'hidden' : 'visible',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            ...sx
          }}>
          <span className="button-content">{children}</span>
          {loading && (
            <CircularProgress
              className="loading-indicator"
              size={30}
              color={loadingColor}
              sx={{ p: 0.5 }}
            />
          )}
        </ArgonButtonRoot>
      </TooltipWrapper>
    );
  }
);

// Setting default values for the props of ArgonButton
ArgonButton.defaultProps = {
  size: 'medium',
  variant: 'contained',
  color: 'white',
  circular: false,
  iconOnly: false,
  loading: false,
  loadingColor: 'white'
};

// Typechecking props for the ArgonButton
ArgonButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark'
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'white'
  ])
};

export default ArgonButton;
