import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableCell from 'components/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch
} from '@mui/material';
import ArgonDropzoneS3 from 'components/ArgonDropzoneS3';
import { createNewClient } from 'services/REST_API/ai/rag-client-admin';
import { createNewApiKeyForClient } from 'services/REST_API/ai/rag-client-admin';
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';
import ArgonDropzoneS3ButtonOnly from 'components/ArgonDropzoneS3ButtonOnly';
import AiKnowledeBaseDropzone from 'components/AiKnowledeBaseDropzone';
import { listDocuments } from 'services/REST_API/ai/kb-manage-documents';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DocumentsTable from './DocumentsTable';
import { vectorizeUploadedDocument } from 'services/REST_API/ai/kb-manage-documents';

function AiAssistant({
  formik,
  formMinWidth,
  onFileUploaded,
  onChange,
  eventInstance
}) {
  const [somethingChanged, setSomethingChanged] = useState(false);

  const [availableDocuments, setAvailableDocuments] = useState([]);

  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [vectorizingDocument, setVectorizingDocument] = useState(false);

  const [reloadDocumentsTrigger, setReloadDocumentsTrigger] = useState(0);

  useEffect(() => {
    if (formik.values.aiAssistantClientId && formik.values.aiAssistantApiKey) {
      listDocuments({
        clientId: formik.values.aiAssistantClientId,
        apiKey: formik.values.aiAssistantApiKey
      }).then((documents) => {
        console.log('AiAssistant: available documents', documents);
        setAvailableDocuments(documents?.documents || []);
      });
    }
  }, [formik.values.aiAssistantClientId, formik.values.aiAssistantApiKey]);

  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
    // setSomethingChanged(true);
    onChange && onChange();
  };

  const handleSwitchChanged = async (event) => {
    formik.setFieldValue(event.target.name, event.target.checked);
    // setSomethingChanged(true);

    if (
      event.target.name === 'aiAssistantEnabled' &&
      event.target.checked &&
      !formik.values.aiAssistantClientId
    ) {
      // initialize ai assistant if not initialized before
      await handleAiAssistantEnabled();
    }

    onChange && onChange();
  };

  const handleAiAssistantEnabled = async () => {
    if (!formik.values.aiAssistantClientId) {
      // create new client in api
      const { client } = await createNewClient({
        name:
          eventInstance?.event?.name +
          ' - ' +
          (eventInstance?.name ||
            selectData?.eventInstanceTypes.find(
              (eit) => eit.value === eventInstance?.type
            )?.label),
        eventId: eventInstance?.event?._id,
        eventInstanceId: eventInstance?._id,
        organizationId: eventInstance?.organizationId
      });

      console.log('AI assistant: new client created', client);
      formik.setFieldValue('aiAssistantClientId', client._id);

      // create new api key for client
      const apiKey = await createNewApiKeyForClient({
        clientId: client._id
      });

      console.log('AI assistant: new api key created', apiKey);
      formik.setFieldValue('aiAssistantApiKey', apiKey?.apiKey?.key);

      setSomethingChanged(true);
    }
  };

  console.log('AiAssistant: formik.values: ', formik.values);

  return (
    <Grid item xs={12} sm={12}>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              Settings
            </TableCell>
            <TableCell
              width="100%"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: formMinWidth }}>
              {' '}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Enable AI-assisted customer support
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <ArgonBox color="inherit" lineHeight={1.4} mt={0.5}>
                    Enabling the AI-assisted customer support will prepare the
                    system to receive emails and prepare answers using the ai.
                    Once you have onboarded participants, you cannot enable /
                    disable this feature anymore.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell
              align="end"
              padding={[1, 1, 1, 0.5]}
              sx={{ minWidth: formMinWidth }}>
              <Switch
                id="aiAssistantEnabled"
                name="aiAssistantEnabled"
                onChange={handleSwitchChanged}
                checked={formik.values.aiAssistantEnabled}
              />
            </TableCell>
          </TableRow>
          {/* </TableBody>
      </Table>

      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              Manage documents
            </TableCell>
          </TableRow> */}

          <TableRow>
            <TableCell
              width="100%"
              padding={[1, 1, 1, 0.5]}
              sx={{ border: 'none' }}>
              {/* <ArgonTypography
                variant="caption"
                color="text"
                fontWeight="regular">
                Upload and manage documents that will be used by the AI to
                answer inquiries.
              </ArgonTypography> */}
              {/* </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}> */}
              {/* <ArgonBox lineHeight={1.4} mt={2} mb={1} ml={0.5}> */}

              <ArgonBox lineHeight={1.4}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Manage documents
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  <ArgonBox color="inherit" lineHeight={1.4} mt={0.5}>
                    Add pdf documents that will be used by the AI to generate
                    responses to support conversations. The more detailed the
                    information available to the AI, the better the responses.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>
            </TableCell>

            <TableCell
              align="end"
              padding={[1, 1, 1, 0]}
              sx={{ border: 'none' }}>
              {/* <ArgonBox mt={-0.5} mr={-1.5}> */}
              {/* <ArgonButton
                    variant="text"
                    color="success"
                    size="small"
                    disabled={!formik.values.aiAssistantClientId}
                    //         onClick={() => {
                    //           setOpenAddNewVolunteerPositionsForm(true);
                    // }}
                  >
                    Add new document
                  </ArgonButton> */}
              {formik.values.aiAssistantClientId &&
              formik.values.aiAssistantApiKey ? (
                <ArgonBox mr={-1}>
                  <AiKnowledeBaseDropzone
                    id="upload_aikb_documents"
                    label={'Upload document'}
                    variant="text"
                    color="secondary"
                    size="small"
                    clientId={formik.values.aiAssistantClientId}
                    apiKey={formik.values.aiAssistantApiKey}
                    onFileAdded={async (document) => {
                      console.log('Uploaded document: ', document);
                      let tempTrigger = reloadDocumentsTrigger + 1;
                      setReloadDocumentsTrigger(tempTrigger);

                      //vectorize the uploaded document, otherwise it will not be available to the knowledge base
                      await vectorizeUploadedDocument({
                        clientId: formik.values.aiAssistantClientId,
                        apiKey: formik.values.aiAssistantApiKey,
                        documentId: document._id
                      }).then(() => {
                        setReloadDocumentsTrigger(tempTrigger + 1);
                      });
                    }}
                    options={{
                      // clickable: Boolean(eventData?._id),
                      paramName: 'file',
                      // dictDefaultMessage: "Put your custom message here",
                      method: 'PUT',
                      chunking: false,
                      chunkSize: 1000000,
                      parallelChunkUploads: false,
                      retryChunks: false,
                      retryChunksLimit: 3,
                      forceChunking: false,
                      // uploadMultiple: true,
                      parallelUploads: 1,
                      addRemoveLinks: false,
                      // maxFiles: 1,
                      acceptedFiles: 'application/pdf',
                      autoQueue: true,
                      autoProcessQueue: true
                      // clickable: 'upload-terms-of-service-button'
                    }}
                  />
                </ArgonBox>
              ) : (
                <ArgonBox mr={-1}>
                  <ArgonButton
                    variant="text"
                    color="secondary"
                    size="small"
                    disabled={true}>
                    Upload disabled
                  </ArgonButton>
                </ArgonBox>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]} colSpan={2}>
              <DocumentsTable
                key={reloadDocumentsTrigger}
                clientId={formik.values.aiAssistantClientId}
                apiKey={formik.values.aiAssistantApiKey}
              />

              {/* <ArgonBox lineHeight={1.4} mt={2} mb={1} ml={0}>
                <ArgonTypography
                  display="block"
                  variant="caption"
                  fontWeight="bold">
                  Manage documents
                </ArgonTypography>
                <ArgonTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular">
                  Upload and manage documents that will be used by the AI to
                  answer inquiries.
                </ArgonTypography>
              </ArgonBox>
            </TableCell>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
              {/* <ArgonBox mt={2} mb={1}> */}
              {/* <ArgonButton
                id="upload_ai_assistant_documents"
                variant="text"
                color="secondary"
                size="small"
                disabled={!formik.values.aiAssistantClientId}
                onClick={() => {
                  console.log('Upload new documents');
                }}>
                Upload documents
              </ArgonButton> */}
              {/* </ArgonBox> */}
              {/* {scheduleAttachments?.length > 0 && (
                  <ArgonBox mt={2}>
                    {scheduleAttachments?.map((sa) => (
                      <ArgonBox
                        key={sa._id}
                        display="flex"
                        alignItems="baseline"
                        mb={0.5}>
                        <ArgonTypography
                          variant="caption"
                          color="dark"
                          fontWeight="regular">
                          <li>
                            <a
                              href={sa.url}
                              style={{ color: 'inherit' }}
                              target="_blank">
                              {sa.fileName}
                            </a>
                          </li>
                        </ArgonTypography>
                      </ArgonBox>
                    ))}
                  </ArgonBox>
                )} */}
            </TableCell>
          </TableRow>

          {/* <TableRow>
            <TableCell width="100%" padding={[1, 1, 1, 0.5]}></TableCell>
            <TableCell align="end" padding={[1, 1, 1, 0.5]}> */}
          {/* <ArgonBox mt={2} mb={1}>
                <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Upload new documents
                  </ArgonTypography>
                </ArgonBox>
                <ArgonDropzoneS3
                  id="upload_ai_assistant_documents"
                  pathToPresignedUrl={
                    process.env.REACT_APP_S3_MEDIA_UPLOAD_PRESIGNED_URL
                  }
                  disabled={!formik.values.aiAssistantClientId}
                  onFileAdded={(url, fileName) => {
                    onFileUploaded(url, fileName);
                  }}
                  // payloadPurpose="eventinstance-ai-assistant-documents"
                  metaData={{
                    event: eventInstance?.event?._id,
                    eventInstance: eventInstance?._id
                  }}
                  identifier={eventInstance?.event?._id}
                  options={{
                    paramName: 'file',
                    method: 'PUT',
                    chunking: true,
                    chunkSize: 1000000,
                    parallelChunkUploads: true,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    forceChunking: true,
                    parallelUploads: 3,
                    addRemoveLinks: true,
                    acceptedFiles: 'image/*,.jpg,.jpeg,.png,.gif,.pdf,.txt',
                    autoQueue: true,
                    autoProcessQueue: true
                  }}
                />
              </ArgonBox> */}
          {/* </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </Grid>
  );
}

export default AiAssistant;
