// =====================================================
// contexts/AuthProvider.jsx
// =====================================================
// Hauptkomponente für die Authentifizierung
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from './AuthContext';
import { ApolloContext } from './ApolloContext';
import { ApolloProvider as BaseApolloProvider } from '@apollo/client';

// Services importieren
import { AuthLogger, logger } from '../services/auth/Logger';
import { TokenService } from '../services/auth/TokenService';
import { CognitoService } from '../services/auth/CognitoService';
import { RealmService } from '../services/auth/RealmService';
import { ApolloAuthService } from '../services/auth/ApolloAuthService';
import { AuthService } from '../services/auth/AuthService';

// UI-Komponenten
import Loader from '../Loader'; // Anpassen an deine Komponente

// Umgebungsvariablen
const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
const GRAPHQL_ENDPOINT = `https://eu-central-1.aws.services.cloud.mongodb.com/api/client/v2.0/app/${REALM_APP_ID}/graphql`;

const AuthProvider = ({ children, userPool }) => {
  // Zustand für Auth-Status und Apollo Client
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [idTokenPayload, setIdTokenPayload] = useState(null);
  const [hasOrganizerAccess, setHasOrganizerAccess] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [apolloClient, setApolloClient] = useState(null);

  // Refs für Services, um Neuerstellung bei Re-Renders zu vermeiden
  const servicesRef = useRef(null);

  // Services initialisieren (nur einmal)
  if (!servicesRef.current) {
    logger.debug('Initializing auth services');

    // Token Service für zentrale Token-Verwaltung
    const tokenService = new TokenService({
      ttl: {
        idToken: 30 * 60 * 1000, // 30 Minuten
        accessToken: 5 * 60 * 1000, // 5 Minuten
        refreshToken: 14 * 24 * 60 * 60 * 1000 // 14 Tage
      },
      refreshThreshold: 5 * 60 * 1000 // 5 Minuten vor Ablauf
    });

    // Cognito Service
    const cognitoService = new CognitoService(userPool, {
      tokenService
    });

    // Realm Service
    const realmService = new RealmService(REALM_APP_ID, {
      tokenService
    });

    // Apollo Auth Service
    const apolloService = new ApolloAuthService({
      graphqlEndpoint: GRAPHQL_ENDPOINT,
      realmService,
      tokenService,
      onAuthError: () => {
        // Zur Login-Seite weiterleiten bei Auth-Fehlern
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      }
    });

    // Haupt-Auth-Service
    const authService = new AuthService({
      tokenService,
      cognitoService,
      realmService,
      apolloService,
      maxBatchSize: 5, // Maximal 5 gleichzeitige API-Anfragen für Orga-Namen
      batchTimeout: 50 // 50ms Timeout für Batch-Anfragen
    });

    servicesRef.current = {
      tokenService,
      cognitoService,
      realmService,
      apolloService,
      authService
    };
  }

  const {
    tokenService,
    cognitoService,
    realmService,
    apolloService,
    authService
  } = servicesRef.current;

  // Auth-Status-Änderungen überwachen
  useEffect(() => {
    logger.debug('Setting up auth state change listener');

    const unsubscribe = authService.addEventListener(
      'state:change',
      (state) => {
        setIsAuthenticated(state.isAuthenticated);
        setIsAnonymous(state.isAnonymous);
        setIsLoading(state.isLoading);
        setUserId(state.userId);
        setUserGroups(state.userGroups);
        setHasOrganizerAccess(state.hasOrganizerAccess);
        setOrganizations(state.organizations);

        // User- und Token-Informationen aus dem TokenService abrufen
        const idTokenPayload = tokenService.getTokenPayload('idToken');
        if (idTokenPayload) {
          setIdTokenPayload(idTokenPayload);
          setIdToken(tokenService.getToken('idToken'));

          // User-Informationen aus Cognito
          setUser(cognitoService.getCurrentUser());
        } else {
          setIdTokenPayload(null);
          setIdToken(null);
          setUser(null);
        }
      }
    );

    return unsubscribe;
  }, []);

  // Apollo Client-Reset bei Auth-Statusänderungen
  useEffect(() => {
    logger.debug('Setting up Apollo cache reset listener');

    const unsubscribe = authService.addEventListener(
      'apollo:resetCache',
      async () => {
        if (apolloClient) {
          try {
            logger.debug('Resetting Apollo client cache');
            await apolloClient.clearStore();
          } catch (error) {
            logger.error('Error resetting Apollo cache', error);
          }
        }
      }
    );

    return unsubscribe;
  }, [apolloClient]);

  // Initialisierung beim Laden
  useEffect(() => {
    logger.debug('Initializing auth provider');

    const initialize = async () => {
      try {
        // Auth-Service initialisieren
        await authService.initialize();

        // Apollo Client erstellen
        const client = apolloService.createApolloClient();
        setApolloClient(client);
      } catch (error) {
        logger.error('Error initializing auth provider', error);

        // Fallback zur anonymen Authentifizierung
        await realmService.loginAnonymously();

        // Apollo Client trotzdem erstellen
        const client = apolloService.createApolloClient();
        setApolloClient(client);
      }
    };

    initialize();

    // Cleanup
    return () => {
      logger.debug('Cleaning up auth provider');
      logger.destroy();
    };
  }, []);

  // Automatische Token-Aktualisierung
  useEffect(() => {
    // Nur für authentifizierte, nicht-anonyme Benutzer
    if (isAuthenticated && !isAnonymous) {
      logger.debug('Setting up token refresh interval');

      // Alle 15 Minuten aktualisieren
      const REFRESH_INTERVAL = 15 * 60 * 1000;

      const intervalId = setInterval(async () => {
        try {
          logger.debug('Proactively refreshing tokens');
          await authService.refreshTokens();
        } catch (error) {
          logger.error('Error during proactive token refresh', error);
        }
      }, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated, isAnonymous]);

  // Auth-Kontext-Wert
  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      isAnonymous,
      isLoading,
      idToken,
      idTokenPayload,
      userId,
      userGroups,
      user,
      hasOrganizerAccess,
      organizations,

      // Login-Funktion
      login: async (username, password) => {
        try {
          return await authService.login(username, password);
        } catch (error) {
          logger.error('Login error in context', error);
          throw error;
        }
      },

      // Logout-Funktion
      logout: async (redirectToLogin = false) => {
        try {
          return await authService.logout(redirectToLogin);
        } catch (error) {
          logger.error('Logout error in context', error);
          throw error;
        }
      },

      // Token-Aktualisierung
      refreshSession: async () => {
        try {
          return await authService.refreshTokens();
        } catch (error) {
          logger.error('Refresh session error in context', error);
          throw error;
        }
      },

      // Auth-Daten neu abrufen
      refetchAuthData: async () => {
        try {
          // Sitzung neu abrufen
          const session = await cognitoService.getSession();

          if (session) {
            // Auth-Daten aktualisieren
            await authService.updateAuthData(session);

            return {
              isAuthenticated: true,
              userId: session.idToken.payload['custom:userId'],
              userGroups: session.idToken.payload['cognito:groups'] || [],
              hasOrganizerAccess: authService.checkOrganizerAccess(
                session.idToken.payload['cognito:groups'] || []
              ),
              organizations: authService.extractOrganizationIds(
                session.idToken.payload['cognito:groups'] || []
              ),
              session
            };
          } else {
            return {
              isAuthenticated: false,
              userId: null,
              userGroups: [],
              hasOrganizerAccess: false,
              organizations: [],
              session: null
            };
          }
        } catch (error) {
          logger.error('Refetch auth data error in context', error);
          throw error;
        }
      },

      // Prüfen, ob das Cognito-Refresh-Token gültig ist
      isCognitoRefreshTokenValid: async () => {
        try {
          return await cognitoService.isRefreshTokenValid();
        } catch (error) {
          logger.error('Error checking Cognito refresh token', error);
          return false;
        }
      }
    }),
    [
      isAuthenticated,
      isAnonymous,
      isLoading,
      idToken,
      idTokenPayload,
      userId,
      userGroups,
      user,
      hasOrganizerAccess,
      organizations
    ]
  );

  // Apollo Kontext-Wert
  const apolloContextValue = useMemo(
    () => ({
      client: apolloClient,
      realmApp: realmService.app,
      getValidAccessToken: () => realmService.getValidAccessToken(),
      loginToRealm: (forceAnonymous) =>
        forceAnonymous
          ? realmService.loginAnonymously()
          : realmService.loginWithJwt(tokenService.getToken('idToken'))
    }),
    [apolloClient]
  );

  // Wenn noch geladen wird oder kein Apollo Client vorhanden ist, Ladeindikator anzeigen
  if (!apolloClient || isLoading) {
    return <Loader />;
  }

  // Provider rendern
  return (
    <AuthContext.Provider value={authContextValue}>
      <ApolloContext.Provider value={apolloContextValue}>
        <BaseApolloProvider client={apolloClient}>
          {children}
        </BaseApolloProvider>
      </ApolloContext.Provider>
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userPool: PropTypes.object.isRequired
};

export default AuthProvider;
