/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill, { Quill } from 'react-quill';

// react-quill styles
import 'react-quill/dist/quill.snow.css';
import 'assets/css/argon-editor-quill-error.css';

import EditorToolbar, { modules, formats } from './EditorToolbar';
// Custom styles for the ArgonEditor
import QuillEditorFlatRoot from './QuillEditorFlatRoot';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

// String.prototype.hashCode = function () {
//   var hash = 0,
//     i,
//     chr;
//   if (this.length === 0) return hash;
//   for (i = 0; i < this.length; i++) {
//     chr = this.charCodeAt(i);
//     hash = (hash << 5) - hash + chr;
//     hash |= 0; // Convert to 32bit integer
//   }
//   return hash;
// };

const QuillEditorFlat = forwardRef(function ArgonEditor(
  {
    id,
    uniqueKey,
    error,
    height,
    helperText,
    pr,
    pl,
    pt,
    pb,
    disabled,
    ...rest
  },
  ref
) {
  // const [key, setKey] = useState(
  //   'toolbar_' + props?.movements?.hashCode() + '_' + ObjectID().toHexString()
  // );

  const quillRef = useRef(); // Create a ref to store the Quill instance

  // Expose quillRef to parent component via forwardRef
  useImperativeHandle(ref, () => ({
    getEditor: () => quillRef.current?.getEditor(),
    getQuillRef: () => quillRef.current
  }));

  useEffect(() => {
    console.log('quillRef.current', id, quillRef.current);
    const quillInstance = quillRef.current.getEditor(); // Get the Quill instance
    console.log('quillInstance', quillInstance);

    quillInstance.on('text-change', function (delta, oldDelta, source) {
      console.log('Quill: Text changed!', delta, oldDelta, source);
      if (source === 'user') {
        delta.ops.forEach((op) => {
          if (op.insert && op.insert.image) {
            const quillEditor = quillInstance.root;
            const imgNodes = quillEditor.querySelectorAll('img');
            console.log('Quill: imgNodes', imgNodes);
            imgNodes.forEach((imgNode) => {
              imgNode.style.width = '100%';
              imgNode.width = '100%'; //! no clue why, but if don't add this, the picture will not be rendered at 100% width but at its original size
              console.log('Quill: imgNode', imgNode);
            });
          }
        });
      }
      console.log('Quill: Text changed!');
    });
  }, []);

  // useEffect(() => {
  //   console.log('disabled', disabled);
  //   if (disabled) {
  //     quillRef.current.getEditor().enable(false);
  //   } else {
  //     quillRef.current.getEditor().enable(true);
  //   }
  // }, [disabled]);

  console.log('ArgonEditor: uniqueKey', uniqueKey);

  return (
    <>
      <QuillEditorFlatRoot pr={pr} pl={pl} pt={pt} pb={pb}>
        <EditorToolbar
          id={uniqueKey}
          className={uniqueKey}
          // className={error ? 'ql-error' : uniqueKey}
        />
        <ReactQuill
          readOnly={disabled}
          id="editor"
          // id={id}
          ref={quillRef} // Pass the ref to the ReactQuill component
          className={uniqueKey}
          // className={error ? 'ql-error' : uniqueKey}
          // theme="snow"
          {...rest}
          modules={modules(uniqueKey)}
          formats={formats}
          style={{ height: height }}
        />

        {helperText && (
          <>
            <ArgonBox mt={-1} ml={0.5}>
              <ArgonTypography
                component="label"
                color="error"
                variant="caption"
                fontWeight="regular"
                textTransform="capitalize">
                {helperText}
              </ArgonTypography>
            </ArgonBox>
          </>
        )}
      </QuillEditorFlatRoot>
    </>
  );
});

export default QuillEditorFlat;
