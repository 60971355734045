import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataType, Table, useTable } from 'ka-table';

import 'assets/css/ka-table.css';
import ObjectID from 'bson-objectid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import ArgonBox from 'components/ArgonBox';
import Swal from 'sweetalert2';
import { IconButton, CircularProgress } from '@mui/material';

import ArgonTypography from 'components/ArgonTypography';

import { listDocuments } from 'services/REST_API/ai/kb-manage-documents';
import { deleteDocument } from 'services/REST_API/ai/kb-manage-documents';

// updateTrigger: to force updating the files list provide a changing trigger value
function DocumentsTable({ clientId, apiKey, updateTrigger }) {
  const { t, i18n } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  // State für Dokumente, die gerade gelöscht werden
  const [deletingDocuments, setDeletingDocuments] = useState([]);

  const [openDocumentsForm, setOpenDocumentsForm] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const columns = [
    {
      key: 'filename',
      title: 'File Name',
      width: 300,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'fileSize',
      title: 'File Size',
      width: 80,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'uploadedAt',
      title: 'Uploaded At',
      width: 120,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'status',
      title: 'Status',
      width: 100,
      dataType: DataType.String,
      isEditable: true
    },
    {
      key: 'action',
      title: '',
      width: 100,
      dataType: DataType.String
    }
  ];

  //   const tableProps = useTable({
  //     columns,
  //     data: spectatorTickets,
  //     rowKeyField: '_id',
  //     editingMode: 'cell'
  //   });

  const handleRowSelection = (rowKeyValue) => {
    const selectedDocument = documents.find(
      (document) => document._id.toString() === rowKeyValue
    );
    setSelectedDocument(selectedDocument);
    setOpenDocumentsForm(true);
  };

  const handleRowDeleteButtonClicked = (rowKeyValue) => {
    Swal.fire({
      title: 'Delete document?',
      text: 'Are you sure you want to delete this document? The content will be removed from the knowledge base and can no longer be used for AI-assisted responses to conversations.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Deleting document: ', rowKeyValue);
        // Setze den Löschstatus für dieses Dokument
        setDeletingDocuments((prev) => [...prev, rowKeyValue]);

        deleteDocument({ clientId, apiKey, documentId: rowKeyValue })
          .then(async (result) => {
            console.log('Document deleted: ', result);
            // refetch the documents
            await listDocuments({ clientId, apiKey }).then((documents) => {
              setDocuments(prepareData(documents.documents));
            });
          })
          .catch((error) => {
            console.error('Error deleting document: ', error);
          })
          .finally(() => {
            // Entferne den Löschstatus für dieses Dokument
            setDeletingDocuments((prev) =>
              prev.filter((id) => id !== rowKeyValue)
            );
          });
      }
    });
  };

  const dispatch = async (action) => {
    if (action.type === 'SaveCell') {
      // const { rowKeyValue, column, value } = action;
      // const updatedTickets = spectatorTickets
      //   .map((ticket) =>
      //     ticket._id === rowKeyValue
      //       ? { ...ticket, [column.key]: value }
      //       : ticket
      //   )
      //   .sort((a, b) => new Date(a.validDate) - new Date(b.validDate));
      // setSpectatorTickets(updatedTickets);
      // if (onUpdate) {
      //   onUpdate(updatedTickets);
      // }
    }
  };
  const prepareData = (data) => {
    let result = [];

    result = data?.map((item) => {
      return {
        ...item,
        fileSize: `${(item.fileSize / 1024 / 1024).toFixed(2)} MB`,
        uploadedAt: new Date(item.uploadedAt).toLocaleDateString(
          i18n.language,
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
            // second: '2-digit'
          }
        )
      };
    });

    return result;
  };

  useEffect(() => {
    if (clientId && apiKey) {
      setLoading(true);
      // fetching documents from the knowledge base
      listDocuments({ clientId, apiKey })
        .then((documents) => {
          console.log(
            'AiAssistant: Documents List: documents fetched from aikb api:',
            documents
          );
          setDocuments(prepareData(documents.documents));
        })
        .catch((error) => {
          console.error(
            'AiAssistant: Documents List: Error fetching documents from aikb api:',
            error
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientId, apiKey, updateTrigger]);

  // const handleDataUpdated = (updatedPositions) => {
  //   // Keep formatted fields in local state
  //   setVolunteerPositions(updatedPositions);

  //   // Remove formatted fields only for parent update
  //   const updatedPositionsWithoutFormattedFields = updatedPositions.map(
  //     (position) => {
  //       const { formattedStartDate, formattedEndDate, ...rest } = position;
  //       return rest;
  //     }
  //   );
  //   onUpdate && onUpdate(updatedPositionsWithoutFormattedFields);
  // };

  // if (!volunteerPositions || volunteerPositions?.length === 0) {
  //   return (
  //     <>
  //       <ArgonTypography variant="caption" color="error">
  //         No volunteer positions offered. Use button to add one or more.
  //       </ArgonTypography>
  //       <DialogWrapper
  //         title="Add Volunteer Position"
  //         open={openAddVolunteerPositionsForm}
  //         onClose={() => {
  //           setSelectedVolunteerPosition(null);
  //           setOpenAddVolunteerPositionsForm(false);
  //           onAddNewVolunteerPositionFormClosed &&
  //             onAddNewVolunteerPositionFormClosed();
  //         }}>
  //         <VolunteerPositionForm
  //           addNew={true}
  //           //   spectatorTicket={selectedSpectatorTicket}
  //           paymentsEnabled
  //           currency={
  //             eventInstance?.currency || eventInstance?.event?.currency || 'EUR'
  //           }
  //           onClose={() => setOpenAddVolunteerPositionsForm(false)}
  //           onSave={(newVolunteerPosition) => {
  //             const tempPositions = [
  //               ...volunteerPositions,
  //               newVolunteerPosition
  //             ].sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  //             setVolunteerPositions(tempPositions);
  //             setOpenAddVolunteerPositionsForm(false);
  //             // onUpdate && onUpdate(tempPositions);
  //             handleDataUpdated(tempPositions);
  //           }}
  //           onDelete={(deletedVolunteerPositionId) => {
  //             // filter out the deleted spectator ticket
  //             const updatedPositions = volunteerPositions.filter(
  //               (position) => position._id !== deletedVolunteerPositionId
  //             );

  //             setVolunteerPositions(updatedPositions);
  //             // onUpdate && onUpdate(updatedPositions);
  //             handleDataUpdated(updatedPositions);
  //           }}
  //         />
  //       </DialogWrapper>
  //     </>
  //   );
  // }

  return (
    <>
      {!loading && (!documents || documents?.length === 0) ? (
        <>
          <ArgonBox mb={2}>
            <ArgonTypography variant="caption" color="secondary">
              No documents found.
            </ArgonTypography>
          </ArgonBox>
        </>
      ) : (
        <>
          <div className="ka-table_transparent_dark_font_small">
            <Table
              //   {...tableProps}
              dispatch={dispatch}
              columns={columns}
              data={documents.sort((a, b) =>
                a.filename.localeCompare(b.filename)
              )}
              rowKeyField="_id"
              editingMode="cell"
              options={{
                search: true,
                pagination: true
              }}
              childComponents={{
                // dataRow: {
                //   elementAttributes: () => ({
                //     onClick: (event, extendedEvent) => {
                //       console.log('dataRow: onClick: extendedEvent', extendedEvent);

                //       //   table.selectSingleRow(extendedEvent.childProps.rowKeyValue);
                //       // setSelectedParticipantId(extendedEvent.childProps.rowKeyValue);
                //       // setSelectedRegistrationId(
                //       //   extendedEvent.childProps.rowData?.registration[0]?._id
                //       // );
                //       // setParticipantDetailsOpen(true);
                //       handleRowSelection(extendedEvent.childProps.rowKeyValue);
                //     }
                //   })
                // },
                cell: {
                  elementAttributes: () => ({
                    onClick: (event, extendedEvent) => {
                      if (extendedEvent?.childProps?.column?.key !== 'action') {
                        console.log(
                          'dataCell: onClick: extendedEvent',
                          extendedEvent
                        );

                        handleRowSelection(
                          extendedEvent.childProps.rowKeyValue
                        );
                      }
                    }
                  })
                },
                cellText: {
                  content: ({ column, rowKeyValue, value }) => {
                    switch (column?.key) {
                      // case 'price':
                      //   return (
                      //     <>
                      //       {new Intl.NumberFormat(i18n.language, {
                      //         style: 'currency',
                      //         currency:
                      //           eventInstance?.currency ||
                      //           eventInstance?.event?.currency ||
                      //           'EUR'
                      //       }).format(Number(value || 0) / 100)}
                      //     </>
                      //   );

                      // case 'initialQuantity':
                      //   return (
                      //     <>
                      //       {new Intl.NumberFormat(i18n.language).format(
                      //         Number(value || 0)
                      //       )}
                      //     </>
                      //   );
                      case 'action':
                        return (
                          <ArgonBox
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            width="100%"
                            gap={1}>
                            <IconButton
                              sx={{
                                fontSize: '1rem'
                              }}
                              onClick={() =>
                                handleRowDeleteButtonClicked(rowKeyValue)
                              }
                              disabled={deletingDocuments.includes(
                                rowKeyValue
                              )}>
                              {deletingDocuments.includes(rowKeyValue) ? (
                                <CircularProgress size={16} color="secondary" />
                              ) : (
                                <DeleteIcon color="secondary" />
                              )}
                            </IconButton>
                          </ArgonBox>
                        );

                      default:
                        //   console.log(
                        //     'Key of row clicked:',
                        //     column?.key,
                        //     rowKeyValue,
                        //     value
                        //   );
                        break;
                    }
                  }
                }
              }}
            />
          </div>
        </>
      )}
    </>
  );
}

DocumentsTable.propTypes = {
  clientId: PropTypes.string,
  apiKey: PropTypes.string,
  updateTrigger: PropTypes.any
};

export default DocumentsTable;
