import { Fragment, useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonAvatar from 'components/ArgonAvatar';
import ArgonBadge from 'components/ArgonBadge';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';

import { useLazyQuery, useQuery } from '@apollo/client';

import { EVENTINSTANCES_GET } from 'GraphQL/EventInstances/queries';
import LoaderWrap from 'LoaderWrap';

import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';
import { Link, useNavigate } from 'react-router-dom';
import selectData from 'options/selectData';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import Loader from 'Loader';
import { getSession } from 'services/cognito/cognitoAccount';

// Data
// const data = [
//   { img: team1, name: 'John Michael', status: 'online', badge: 'success' },
//   { img: team2, name: 'Alex Smith', status: 'in meeting', badge: 'warning' },
//   { img: team3, name: 'Samantha Ivy', status: 'offline', badge: 'error' },
//   { img: team4, name: 'John Michael', status: 'online', badge: 'success' }
// ];

function UpcomingEventsList({ organizationId }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [allowedEvents, setAllowedEvents] = useState([]);
  const [workInProgress, setWorkInProgress] = useState(true);

  const [fetchEventInstances, { data, loading, error }] = useLazyQuery(
    EVENTINSTANCES_GET,
    {
      //   variables: {
      //     query: {
      //       organizationId: organizationId,
      //       endDate_gte: new Date().toISOString()
      //     }
      //   },
      onCompleted: (data) => {
        console.log('Upcoming events: ', data);
      },
      onError: (error) => {
        console.log('Error fetching upcoming events: ', error);
      }
    }
  );

  // const { loading, error, data } = useQuery(EVENTINSTANCES_GET, {
  //   variables: {
  //     query: {
  //       event: {
  //         _id_in: allowedEvents
  //       },
  //       endDate_gte: new Date().toISOString()
  //     }
  //   },
  //   onCompleted: (data) => {
  //     console.log('Events fetched:', data);
  //   }
  // });

  // useEffect(() => {
  //   console.log('Organization ID: ', organizationId);
  //   // if (organizationId)
  //   fetchEventInstances({
  //     variables: {
  //       query: {
  //         //! organizationId: organizationId, // skip limit to orgaId for now as user can be event-admin without being orga-admin.
  //         endDate_gte: new Date().toISOString()
  //         // endDate_gte: new Date('2023-03-25').toISOString()
  //       }
  //     }
  //   });
  // }, [organizationId]);

  useEffect(() => {
    setWorkInProgress(true);
    getSession()
      .then(async (session) => {
        const groups = session.idToken.payload['cognito:groups'];
        if (groups) {
          // navigate('/login');

          const eventAdminGroups = groups.filter((group) =>
            group.startsWith('event-admin')
          );

          const associatedEvents = eventAdminGroups.map(
            (group) => group.split('-')[2]
          );
          console.log('Associated events:', associatedEvents);

          fetchEventInstances({
            variables: {
              query: {
                event: {
                  _id_in: associatedEvents
                },
                endDate_gte: new Date().toISOString()
              }
            }
          });

          // now get Events from database
          // setAllowedEvents(associatedEvents);
        }
      })

      .catch((error) => {
        console.error('Error fetching Auth token:', error);
        //! if not logged in, forward to login page
        navigate('/login');
      })
      .finally(() => {
        setWorkInProgress(false);
      });
  }, []);

  return (
    // <Card sx={{ maxHeight: '19.25rem', height: '19rem', overflowY: 'auto' }}></Card>
    <Card sx={{ maxHeight: '19.25rem', height: '100%' }}>
      <LoaderWrap show={workInProgress}>
        <ArgonBox p={3}>
          <ArgonBox display="flex" justifyContent="space-between" mb={1}>
            <ArgonTypography variant="h6" textTransform="capitalize">
              Upcoming Events
            </ArgonTypography>

            {data?.eventInstances?.length > 0 && (
              <ArgonBox>
                <ArgonButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  component={Link}
                  to="/organizer/events/create">
                  {data?.eventInstances?.length === 0
                    ? 'Create your first event!'
                    : 'Create new event'}
                </ArgonButton>
              </ArgonBox>
            )}
          </ArgonBox>

          <ArgonBox pb={1} sx={{ overflow: 'scroll', height: '100%' }}>
            {/* <ScrollBar>
            <div> */}
            <ArgonBox
              component="ul"
              display="flex"
              flexDirection="column"
              m={0}
              p={0}
              sx={{
                listStyle: 'none',
                height: '14.5rem',
                overflowY: 'auto'
              }}>
              {data?.eventInstances?.map((eventInstance, index) => (
                <Fragment key={eventInstance?._id}>
                  <ArgonBox component="li" py={1}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item alignItems="center" xs="auto">
                        {/* <img
                        src={eventInstance?.event?.pic_logo_url}
                        alt={eventInstance?.event?.name}
                        variant="rounded"
                      /> */}
                        <Link
                          to={`/organizer/events/${eventInstance?.event?._id}/${eventInstance?._id}`}>
                          <ArgonBox
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <ArgonBox
                              component="img"
                              src={
                                eventInstance?.event?.pic_logo_url ||
                                NoImagePlaceholder
                              }
                              shadow="md"
                              borderRadius="md"
                              width="80px"
                              height="80px"
                              style={{
                                objectFit: 'cover'
                              }}
                            />
                          </ArgonBox>
                        </Link>
                      </Grid>
                      <Grid item lineHeight={1.4} xs>
                        <Link
                          to={`/organizer/events/${eventInstance?.event?._id}/${eventInstance?._id}`}>
                          <ArgonTypography variant="regular" fontWeight="bold">
                            <ArgonBox>
                              {/* {`${eventInstance?.event?.name} - ${eventInstance?.name}`} */}
                              {eventInstance?.event?.name}
                              <br />
                              <ArgonTypography
                                variant="caption"
                                fontWeight="medium">
                                {eventInstance?.name ||
                                  selectData.eventInstanceTypes?.find(
                                    (type) => type.value === eventInstance?.type
                                  )?.label}
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonTypography>

                          <ArgonTypography variant="caption" color="secondary">
                            <ArgonBox mt={0.5}>
                              {eventInstance?.startDate &&
                                `${new Intl.DateTimeFormat(i18n.language, {
                                  dateStyle: 'long'
                                }).format(new Date(eventInstance?.startDate))}`}

                              {eventInstance?.endDate &&
                                eventInstance?.endDate !==
                                  eventInstance?.startDate && (
                                  <>
                                    {' '}
                                    -{/* <br /> */}{' '}
                                    {new Intl.DateTimeFormat(i18n.language, {
                                      dateStyle: 'long'
                                    }).format(new Date(eventInstance?.endDate))}
                                  </>
                                )}
                            </ArgonBox>
                          </ArgonTypography>

                          {/* <ArgonBadge
                        variant="contained"
                        color={badge}
                        badgeContent={status}
                        size="xs"
                        container
                      /> */}
                        </Link>
                      </Grid>
                      {/* <Grid item ml="auto">
                      <ArgonButton variant="outlined" color="info" size="small">
                        Add
                      </ArgonButton>
                    </Grid> */}
                    </Grid>
                  </ArgonBox>
                  {index !== data?.eventInstances.length - 1 && (
                    <ArgonBox
                      component="hr"
                      sx={({ palette: { grey } }) => ({
                        borderTop: `1px solid ${grey[300]}`,
                        borderBottom: 0
                      })}
                    />
                  )}
                </Fragment>
              ))}
            </ArgonBox>
            {/* </div>
          </ScrollBar> */}
            {data?.eventInstances?.length === 0 && (
              <ArgonBox mt={2} mb={2}>
                <ArgonButton
                  variant="gradient"
                  color="success"
                  size="small"
                  component={Link}
                  to="/organizer/events/create">
                  {data?.eventInstances?.length === 0
                    ? 'Create your first event!'
                    : 'Create new event'}
                </ArgonButton>
              </ArgonBox>
            )}
          </ArgonBox>
        </ArgonBox>
      </LoaderWrap>
    </Card>
  );
}

export default UpcomingEventsList;
