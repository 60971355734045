// services/auth/Logger.js
// Zentrale Logging-Funktionalität mit Performance-Optimierungen

export class AuthLogger {
  constructor(options = {}) {
    this.debugMode = options.debugMode ?? process.env.NODE_ENV !== 'production';
    this.minLevel = options.minLevel || (this.debugMode ? 'debug' : 'warn');
    this.prefix = options.prefix || '🔐 Auth:';
    this.errorPrefix = options.errorPrefix || '❌ Auth Error:';

    // Performance: Rate-Limiting für Logs
    this.logCounts = {};
    this.logCountsResetInterval = setInterval(() => {
      this.logCounts = {};
    }, 60000); // Reset counter every minute

    // Performance: Buffer für Logs in Produktionsumgebung
    this.logBuffer = [];
    this.flushInterval = null;
    if (!this.debugMode) {
      this.flushInterval = setInterval(() => this.flushLogs(), 5000);
    }
  }

  debug(message, ...args) {
    if (!this.debugMode) return;
    this._log('debug', message, args);
  }

  info(message, ...args) {
    this._log('info', message, args);
  }

  warn(message, ...args) {
    this._log('warn', message, args);
  }

  error(message, error) {
    // Rate-Limiting für identische Fehler
    const key = `error:${message}:${error?.message || 'unknown'}`;
    if (this._shouldThrottle(key)) return;

    if (this.debugMode) {
      console.error(`${this.errorPrefix} ${message}`, error);
      if (error?.message) {
        console.error(`Error message: ${error.message}`);
      }
      if (error?.stack && this.debugMode) {
        console.error(`Stack trace: ${error.stack}`);
      }
    } else {
      // In Produktion minimales Logging
      this.logBuffer.push({
        level: 'error',
        message: `${this.errorPrefix} ${message}`,
        timestamp: new Date()
      });
    }
  }

  _log(level, message, args = []) {
    // Prüfen, ob Log-Level ausgegeben werden soll
    const levels = ['debug', 'info', 'warn', 'error'];
    if (levels.indexOf(level) < levels.indexOf(this.minLevel)) return;

    // Rate-Limiting für häufige Logs
    const key = `${level}:${message}`;
    if (this._shouldThrottle(key)) return;

    if (this.debugMode) {
      switch (level) {
        case 'debug':
        case 'info':
          console.log(`${this.prefix} ${message}`, ...args);
          break;
        case 'warn':
          console.warn(`${this.prefix} ${message}`, ...args);
          break;
      }
    } else {
      // In Produktion Logs puffern
      this.logBuffer.push({
        level,
        message: `${this.prefix} ${message}`,
        timestamp: new Date()
      });
    }
  }

  // Rate-Limiting für Logs
  _shouldThrottle(key) {
    if (!this.logCounts[key]) {
      this.logCounts[key] = 1;
      return false;
    }

    this.logCounts[key]++;

    // Bei mehr als 3 identischen Logs in einer Minute, nur jedes 5. ausgeben
    if (this.logCounts[key] > 3) {
      return this.logCounts[key] % 5 !== 0;
    }

    return false;
  }

  // Gepufferte Logs in Production-Umgebung ausgeben
  flushLogs() {
    if (this.logBuffer.length === 0) return;

    // Logs gruppieren und zählen
    const groupedLogs = {};
    this.logBuffer.forEach((log) => {
      const key = `${log.level}:${log.message}`;
      if (!groupedLogs[key]) {
        groupedLogs[key] = { ...log, count: 1 };
      } else {
        groupedLogs[key].count++;
      }
    });

    // Ausgabe der gruppierten Logs
    Object.values(groupedLogs).forEach((log) => {
      const message =
        log.count > 1
          ? `${log.message} (${log.count} occurrences)`
          : log.message;

      switch (log.level) {
        case 'debug':
        case 'info':
          console.log(message);
          break;
        case 'warn':
          console.warn(message);
          break;
        case 'error':
          console.error(message);
          break;
      }
    });

    // Buffer leeren
    this.logBuffer = [];
  }

  // Aufräumen
  destroy() {
    if (this.logCountsResetInterval) {
      clearInterval(this.logCountsResetInterval);
    }
    if (this.flushInterval) {
      this.flushLogs(); // Final flush
      clearInterval(this.flushInterval);
    }
  }
}

// Singleton-Instanz
export const logger = new AuthLogger();
