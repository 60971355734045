/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-flatpickr components
import Flatpickr from 'react-flatpickr';

// react-flatpickr styles
// import 'flatpickr/dist/flatpickr.css';
import 'assets/css/flatpickr.css';

// Argon Dashboard 2 PRO MUI components
import ArgonInput from 'components/ArgonInput';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from 'components/TooltipWrapper';
import ArgonTypography from 'components/ArgonTypography';
import ArgonBox from 'components/ArgonBox';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputAdornment } from '@mui/material';

function ArgonDatePicker({
  id,
  name,
  input,
  options,
  label,
  tooltip,
  required,
  width,
  ...rest
}) {
  const { i18n } = useTranslation();

  let defaultOptions = {
    locale: { firstDayOfWeek: 1 },
    // altInput: true,
    altInput: false,
    altFormat: 'j. F Y',
    allowInput: true
  };

  return (
    <>
      {(label || tooltip) && (
        <ArgonBox sx={{ width: width }}>
          <TooltipWrapper tooltip={tooltip}>
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              // color={textColor}
              {...rest}>
              {label}&nbsp;
              {required && (
                // <sup>
                <span style={{ color: 'red' }}>*</span>
                // </sup>
              )}
              {tooltip && (
                <ArgonBox ml={1} display="inline-block">
                  <ArgonTypography variant="regular">
                    <InfoOutlinedIcon color="secondary" />
                  </ArgonTypography>
                </ArgonBox>
              )}
            </ArgonTypography>
          </TooltipWrapper>
        </ArgonBox>
      )}
      <ArgonBox sx={{ width: width }}>
        <Flatpickr
          // options={{ formatDate: (date) => i18n.format(date), ...options }}
          options={{ ...defaultOptions, ...options }}
          {...rest}
          placeholder={input.placeholder}
          render={({ defaultValue }, ref) => (
            <>
              <ArgonInput
                id={id}
                name={name}
                defaultValue={i18n.format(defaultValue)}
                placeholder={input.placeholder}
                inputRef={ref}
                endAdornment={
                  required && !label && !rest.value ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon sx={{ color: 'error.main' }} />
                    </InputAdornment>
                  ) : undefined
                }
                {...input}
              />
            </>
          )}
        />
      </ArgonBox>
    </>
  );
}

// Setting default values for the props of ArgonDatePicker
ArgonDatePicker.defaultProps = {
  input: {}
};

// Typechecking props for the ArgonDatePicker
ArgonDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any)
};

export default ArgonDatePicker;
