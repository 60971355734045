/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from 'Layout/DashboardLayout';
import DashboardNavbar from 'Layout/DashboardNavbar';
import Footer from 'Layout/DashboardLayout/Footer';
// import DetailedStatisticsCard from 'examples/Cards/StatisticsCards/DetailedStatisticsCard';
// import SalesTable from 'examples/Tables/SalesTable';
// import Table from 'examples/Tables/Table';
// import CategoriesList from 'examples/Lists/CategoriesList';
// import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';

// // Argon Dashboard 2 PRO MUI base styles
// import typography from 'assets/theme/base/typography';

// // Dashboard layout components
// import Slider from 'layouts/dashboards/default/components/Slider';
// import TeamMembers from 'layouts/dashboards/default/components/TeamMembers';
// import TodoList from 'layouts/dashboards/default/components/TodoList';
// import ProgressTrack from 'layouts/dashboards/default/components/ProgressTrack';
// import BalanceCard from 'layouts/dashboards/default/components/BalanceCard';
// import CryptoCard from 'layouts/dashboards/default/components/CryptoCard';

// // Pages layout components
// import Post from 'layouts/pages/profile/teams/components/Post';

// Data
// import reportsBarChartData from 'layouts/dashboards/default/data/reportsBarChartData';
// import gradientLineChartData from 'layouts/dashboards/default/data/gradientLineChartData';
// import projectsTableData from 'layouts/dashboards/default/data/projectsTableData';
// import salesTableData from 'layouts/dashboards/default/data/salesTableData';
// import authorsTableData from 'layouts/dashboards/default/data/authorsTableData';
// import categoriesListData from 'layouts/dashboards/default/data/categoriesListData';
import { Card, Collapse } from '@mui/material';
import StripeTaxAlert from 'components/StripeTaxAlert';
import NetRevenueOverTimeChart from './Charts/Organization/NetRevenueOverTime/NetRevenueOverTimeChart';
import { useEffect, useState } from 'react';
// import { getSession } from 'services/cognito/cognitoAccount';
// import { getUserGroups } from 'services/cognito/cognitoAccount';
import { getCurrentUsersOrganizations } from 'services/cognito/userOrganizations';
import ArgonSelect from 'components/ArgonSelect';
import UpcomingEventsList from './Lists/UpcomingEvents';
import OpenOrders from './Lists/OpenOrders';
import TopRowStatisticsCards from './Cards/TopCardsRow';
import LoaderWrap from 'LoaderWrap';
import { checkIfStripeTaxIsActivated } from 'services/REST_API/organization';
import { Link } from 'react-router-dom';
import TasksByStatusDoughnut from '../Tasks/Statistics/TasksByStatusDoughnut';
import DueTasksList from './Lists/DueTasksList';
import DashboardTasksByStatusDoughnut from './Charts/User/TasksByStatusDoughnut';

function Default() {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [stripeTaxIsActivated, setStripeTaxIsActivated] = useState(undefined);
  const [stripeTaxLoading, setStripeTaxLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const hasOrganizerAccess = organizations.length > 0;

  useEffect(() => {
    setLoading(true);
    getCurrentUsersOrganizations()
      .then((res) => {
        console.log('User organizations: ', res);

        // put into correct format (label, value)
        let temp = res.map((org) => ({
          label: org?.organizationName,
          value: org?.organizationId
        }));
        console.log('temp: ', temp);
        setOrganizations(temp);
        setSelectedOrganization(temp[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSetSelectedOrganization = (organization) => {
    console.log('Selected organization: ', organization);

    setSelectedOrganization(organization);
  };

  // const { size } = typography;
  return (
    <DashboardLayout bgColor="#466B72">
      <DashboardNavbar title="Dashboard" />

      <ArgonBox
      // py={3}
      >
        <LoaderWrap loading={loading}>
          <Collapse in={organizations?.length > 0}>
            <ArgonBox mb={3}>
              <ArgonSelect
                id="organization-select"
                name="organization-select"
                size="small"
                placeHolder="Select Organization"
                options={organizations}
                onChange={handleSetSelectedOrganization}
                value={selectedOrganization}
                overFlow
              />
            </ArgonBox>
          </Collapse>
          {/* <StripeTaxAlert
            organizationId={selectedOrganization?.value}
            showLoader={true}
          /> */}
          <Grid container spacing={3} mb={3}>
            {hasOrganizerAccess && (
              <>
                <Grid item xs={12}>
                  <TopRowStatisticsCards
                    organizationId={selectedOrganization?.value}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NetRevenueOverTimeChart
                    organizationId={selectedOrganization?.value}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Card sx={{ height: '100%', flex: 1 }}>
                    <DashboardTasksByStatusDoughnut
                      organizationId={selectedOrganization?.value}
                      width="600px"
                      maxWidth="600px"
                      height="200px"
                      hasCard={false}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <DueTasksList />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <UpcomingEventsList
                    organizationId={selectedOrganization?.value}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <OpenOrders />
                </Grid>
              </>
            )}
          </Grid>
        </LoaderWrap>
      </ArgonBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Default;
