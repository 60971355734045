/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Collapse, Icon, IconButton, Switch } from '@mui/material';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArgonDatePicker from 'components/ArgonDatePicker';
// import ObjectID from 'bson-objectid';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';

import TableCell from 'components/TableCell';
import ArgonInput from 'components/ArgonInput';
import DivisionList from '../Divisions/DivisionList';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

import { EVENTINSTANCE_UPDATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_DELETE } from 'GraphQL/EventInstances/mutations';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FormatMoney } from 'format-money-js';
import { useSnackbar } from 'notistack';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import Loader from 'Loader';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import ArgonEditor from 'components/ArgonEditor';
import ObjectID from 'bson-objectid';
import { deactivateStripeProduct } from 'services/REST_API/stripe';
import { updateStripeProduct } from 'services/REST_API/stripe';
import { createStripeProduct } from 'services/REST_API/stripe';
import { setNewPriceForStripeProduct } from 'services/REST_API/stripe';
import StripeTaxRateSelector from 'Routes/Organizer/Organization/Settings/Payments/StripeTaxRates/Selector';
import { Link } from 'react-router-dom';
import FormikNumberInput from 'components/FormikNumberInput';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { nanoid } from 'nanoid';
import ArgonDropzoneS3 from 'components/ArgonDropzoneS3';
import { timeZones } from 'options/timeZones';

import AutoInvitationSettingsWizard from '../Auto-Invitation/Wizard/AutoInvitationSettingsWizard';
import AutoInvitationStatistics from '../Auto-Invitation/Statistics';
import SpectatorTicketsTable from '../SpectatorTickets/SpectatorTicketsTable';
import { calculateSpectatorTicketPriceWithFee } from 'services/calculateFees';
import VolunteerPositionsTable from '../Volunteers/Positions/VolunteerPositionsTable';
import { removeGqlGarbageFromArrays } from 'services/removeGqlGarbage';

// Import the new components
import BasicInfo from './components/BasicInfo';
import Parameters from './components/Parameters';
import Location from './components/Location';
import ScheduleDetails from './components/ScheduleDetails';
import AiAssistant from '../AiAssistant';
import OnlineQualifierSettings from './components/OnlineQualifierSettings';

function EventInstanceDetails({
  eventInstanceId,
  open,
  addNew,
  addNewEventType,
  onEventInstanceChanged
}) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  moment.locale(i18n.language);

  const [spectatorTicketsHaveChanged, setSpectatorTicketsHaveChanged] =
    useState(false);
  const [volunteerPositionsHaveChanged, setVolunteerPositionsHaveChanged] =
    useState(false);
  const [somethingHasChanged, setSomethingHasChanged] = useState(false);

  const [workInProgress, setWorkInProgress] = useState(false);

  const [expanded, setExpanded] = useState(open || true);
  const [divisionsExpanded, setDivisionsExpanded] = useState(true);

  const [autoInvitationsExpanded, setAutoInvitationsExpanded] = useState(true);
  const [autoInvitationWizardOpen, setAutoInvitationWizardOpen] =
    useState(false);
  const [showInvitationProgress, setShowInvitationProgress] = useState(false);

  const [volunteerPositionsExpanded, setVolunteerPositionsExpanded] =
    useState(false);

  const [spectatorTicketsExpanded, setSpectatorTicketsExpanded] =
    useState(false);

  const [aiAssistantExpanded, setAiAssistantExpanded] = useState(false);
  const [aiAssistantHaveChanged, setAiAssistantHaveChanged] = useState(false);
  const [event, setEvent] = useState({});

  const [gqlUpdateEventInstance] = useMutation(EVENTINSTANCE_UPDATE);
  const [gqlDeleteEventInstance] = useMutation(EVENTINSTANCE_DELETE);

  const [editor1Key, setEditor1Key] = useState(nanoid());
  const [editor2Key, setEditor2Key] = useState(nanoid());

  const [scheduleAttachments, setScheduleAttachments] = useState([]);

  const [openAddSpectatorTicketsForm, setOpenAddSpectatorTicketsForm] =
    useState(false);

  const [
    openAddNewVolunteerPositionsForm,
    setOpenAddNewVolunteerPositionsForm
  ] = useState(false);

  const [spectatorTicketsOffered, setSpectatorTicketsOffered] = useState([]);
  const [volunteerPositionsOffered, setVolunteerPositionsOffered] = useState(
    []
  );
  const { data, loading, refetch } = useQuery(EVENTINSTANCE_GET_BY_ID, {
    variables: { eventInstanceId: eventInstanceId },
    onCompleted: async (data) => {
      console.log('EventInstanceDetails: data loaded:', data);

      await getEvent({ variables: { eventId: data.eventInstance?.eventId } });

      // provide formik with values from database

      setScheduleAttachments(data?.eventInstance?.scheduleAttachments || []);

      formik.setFieldValue('_id', data.eventInstance?._id, false);
      formik.setFieldValue('eventId', data.eventInstance?.event?.id, false);
      formik.setFieldValue(
        'creationDate',
        data.eventInstance?.creationDate,
        false
      );

      // creationDate: data.eventInstance?.creationDate ||
      //   new Date().toDateString(),

      formik.setFieldValue(
        'type',
        data.eventInstance?.type || addNewEventType || '',
        false
      );

      formik.setFieldValue(
        'startDate',
        data.eventInstance?.startDate || '',
        false
      );
      formik.setFieldValue('endDate', data.eventInstance?.endDate || '', false);
      formik.setFieldValue(
        'isVirtualEvent',
        data.eventInstance?.isVirtualEvent || false,
        false
      );
      formik.setFieldValue(
        'isOpenRegistration',
        data.eventInstance?.isOpenRegistration,
        false
      );

      // isOpenRegistration: eventInstance?.isOpenRegistration ||
      //   // Boolean(addNewEventType === "onlineQualifier") ||
      //   true,

      formik.setFieldValue('name', data.eventInstance?.name || '', false);
      formik.setFieldValue(
        'description',
        data.eventInstance?.description || '',
        false
      );
      formik.setFieldValue(
        'registrationDeadline',
        data.eventInstance?.registrationDeadline || '',
        false
      );
      formik.setFieldValue(
        'organizationId',
        data.eventInstance?.organizationId || '',
        false
      );
      formik.setFieldValue(
        'maxParticipants',
        data.eventInstance?.maxParticipants || 0,
        false
      );
      formik.setFieldValue(
        'registrationFee',
        Number(data.eventInstance?.registrationFee) / 100 || 0,
        false
      );
      formik.setFieldValue(
        'address1',
        data?.eventInstance?.location?.address1 || '',
        false
      );
      formik.setFieldValue(
        'address2',
        data?.eventInstance?.location?.address2 || '',
        false
      );
      formik.setFieldValue(
        'zip',
        data?.eventInstance?.location?.zip || '',
        false
      );
      formik.setFieldValue(
        'city',
        data?.eventInstance?.location?.city || '',
        false
      );
      formik.setFieldValue(
        'country',
        data?.eventInstance?.location?.country || ''
      );
      formik.setFieldValue(
        'timeZone',
        data?.eventInstance?.timeZone || '',
        false
      );
      formik.setFieldValue(
        'stripeTaxRateId',
        data?.eventInstance?.stripeTaxRateId || '',
        false
      );
      formik.setFieldValue(
        'stripeProductId',
        data?.eventInstance?.stripeProductId || '',
        false
      );
      formik.setFieldValue(
        'stripePriceId',
        data?.eventInstance?.stripePriceId || '',
        false
      );

      formik.setFieldValue(
        'setLimitPerEventInstance',
        Boolean(data?.eventInstance?.maxParticipants),
        false
      );
      formik.setFieldValue(
        'maxParticipants',
        data?.eventInstance?.maxParticipants || null,
        false
      );

      formik.setFieldValue(
        'askForTShirtSize',

        data?.eventInstance?.customRequiredFields?.find(
          (crf) => crf.name === 'tShirtSize'
        )?.required == true || false,
        false
      );

      formik.setFieldValue(
        'askForCountry',
        data?.eventInstance?.customRequiredFields?.find(
          (crf) => crf.name === 'country'
        )?.required == true || false,
        false
      );

      formik.setFieldValue(
        'scheduleDetails',
        data?.eventInstance?.scheduleDetails || null,
        false
      );

      formik.setFieldValue(
        'spectatorTicketsSold',
        data?.eventInstance?.spectatorTicketsSold || 0,
        false
      );

      // for some reason formik.setFieldValue is not working when updating the spectatorTicketsOffered, so I needed to park the values in state instead of in formik.values
      // formik.setFieldValue(
      //   'spectatorTicketsOffered',
      //   data?.eventInstance?.spectatorTicketsOffered || []
      // );

      if (data?.eventInstance?.spectatorTicketsOffered?.length > 0)
        setSpectatorTicketsOffered(
          data?.eventInstance?.spectatorTicketsOffered
        );

      formik.setFieldValue(
        'spectatorTicketsOffered',
        data?.eventInstance?.spectatorTicketsOffered,
        false
      );
      console.log(
        'eventInstanceDetails: spectatorTicketsOffered',
        data?.eventInstance?.spectatorTicketsOffered
      );

      formik.setFieldValue(
        'spectatorTicketsLimit',
        data?.eventInstance?.spectatorTicketsLimit || null,
        false
      );

      formik.setFieldValue(
        'spectatorTicketSalesStartDate',
        data?.eventInstance?.spectatorTicketSalesStartDate || null,
        false
      );
      formik.setFieldValue(
        'spectatorTicketSalesEndDate',
        data?.eventInstance?.spectatorTicketSalesEndDate || null,
        false
      );

      formik.setFieldValue(
        'volunteerRegistrationEnabled',
        data?.eventInstance?.volunteerRegistrationEnabled || false,
        false
      );

      setVolunteerPositionsExpanded(
        data?.eventInstance?.volunteerRegistrationEnabled || false
      );

      formik.setFieldValue(
        'volunteerPositionsOffered',
        data?.eventInstance?.volunteerPositionsOffered || [],
        false
      );

      formik.setFieldValue(
        'volunteerEnrollmentStartDate',
        data?.eventInstance?.volunteerEnrollmentStartDate || null,
        false
      );
      formik.setFieldValue(
        'volunteerEnrollmentEndDate',
        data?.eventInstance?.volunteerEnrollmentEndDate || null,
        false
      );
      formik.setFieldValue(
        'aiAssistantEnabled',
        data?.eventInstance?.aiAssistantEnabled || false,
        false
      );
      formik.setFieldValue(
        'aiAssistantClientId',
        data?.eventInstance?.aiAssistantClientId || '',
        false
      );
      formik.setFieldValue(
        'aiAssistantApiKey',
        data?.eventInstance?.aiAssistantApiKey || '',
        false
      );
      formik.setFieldValue(
        'allowedVideoUploadPlatforms',
        data?.eventInstance?.allowedVideoUploadPlatforms || ['all'],
        false
      );
      formik.setFieldValue(
        'leaderboardPublishingDate',
        data?.eventInstance?.leaderboardPublishingDate || null,
        false
      );
      formik.setFieldValue(
        'enableVideoUpload',
        data?.eventInstance?.enableVideoUpload || false,
        false
      );
    },
    onError: (error) => {
      console.error('onError', error);
    },

    nextFetchPolicy: 'network-only'
  });

  const [getEvent] = useLazyQuery(EVENT_GET_BY_ID, {
    onCompleted: (data) => {
      console.log('onCompleted: event data fetched:', data);
      setEvent(data.event);
    }
  });

  const eventInstance = data?.eventInstance;

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const validationSchema = Yup.object({
    type: Yup.string()
      .oneOf(selectData.eventInstanceTypes.map((item) => item.value))
      .required('Required'),

    registrationDeadline: Yup.string().required('Required'),
    // registrationFee: Yup.number('Must be a valid number'),
    startDate: Yup.date('Must be a valid date').required('Required'),
    endDate: Yup.date('Must be a valid date')
      .required('Required')
      .min(Yup.ref('startDate'), 'End date must be after start date'),
    // volunteerEnrollmentStartDate: Yup.date('Must be a valid date').nullable(),
    // volunteerEnrollmenEndDate: Yup.date('Must be a valid date')
    //   .nullable()
    //   .min(
    //     Yup.ref('volunteerEnrollmentStartDate'),
    //     'End date must be after start date'
    //   )
    volunteerEnrollmentStartDate: Yup.date('Must be a valid date').nullable(),
    volunteerEnrollmentEndDate: Yup.date('Must be a valid date')
      .nullable()
      .when('volunteerEnrollmentStartDate', {
        is: (val) => val && val !== '',
        then: Yup.date()
          .required('Required')
          .min(
            Yup.ref('volunteerEnrollmentStartDate'),
            'End date must be after start date'
          )
      })
  });

  const formik = useFormik({
    initialValues: {
      _id: eventInstance?._id, //|| ObjectID.toHexString(),
      eventId: eventInstance?.eventId || '',
      creationDate: eventInstance?.creationDate || new Date().toDateString(),

      type: eventInstance?.type || addNewEventType || '',
      startDate: eventInstance?.startDate || '',
      endDate: eventInstance?.endDate || '',

      isVirtualEvent:
        eventInstance?.isVirtualEvent ||
        // Boolean(addNewEventType === "onlineQualifier") ||
        false,

      isOpenRegistration:
        eventInstance?.isOpenRegistration ||
        // Boolean(addNewEventType === "onlineQualifier") ||
        true,
      name: eventInstance?.name || '',
      description: eventInstance?.description || '',
      registrationDeadline: eventInstance?.registrationDeadline || '',
      organizationId:
        eventInstance?.organizationId || eventInstance?.event?._id || '',
      maxParticipants: eventInstance?.maxParticipants || 0,
      setLimitPerEventInstance:
        Boolean(eventInstance?.maxParticipants) || false,
      registrationFee: Number(eventInstance?.registrationFee) / 100 || 0,
      address1: eventInstance?.location?.address1 || '',
      address2: eventInstance?.location?.address2 || '',
      city: eventInstance?.location?.city || '',
      zip: eventInstance?.location?.zip || '',
      country: eventInstance?.location?.country || '',
      timeZone: eventInstance?.timeZone || '',
      stripeProductId: eventInstance?.stripeProductId || '',
      stripePriceId: eventInstance?.stripePriceId || '',
      stripeTaxRateId: eventInstance?.stripeTaxRateId || '',
      scheduleDetails: eventInstance?.scheduleDetails || '',

      // askForTShirtSize: eventInstance?.customRequiredFields?.find(
      //   (crf) => crf.name === 'tShirtSize'
      // )?.required == true,
      // askForCountry: eventInstance?.customRequiredFields?.find(
      //   (crf) => crf.name === 'country'
      // )?.required == true

      spectatorTicketsSold: eventInstance?.spectatorTicketsSold || 0,

      // spectatorTicketsOffered: eventInstance?.spectatorTicketsOffered || [], //! for some reason formik.setFieldValue is not working here, so I needed to park the values in state instead of in formik.values
      spectatorTicketsOffered:
        removeGqlGarbageFromArrays(spectatorTicketsOffered) || [],

      spectatorTicketsLimit: eventInstance?.spectatorTicketsLimit || null,

      spectatorTicketSalesStartDate:
        eventInstance?.spectatorTicketSalesStartDate ||
        // new Date().toDateString(),
        null,
      spectatorTicketSalesEndDate:
        eventInstance?.spectatorTicketSalesEndDate || null,

      volunteerRegistrationEnabled:
        eventInstance?.volunteerRegistrationEnabled || false,

      volunteerPositionsOffered:
        removeGqlGarbageFromArrays(eventInstance?.volunteerPositionsOffered) ||
        [],
      volunteerEnrollmentStartDate:
        eventInstance?.volunteerEnrollmentStartDate || null,
      volunteerEnrollmentEndDate:
        eventInstance?.volunteerEnrollmentEndDate || null,

      aiAssistantEnabled: eventInstance?.aiAssistantEnabled || false,
      aiAssistantClientId: eventInstance?.aiAssistantClientId || '',
      aiAssistantApiKey: eventInstance?.aiAssistantApiKey || '',

      allowedVideoUploadPlatforms:
        eventInstance?.allowedVideoUploadPlatforms || [],
      leaderboardPublishingDate:
        eventInstance?.leaderboardPublishingDate || null,

      enableVideoUpload: eventInstance?.enableVideoUpload || false
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setWorkInProgress(true);

      // alert(JSON.stringify(values, null, 2));
      console.log('EventInstanceDetails: Submitting: ', values);

      const customRequiredFields = [];

      if (values.askForCountry) {
        customRequiredFields.push({
          name: 'country',
          label: 'Country to represent',
          type: 'countrySelector',
          belongsTo: 'registration',
          required: true
        });
      }

      if (values.askForTShirtSize) {
        customRequiredFields.push({
          name: 'tShirtSize',
          label: 'T-Shirt Size',
          type: 'select',
          belongsTo: 'participant',
          options: [
            {
              value: 'XS',
              label: 'XS'
            },
            {
              value: 'S',
              label: 'S'
            },
            {
              value: 'M',
              label: 'M'
            },
            {
              value: 'L',
              label: 'L'
            },
            {
              value: 'XL',
              label: 'XL'
            },
            {
              value: 'XXL',
              label: 'XXL'
            }
          ],

          required: true
        });
      }

      if (!addNew) {
        let stripeProduct;

        console.log('eventInstance to update:', eventInstance);

        // if there is a stripe product id, update the product; if not, create a new one
        if (eventInstance?.stripeProductId) {
          console.log('updating stripe product');
          await updateStripeProduct({
            organizationId: values?.organizationId,
            productId: eventInstance?.stripeProductId,
            productName:
              eventInstance?.event?.name +
              ' - ' +
              (values?.name ||
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === values?.type
                ).label),
            description: values.description
          }).catch((err) => {
            console.error(
              'error updating stripe product for updated eventInstance',
              err
            );
          });

          // if price has changed, set new price
          if (
            fm.un(values?.registrationFee) * 100 !==
            eventInstance?.registrationFee
          ) {
            console.log('Updating stripe price');
            await setNewPriceForStripeProduct({
              organizationId: values?.organizationId,
              productId: eventInstance?.stripeProductId,

              newPriceInCents: fm.un(values?.registrationFee) * 100,
              newCurrency:
                values?.currency ||
                eventInstance?.currency ||
                eventInstance?.event?.currency ||
                eventInstance?.event?.organization?.currency ||
                'EUR'
            }).catch((err) => {
              console.error(
                'error updating stripe price for updated eventInstance',
                err
              );
            });
          } else {
            console.log('Price has not changed. Not updated in stripe.');
          }
        } else {
          // if orga has stripe account connected, create a product
          console.log(
            'eventInstance?.event?.organization?.stripeAccountId',
            eventInstance?.event?.organization?.stripeAccountId
          );
          if (
            eventInstance?.event?.organization?.stripeAccountId &&
            eventInstance?.event?.organization?.stripeAccountId !== ''
          ) {
            console.log('creating new stripe product');
            stripeProduct = await createStripeProduct({
              organizationId: values?.organizationId,
              productName:
                eventInstance?.event?.name +
                ' - ' +
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === values?.type
                ).label,

              defaultCurrency:
                values?.currency ||
                eventInstance?.event?.currency ||
                eventInstance?.event?.organization?.currency ||
                'EUR',
              defaultPrice: 0,
              stripeProductTaxCode: 'txcd_20030000',
              imageUrl: eventInstance?.event?.pic_logo_url,
              productType: 'eventInstance',
              productDbId: eventInstanceId
            }).catch((err) => {
              console.error('Error creating stripe product', err);
            });
            console.log('New Stripe Product: ', stripeProduct);
          }
        }

        // //! Create or update a spectator ticket product & price
        // //! #########################################################

        // loop through spectatorTicketsOffered and create a product with defualt price for each where no productId is not set yet;
        // if productId is set AND price has changed, update the price

        // Loop through spectator tickets and handle Stripe products
        for (const ticket of spectatorTicketsOffered) {
          if (!ticket.stripeProductId) {
            if (
              !eventInstance?.event?.organization?.stripeAccountId ||
              eventInstance?.event?.organization?.stripeAccountId === ''
            ) {
              console.log(
                'No stripe account connected. Cannot create spectator ticket stripe product.'
              );
              continue;
            }

            // only create stripe product for ticket if price is greater than 0
            if (ticket.price <= 0) {
              console.log(
                'Price is 0. Not creating stripe product for ticket.'
              );
              continue;
            }

            // Create new Stripe product for ticket
            console.log('Creating new stripe product for ticket:', ticket);
            const stripeProduct = await createStripeProduct({
              organizationId: values?.organizationId,
              productName: `${eventInstance?.event?.name} - Spectator Ticket - ${ticket.description} (${ticket.validDate})`,
              defaultCurrency:
                values?.currency ||
                eventInstance?.event?.currency ||
                eventInstance?.event?.organization?.currency ||
                'EUR',
              defaultPrice: calculateSpectatorTicketPriceWithFee(ticket.price),
              stripeProductTaxCode: 'txcd_20030000',
              imageUrl: eventInstance?.event?.pic_logo_url,
              productType: 'spectatorTicket',
              productDbId: eventInstanceId
            }).catch((err) => {
              console.error('Error creating stripe product for ticket', err);
            });

            if (stripeProduct) {
              // Update ticket with Stripe IDs
              ticket.stripeProductId = stripeProduct.id;
              ticket.stripePriceId = stripeProduct.default_price;
            }
          } else if (ticket.stripeProductId) {
            // Update existing product if price changed
            const existingTicket = eventInstance?.spectatorTicketsOffered?.find(
              (t) => t._id === ticket._id
            );

            if (existingTicket && existingTicket.price !== ticket.price) {
              console.log('Updating stripe price for ticket:', ticket);
              await setNewPriceForStripeProduct({
                organizationId: values?.organizationId,
                productId: ticket.stripeProductId,
                newPriceInCents: calculateSpectatorTicketPriceWithFee(
                  ticket.price
                ),
                newCurrency:
                  values?.currency ||
                  eventInstance?.currency ||
                  eventInstance?.event?.currency ||
                  eventInstance?.event?.organization?.currency ||
                  'EUR'
              })
                .then((result) => {
                  console.log(
                    'Successfully updated stripe price for ticket',
                    result
                  );
                  ticket.stripePriceId = result.default_price;
                })
                .catch((err) => {
                  console.error('Error updating stripe price for ticket', err);
                });
            }

            // Update product name/description if needed
            const existingDescription =
              eventInstance?.spectatorTicketsOffered?.find(
                (t) => t._id === ticket._id
              )?.description;

            if (existingDescription !== ticket.description) {
              await updateStripeProduct({
                organizationId: values?.organizationId,
                productId: ticket.stripeProductId,
                productName: `${eventInstance?.event?.name} - Spectator Ticket - ${ticket.description} (${ticket.validDate})`,
                description: ticket.description
              }).catch((err) => {
                console.error('Error updating stripe product for ticket', err);
              });
            }
          }
        }

        // let spectatorTicketStripeProduct;
        // // create product and price if no spectatorTicketProductId is set
        // if (
        //   values?.spectatorTicketsSold &&
        //   !eventInstance?.spectatorTicketStripeProductId
        // ) {
        //   console.log('creating new spectator ticket stripe product');
        //   spectatorTicketStripeProduct = await createStripeProduct({
        //     organizationId: values?.organizationId,
        //     productName:
        //       'Spectator Ticket: ' +
        //       eventInstance?.event?.name +
        //       ' - ' +
        //       selectData.eventInstanceTypes.find(
        //         (eit) => eit.value === values?.type
        //       ).label,

        //     defaultCurrency:
        //       values?.currency ||
        //       eventInstance?.event?.currency ||
        //       eventInstance?.event?.organization?.currency ||
        //       'EUR',
        //     defaultPrice: fm.un(values?.spectatorTicketFee) * 100 || 0,
        //     stripeProductTaxCode: 'txcd_20030000',
        //     imageUrl: eventInstance?.event?.pic_logo_url,
        //     productType: 'spectatorTicket',
        //     productDbId: eventInstanceId
        //   }).catch((err) => {
        //     console.error('Error creating stripe product', err);
        //   });
        //   console.log(
        //     'New Spectator Ticket Stripe Product: ',
        //     spectatorTicketStripeProduct
        //   );
        //   formik.setFieldValue(
        //     'spectatorTicketStripeProductId',
        //     spectatorTicketStripeProduct?.id
        //   );
        //   // set stripe price id
        //   formik.setFieldValue(
        //     'spectatorTicketStripePriceId',
        //     spectatorTicketStripeProduct?.default_price
        //   );
        // }

        // if (eventInstance?.spectatorTicketStripeProductId) {
        //   console.log(
        //     'Updating spectator ticket stripe price?',
        //     fm.un(values?.spectatorTicketFee) * 100,
        //     eventInstance?.spectatorTicketFee
        //   );
        //   if (
        //     fm.un(values?.spectatorTicketFee) * 100 !==
        //     eventInstance?.spectatorTicketFee
        //   ) {
        //     console.log('Updating stripe price for spectator ticket');
        //     await setNewPriceForStripeProduct({
        //       organizationId: values?.organizationId,
        //       productId: eventInstance?.spectatorTicketStripeProductId,

        //       newPriceInCents: fm.un(values?.spectatorTicketFee) * 100,
        //       newCurrency:
        //         values?.currency ||
        //         eventInstance?.currency ||
        //         eventInstance?.event?.currency ||
        //         eventInstance?.event?.organization?.currency ||
        //         'EUR'
        //     }).catch((err) => {
        //       console.error(
        //         'error updating stripe price for spectator ticket',
        //         err
        //       );
        //     });
        //   } else {
        //     console.log(
        //       'Spectator ticket price has not changed. Not updated in stripe.'
        //     );
        //   }
        // }

        console.log('spectatorTicketsOffered', spectatorTicketsOffered);
        console.log(
          'volunteerPositionsOffered',
          values.volunteerPositionsOffered
        );

        gqlUpdateEventInstance({
          variables: {
            eventInstanceId: values._id,
            data: {
              type: values.type,
              startDate: values.startDate,
              endDate: values.endDate,
              isVirtualEvent: values.isVirtualEvent,
              isOpenRegistration: values.isOpenRegistration,
              name: values.name,
              description: values.description,
              registrationDeadline: values.registrationDeadline,
              organizationId: values.organizationId,
              maxParticipants: values.maxParticipants,
              registrationFee: fm.un(values.registrationFee) * 100,
              location: {
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                zip: values.zip,
                country: values.country
              },
              timeZone: values.timeZone,
              stripeProductId: stripeProduct?.id,
              stripePriceId: stripeProduct?.default_price,
              stripeTaxRateId: values.stripeTaxRateId,
              customRequiredFields: customRequiredFields,
              scheduleDetails: values.scheduleDetails,
              spectatorTicketsSold: values.spectatorTicketsSold,
              spectatorTicketsLimit: values.spectatorTicketsLimit,
              // spectatorTicketFee: fm.un(values.spectatorTicketFee) * 100,

              // spectatorTicketsOffered: values.spectatorTicketsOffered,
              spectatorTicketsOffered: removeGqlGarbageFromArrays(
                spectatorTicketsOffered
              ),

              spectatorTicketSalesStartDate:
                values.spectatorTicketSalesStartDate,
              spectatorTicketSalesEndDate: values.spectatorTicketSalesEndDate,

              volunteerRegistrationEnabled: values.volunteerRegistrationEnabled,
              volunteerPositionsOffered: removeGqlGarbageFromArrays(
                values.volunteerPositionsOffered
              ),
              volunteerEnrollmentStartDate: values.volunteerEnrollmentStartDate,
              volunteerEnrollmentEndDate: values.volunteerEnrollmentEndDate,

              aiAssistantEnabled: values.aiAssistantEnabled,
              aiAssistantClientId: values.aiAssistantClientId,
              aiAssistantApiKey: values.aiAssistantApiKey,

              allowedVideoUploadPlatforms: values.allowedVideoUploadPlatforms,
              leaderboardPublishingDate: values.leaderboardPublishingDate,

              enableVideoUpload: values.enableVideoUpload
            }
          }
        })
          .then(async (data) => {
            console.log('result from update operation', data);

            enqueueSnackbar('Your changes have been saved.', {
              variant: 'success'
            });
            setSomethingHasChanged(false);
            setVolunteerPositionsHaveChanged(false);
            setSpectatorTicketsHaveChanged(false);

            // Swal.fire('Success!', 'Your profile has been updated!', 'success');
          })
          .catch((err) => {
            console.error('error', err);
            enqueueSnackbar('Your changes could not be saved.', {
              variant: 'error'
            });
          })
          .finally(() => {
            setWorkInProgress(false);
          });
      }
    }
  });

  console.log('EventInstanceDetails: Formik values:', formik.values);

  const handleDeleteEventInstanceButtonClicked = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // first, remove stripe product
        if (eventInstance?.stripeProductId) {
          console.log('Removing stripe product for eventInstance');
          await deactivateStripeProduct({
            organizationId: eventInstance?.organizationId,
            productId: eventInstance?.stripeProductId
          }).catch((err) => {
            console.error(
              'error deactivating stripe product for removed eventInstance',
              err
            );
          });
        }

        console.log('Deleting eventInstance', eventInstance._id);

        // then, delete eventInstance from database
        gqlDeleteEventInstance({
          variables: {
            eventInstanceId: eventInstance._id
          }
        })
          .then((data) => {
            console.log('result from delete operation', data);
            onEventInstanceChanged && onEventInstanceChanged();
            enqueueSnackbar('Your event instance has been deleted.', {
              variant: 'success'
            });
            // Swal.fire(
            //   'Deleted!',
            //   'Your event instance has been deleted.',
            //   'success'
            // );

            navigate('/organizer/events/' + eventInstance?.event?._id, {
              replace: true
            });
          })
          .catch((err) => {
            console.error('error', err);
          });
      }
    });
  };

  // const handleSwitchChanged = async (event) => {
  //   formik.setFieldValue(event.target.name, event.target.checked);
  // };

  const handleDatePickerChanged = async (fieldName, newValue) => {
    console.log('Setting date for: ', fieldName, newValue[0]);
    if (newValue.length > 0) {
      await formik.setFieldValue(fieldName, newValue[0], true);
    } else {
      console.error(
        'New picked date is an array with multiple date. Cannot update.'
      );
    }
  };

  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  var decimalFormatter = new Intl.NumberFormat(i18n.language, {
    // style: "currency",
    // currency: event?.currency || "EUR",
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  // console.log('Event Instance Details: Data', data);

  const calculatedSystemFee = decimalFormatter.format(
    // if no fee is asked, do not ask for a platform fee
    formik.values?.registrationFee == '0'
      ? 0
      : Number(process.env.REACT_APP_TDU_FIXED_FEE) +
          Number(
            (process.env.REACT_APP_TDU_PERCENTAGE_FEE / 100) *
              fm.un(formik.values?.registrationFee)
          )
  );

  const calculateTicketFee = (price) =>
    Number(price) === 0 ? 0 : Number(process.env.REACT_APP_TICKET_FIXED_FEE);

  const paymentsEnabled = eventInstance?.event?.organization?.stripeAccountId;

  console.log('EventInstanceDetails: paymentsEnabled', paymentsEnabled);

  const handleFileUploaded = (url, fileName) => {
    console.log('handleFileUploaded', url);
    setScheduleAttachments((attachments) => [
      ...attachments,
      { url, fileName }
    ]);
  };

  const handleDivisionsChanged = async () => {
    console.log('EventInstance Setup: handleDivisionsChanged');
    await refetch();
  };

  const handleSpectatorTicketsTableChanged = async (newValues) => {
    try {
      setSpectatorTicketsHaveChanged(true);
      setSomethingHasChanged(true);

      console.log(
        'EventInstance Setup: handleSpectatorTicketsTableChanged: spectatorTicketsOffered:',
        newValues,
        typeof newValues,
        'is array:',
        Array.isArray(newValues)
      );

      // await formik.setFieldValue('spectatorTicketsOffered', newValues, false);

      // formik
      //   .setValues({
      //     ...formik.values,
      //     spectatorTicketsOffered: newValues
      //   })

      //! formik.setFieldValue is not updating for some reason;
      //! workaround: store in state and update values from state rather ftom formik

      setSpectatorTicketsOffered(newValues);

      formik.setFieldValue('spectatorTicketsOffered', newValues, false);
      //   .then(() => {
      //     // Add validation touch
      //     console.log('spectatorTicketsOffered set to', newValues);
      //     formik.setFieldTouched('spectatorTicketsOffered', true, false);
      //     console.log(
      //       'spectatorTicketsOffered: Updated formik values:',
      //       formik.values.spectatorTicketsOffered
      //     );
      //   })
      //   .catch((error) => {
      //     console.error('Error updating spectatorTicketsOffered', error);
      //   });
      // Debug: Check if value was updated
    } catch (error) {
      console.error('Error updating spectatorTicketsOffered', error);
    }
  };

  const handleVolunteerPositionsTableChanged = async (newValues) => {
    try {
      setVolunteerPositionsHaveChanged(true);
      setSomethingHasChanged(true);

      console.log(
        'EventInstance Setup: handleVolunteerPositionsUpdated: volunteerPositions:',
        newValues,
        typeof newValues,
        'is array:',
        Array.isArray(newValues)
      );

      // await formik.setFieldValue('volunteerPositions', newValues, false);

      // formik
      //   .setValues({
      //     ...formik.values,
      //     volunteerPositions: newValues
      //   })

      //! formik.setFieldValue is not updating for some reason;
      //! workaround: store in state and update values from state rather ftom formik

      // setVolunteerPositions(newValues);

      formik
        .setFieldValue('volunteerPositionsOffered', newValues, true)
        .then(() => {
          // Add validation touch
          console.log('volunteerPositionsOffered set to', newValues);
          formik.setFieldTouched('volunteerPositionsOffered', true, false);
          console.log(
            'volunteerPositions: Updated formik values:',
            formik.values.volunteerPositionsOffered
          );
        })
        .catch((error) => {
          console.error('Error updating volunteerPositions', error);
        });
      // Debug: Check if value was updated
    } catch (error) {
      console.error('Error updating volunteerPositions', error);
    }
  };

  // listen to updates on volunteerEnrollmentStartDate and if no enddate was set, set startdate as enddate
  useEffect(() => {
    console.log(
      'UseEffect: Volunteers: volunteerEnrollmentStartDate updated:',
      formik.values.volunteerEnrollmentStartDate,
      formik.values.volunteerEnrollmentEndDate
    );

    if (
      !formik.values.volunteerEnrollmentEndDate ||
      formik.values.volunteerEnrollmentEndDate === '' ||
      formik.values.volunteerEnrollmentEndDate === null
    ) {
      formik.setFieldValue(
        'volunteerEnrollmentEndDate',
        formik.values.volunteerEnrollmentStartDate
      );
    }
  }, [formik.values.volunteerEnrollmentStartDate]);

  // listen to updates on startDate and if no endDate was set, set startDate as endDate
  useEffect(() => {
    console.log(
      'UseEffect: Event dates: startDate updated:',
      formik.values.startDate,
      formik.values.endDate
    );

    let newDate = new Date(formik.values.startDate);
    newDate.setHours(18);
    console.log('StartDate New Date: ', newDate);

    if (
      !formik.values.endDate ||
      formik.values.endDate === '' ||
      formik.values.endDate === null
    ) {
      formik.setFieldValue('endDate', newDate);
    }
  }, [formik.values.startDate]);

  const formMinWidth = '200px';
  console.log('EventInstanceDetails: formik.values', formik.values);

  console.log(
    'EventInstanceDetails: spectatorTicketsOffered',
    spectatorTicketsOffered
  );

  if (!data) return <Loader />;

  return (
    <>
      <Card id={eventInstance?._id} sx={{ overflow: 'visible' }}>
        <ArgonBox
          p={3}
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={handleExpandClick}
          style={{ cursor: 'pointer' }}>
          <ArgonTypography variant="h5" textTransform="capitalize">
            {!formik.values.name &&
              !formik.values?.type &&
              'Event Instance Details'}
            {formik.values.name && formik.values.name}
            {!formik.values.name &&
              formik.values?.type &&
              `${formik.values.name} ${
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === formik.values.type
                )?.label || ''
              }`}
          </ArgonTypography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </ArgonBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ArgonBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              {/* Basic Info Section */}
              <ArgonBox ml={3}>
                <BasicInfo formik={formik} editor1Key={editor1Key} />
              </ArgonBox>

              {/* Parameters Section */}
              <Parameters
                formik={formik}
                event={eventInstance?.event}
                paymentsEnabled={paymentsEnabled}
                fm={fm}
                formMinWidth={formMinWidth}
              />

              {/* Online Qualifier Settings Section */}
              {formik.values.isVirtualEvent && (
                <OnlineQualifierSettings
                  formik={formik}
                  eventInstance={eventInstance}
                  formWidth={formMinWidth}
                />
              )}

              {/* Location Section */}
              <Location formik={formik} formMinWidth={formMinWidth} />

              {/* Schedule Details Section */}
              <ScheduleDetails
                formik={formik}
                editor2Key={editor2Key}
                scheduleAttachments={scheduleAttachments}
                onFileUploaded={handleFileUploaded}
                eventInstance={eventInstance}
              />

              <Grid item xs={12} sm={12}>
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonButton
                    variant={formik.dirty ? 'gradient' : 'outlined'}
                    color={formik.dirty ? 'info' : 'secondary'}
                    size="small"
                    onClick={formik.submitForm}
                    loading={workInProgress}
                    disabled={!formik.dirty || workInProgress}
                    style={{ width: '200px' }}>
                    Save
                  </ArgonButton>
                  <ArgonButton
                    variant="text"
                    color="error"
                    onClick={handleDeleteEventInstanceButtonClicked}>
                    <DeleteIcon />
                    &nbsp;Remove Event Instance
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
        </Collapse>
      </Card>

      {/* ########################### DIVISIONS ########################### */}

      <ArgonBox mt={3}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setDivisionsExpanded(!divisionsExpanded)}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              Divisions
            </ArgonTypography>
            <ExpandMore
              expand={divisionsExpanded}
              onClick={() => setDivisionsExpanded(!divisionsExpanded)}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </ArgonBox>
          <Collapse in={divisionsExpanded}>
            <ArgonBox px={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <DivisionList
                    eventInstance={eventInstance}
                    event={event}
                    onDivisionsChanged={handleDivisionsChanged}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </Collapse>
        </Card>
      </ArgonBox>

      {/* ########################### Volunteer Positions ########################### */}
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox p={3}>
            <ArgonBox
              p={0}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() =>
                setVolunteerPositionsExpanded(!volunteerPositionsExpanded)
              }
              style={{ cursor: 'pointer' }}>
              <ArgonTypography variant="h5" textTransform="capitalize">
                Volunteers
              </ArgonTypography>

              <ExpandMore
                expand={volunteerPositionsExpanded}
                onClick={() => () =>
                  setVolunteerPositionsExpanded(!volunteerPositionsExpanded)}
                aria-expanded={expanded}
                aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
              {/* <ArgonButton
                variant="text"
                color="success"
                size="small"
                onClick={() => {
                  setOpenAddNewVolunteerPositionsForm(true);
                }}>
                Add new position
              </ArgonButton> */}
            </ArgonBox>

            <Collapse in={volunteerPositionsExpanded}>
              <ArgonBox px={0} pt={2} pb={1}>
                <ArgonTypography variant="regular" color="text">
                  <ArgonBox color="inherit">
                    You can have volunteers register for the specified positions
                    to support the event. Volunteers register from the event
                    page. You can see and manage the volunteers from the
                    Volunteers tab within this event instance.
                  </ArgonBox>
                </ArgonTypography>
              </ArgonBox>

              <Table>
                {/* Empty table head required to get rid of warning in dev tools */}

                <TableHead></TableHead>
                <TableBody>
                  {/* <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Spectator Tickets
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: formMinWidth }}>
                        {' '}
                      </TableCell>
                    </TableRow> */}

                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          Offer volunteer positions
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Volunteers can register for the specified positions to
                          support the event.
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>

                    <TableCell
                      align="end"
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: formMinWidth }}>
                      <Switch
                        id="volunteerRegistrationEnabled"
                        name="volunteerRegistrationEnabled"
                        onChange={(event) => {
                          console.log(
                            'volunteerRegistrationEnabled',
                            event.target.name,
                            event.target.checked
                          );
                          formik.setFieldValue(
                            'volunteerRegistrationEnabled',
                            event.target.checked
                          );
                          setVolunteerPositionsHaveChanged(true);
                        }}
                        checked={
                          Boolean(
                            formik.values?.volunteerRegistrationEnabled
                          ) || false
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* <Collapse in={formik?.values?.volunteerRegistrationEnabled}> */}
              <Table>
                {/* Empty table head required to get rid of warning in dev tools */}

                <TableHead></TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      <ArgonBox
                        lineHeight={1.4}
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        justifyContent="space-between">
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          Available volunteer positions
                        </ArgonTypography>
                        <ArgonBox mt={-1}>
                          <ArgonButton
                            variant="text"
                            color="success"
                            size="small"
                            onClick={() => {
                              setOpenAddNewVolunteerPositionsForm(true);
                            }}>
                            Add new position
                          </ArgonButton>
                        </ArgonBox>
                      </ArgonBox>
                      <VolunteerPositionsTable
                        eventInstance={eventInstance}
                        onUpdate={handleVolunteerPositionsTableChanged}
                        openAddNewVolunteerPosition={
                          openAddNewVolunteerPositionsForm
                        }
                        onAddNewVolunteerPositionFormClosed={() =>
                          setOpenAddNewVolunteerPositionsForm(false)
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* </Collapse> */}

              {/* <Collapse in={formik?.values?.volunteerRegistrationEnabled}> */}
              <Table>
                {/* Empty table head required to get rid of warning in dev tools */}

                <TableHead></TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          Enrollment start date
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Set the date when volunteers can start enrolling for
                          the positions.
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell
                      width="100%"
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: formMinWidth }}>
                      <ArgonBox width={formMinWidth}>
                        <ArgonDatePicker
                          id="volunteerEnrollmentStartDate"
                          name="volunteerEnrollmentStartDate"
                          // defaultValue={formik.values.startDate}
                          options={{
                            allowInput: true,
                            enableTime: true,
                            time_24hr: true,
                            defaultHour: 8
                          }}
                          input={{
                            // width: { formMinWidth },
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.volunteerEnrollmentStartDate &&
                              Boolean(
                                formik.errors.volunteerEnrollmentStartDate
                              ),

                            helperText:
                              formik.touched.volunteerEnrollmentStartDate &&
                              formik.errors.volunteerEnrollmentStartDate
                          }}
                          onChange={async (date) => {
                            await handleDatePickerChanged(
                              'volunteerEnrollmentStartDate',
                              date
                            );
                            setVolunteerPositionsHaveChanged(true);
                          }}
                          value={formik.values.volunteerEnrollmentStartDate}
                        />
                      </ArgonBox>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          Enrollment end date
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Set the date until which volunteers can enroll for the
                          positions.
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell
                      align="end"
                      width={formMinWidth}
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: formMinWidth }}>
                      <ArgonDatePicker
                        id="volunteerEnrollmentEndDate"
                        name="volunteerEnrollmentEndDate"
                        // defaultValue={formik.values.startDate}
                        options={{
                          allowInput: true,
                          enableTime: true,
                          time_24hr: true,
                          defaultHour: 8
                        }}
                        input={{
                          size: 'small',
                          placeholder: 'Select a date',
                          error:
                            formik.touched.volunteerEnrollmentEndDate &&
                            Boolean(formik.errors.volunteerEnrollmentEndDate),

                          helperText:
                            formik.touched.volunteerEnrollmentEndDate &&
                            formik.errors.volunteerEnrollmentEndDate
                        }}
                        onChange={async (date) => {
                          await handleDatePickerChanged(
                            'volunteerEnrollmentEndDate',
                            date
                          );
                          // console.log('StartDate onChange: ', date);
                          // // change hours of date to 22:00
                          // let newDate = new Date(date);
                          // newDate.setHours(18);
                          // console.log('StartDate New Date: ', newDate);

                          // if (
                          //   formik.values.endDate === '' ||
                          //   formik.values.endDate === null ||
                          //   formik.values.endDate === undefined
                          // ) {
                          //   console.log(
                          //     'Setting endDate to updated startDate',
                          //     newDate
                          //   );
                          //   await handleDatePickerChanged('endDate', [newDate]);
                          // }
                          setSomethingHasChanged(true);
                          setVolunteerPositionsHaveChanged(true);
                        }}
                        value={formik.values.volunteerEnrollmentEndDate}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <ArgonBox mt={2} display="flex" justifyContent="flex-start">
                <ArgonButton
                  variant={
                    !volunteerPositionsHaveChanged ? 'outlined' : 'gradient'
                  }
                  color={!volunteerPositionsHaveChanged ? 'secondary' : 'info'}
                  size="small"
                  disabled={workInProgress || !volunteerPositionsHaveChanged}
                  onClick={formik.submitForm}
                  loading={workInProgress}
                  style={{ width: '200px' }}>
                  Save
                </ArgonButton>
              </ArgonBox>
            </Collapse>
          </ArgonBox>
        </Card>
      </ArgonBox>

      {/* ########################### AI Assistant  ########################### */}
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox p={3}>
            <ArgonBox
              p={0}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setAiAssistantExpanded(!aiAssistantExpanded)}
              style={{ cursor: 'pointer' }}>
              <ArgonTypography variant="h5" textTransform="capitalize">
                AI Assistant
              </ArgonTypography>

              <ExpandMore
                expand={spectatorTicketsExpanded}
                onClick={() => setAiAssistantExpanded(!aiAssistantExpanded)}
                aria-expanded={aiAssistantExpanded}
                aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            </ArgonBox>

            <Collapse in={aiAssistantExpanded}>
              <>
                <ArgonBox px={0} pt={2} pb={1}>
                  <ArgonTypography variant="regular" color="text">
                    <ArgonBox color="inherit">
                      AI-assisted athlete support enables athlete / customer
                      inquiries to be received and processed directly from this
                      platform. The AI uses documents relating to your event
                      (e.g. movement standards, workout descriptions,
                      scorecards) to generate specific answers to the inquiries.
                      This massively reduces the time you spend prowith athlete
                      / customer support. <br />
                      <br />
                      You can access the messages inside the platform by
                      clicking on the Conversations section in the sidebar.{' '}
                      <br />
                      <br />
                      To receive inquiries inside the platform you will need to
                      forward emails to a specific STRONG ONE email address.
                    </ArgonBox>
                  </ArgonTypography>
                </ArgonBox>

                <AiAssistant
                  formik={formik}
                  formMinWidth={formMinWidth}
                  onFileUploaded={handleFileUploaded}
                  eventInstance={eventInstance}
                  onChange={() => setAiAssistantHaveChanged(true)}
                />

                <ArgonBox mt={2} display="flex" justifyContent="flex-start">
                  <ArgonButton
                    variant={aiAssistantHaveChanged ? 'gradient' : 'outlined'}
                    color={aiAssistantHaveChanged ? 'info' : 'secondary'}
                    size="small"
                    onClick={formik.submitForm}
                    disabled={!aiAssistantHaveChanged || workInProgress}
                    loading={workInProgress}
                    style={{ width: '200px' }}>
                    Save
                  </ArgonButton>
                </ArgonBox>
              </>
            </Collapse>
          </ArgonBox>
        </Card>
      </ArgonBox>

      {/* ########################### Spectator Tickets  ########################### */}
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox p={3}>
            <ArgonBox
              p={0}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() =>
                setSpectatorTicketsExpanded(!spectatorTicketsExpanded)
              }
              style={{ cursor: 'pointer' }}>
              <ArgonTypography variant="h5" textTransform="capitalize">
                Spectator Tickets
              </ArgonTypography>

              <ExpandMore
                expand={spectatorTicketsExpanded}
                onClick={() =>
                  setSpectatorTicketsExpanded(!spectatorTicketsExpanded)
                }
                aria-expanded={spectatorTicketsExpanded}
                aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
              {/* <ArgonButton
                variant="text"
                color="success"
                size="small"
                onClick={() => {
                  setOpenAddNewVolunteerPositionsForm(true);
                }}>
                Add new position
              </ArgonButton> */}
            </ArgonBox>

            <Collapse in={spectatorTicketsExpanded}>
              <>
                <ArgonBox px={0} pt={2} pb={1}>
                  <ArgonTypography variant="regular" color="text">
                    <ArgonBox color="inherit">
                      Use the tool to sell spectator tickets. Visitors can buy
                      these tickets directly from the event page.
                    </ArgonBox>
                  </ArgonTypography>
                </ArgonBox>

                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    {/* <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Spectator Tickets
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: formMinWidth }}>
                        {' '}
                      </TableCell>
                    </TableRow> */}

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Offer spectator tickets
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Specators must register for (or buy) tickets to be
                            admitted to the event.
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>

                      <TableCell
                        align="end"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: formMinWidth }}>
                        <Switch
                          id="spectatorTicketsSold"
                          name="spectatorTicketsSold"
                          onChange={(event) => {
                            console.log(
                              'spectatorTicketsSold',
                              event.target.name,
                              event.target.checked
                            );
                            formik.setFieldValue(
                              'spectatorTicketsSold',
                              event.target.checked
                            );
                          }}
                          checked={
                            Boolean(formik.values?.spectatorTicketsSold) ||
                            false
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* <Collapse in={formik?.values?.volunteerRegistrationEnabled}> */}
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox
                          lineHeight={1.4}
                          display="flex"
                          flexDirection="row"
                          gap={1}
                          justifyContent="space-between">
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Available tickets
                          </ArgonTypography>
                          <ArgonBox mt={-1}>
                            <ArgonButton
                              variant="text"
                              color="success"
                              size="small"
                              onClick={() => {
                                setOpenAddSpectatorTicketsForm(true);
                              }}>
                              Add new ticket offer
                            </ArgonButton>
                          </ArgonBox>
                        </ArgonBox>
                        <SpectatorTicketsTable
                          eventInstance={eventInstance}
                          openAddNewTicket={openAddSpectatorTicketsForm}
                          onUpdate={handleSpectatorTicketsTableChanged}
                          onAddNewTicketFormClosed={() => {
                            setOpenAddSpectatorTicketsForm(false);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* </Collapse> */}

                {/* <Collapse in={formik?.values?.volunteerRegistrationEnabled}> */}
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            System fee per ticket
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            For each ticket that is not provied for free, STRONG
                            ONE will add a system fee to the sales price. E.g. a
                            10 € ticket will sell for 11,50 €. <br />
                            You will receive 10 € from the transaction, less
                            payment provider fees, depending on payment method
                            used by customer.
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonCurrencyInput
                          id="spectatorTicketSystemFee"
                          name="spectatorTicketSystemFee"
                          size="small"
                          intlConfig={{
                            locale: i18n.language,
                            currency: event.currency || 'EUR'
                          }}
                          disabled
                          // prefix={currency || 'EUR' + ' '}
                          allowNegativeValue={false}
                          placeholder=""
                          defaultValue={0}
                          // decimalsLimit={2}
                          decimalsScale={2}
                          value={fm.from(
                            Number(process.env.REACT_APP_TICKET_FIXED_FEE)
                          )}
                          // onChange={formik.handleChange}
                          // onValueChange={(value, name) => {
                          //   console.log(value, name);
                          //   formik.setFieldValue(name, value, true);
                          //   // formik.setTouched(name, true);
                          // }}
                          error={
                            formik.touched.spectatorTicketFee &&
                            Boolean(formik.errors.spectatorTicketFee)
                          }
                          // transformRawValue={(value) => (value * 100).toString()}
                          sx={{ minWidth: formMinWidth }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Ticket sales start date
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Set the date when ticket sales start
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="spectatorTicketSalesStartDate"
                          name="spectatorTicketSalesStartDate"
                          // defaultValue={formik.values.startDate}
                          options={{
                            allowInput: true,
                            enableTime: true,
                            time_24hr: true,
                            defaultHour: 8
                          }}
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.spectatorTicketSalesStartDate &&
                              Boolean(
                                formik.errors.spectatorTicketSalesStartDate
                              ),

                            helperText:
                              formik.touched.spectatorTicketSalesStartDate &&
                              formik.errors.spectatorTicketSalesStartDate
                          }}
                          onChange={async (date) => {
                            await handleDatePickerChanged(
                              'spectatorTicketSalesStartDate',
                              date
                            );
                            setSomethingHasChanged(true);
                            setSpectatorTicketsHaveChanged(true);
                          }}
                          value={formik.values.spectatorTicketSalesStartDate}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Ticket sales end date
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Set the date when ticket sales end
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="spectatorTicketSalesEndDate"
                          name="spectatorTicketSalesEndDate"
                          // defaultValue={formik.values.startDate}
                          options={{
                            allowInput: true,
                            enableTime: true,
                            time_24hr: true,
                            defaultHour: 8
                          }}
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.spectatorTicketSalesEndDate &&
                              Boolean(
                                formik.errors.spectatorTicketSalesEndDate
                              ),

                            helperText:
                              formik.touched.spectatorTicketSalesEndDate &&
                              formik.errors.spectatorTicketSalesEndDate
                          }}
                          onChange={async (date) => {
                            await handleDatePickerChanged(
                              'spectatorTicketSalesEndDate',
                              date
                            );
                            // console.log('StartDate onChange: ', date);
                            // // change hours of date to 22:00
                            // let newDate = new Date(date);
                            // newDate.setHours(18);
                            // console.log('StartDate New Date: ', newDate);

                            // if (
                            //   formik.values.endDate === '' ||
                            //   formik.values.endDate === null ||
                            //   formik.values.endDate === undefined
                            // ) {
                            //   console.log(
                            //     'Setting endDate to updated startDate',
                            //     newDate
                            //   );
                            //   await handleDatePickerChanged('endDate', [newDate]);
                            // }
                            setSomethingHasChanged(true);
                            setSpectatorTicketsHaveChanged(true);
                          }}
                          value={formik.values.spectatorTicketSalesEndDate}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* </Collapse> */}

                {/* <Collapse in={volunteerPositionsExpanded}> */}
                {/* <ArgonBox p={0} display="flex" flexDirection="column" gap={2}>
              <VolunteerPositionsTable
                eventInstance={eventInstance}
                onUpdate={handleVolunteerPositionsTableChanged}
                openAddNewVolunteerPosition={openAddNewVolunteerPositionsForm}
                // onAddNewVolunteerPositionFormClosed={setOpenAddNewVolunteerPositionsForm(
                //   false
                // )}
              />
            </ArgonBox> */}
                {/* </Collapse> */}
                <ArgonBox mt={2} display="flex" justifyContent="flex-start">
                  <ArgonButton
                    variant={
                      spectatorTicketsHaveChanged ? 'gradient' : 'outlined'
                    }
                    color={spectatorTicketsHaveChanged ? 'info' : 'secondary'}
                    size="small"
                    onClick={formik.submitForm}
                    disabled={!spectatorTicketsHaveChanged || workInProgress}
                    loading={workInProgress}
                    style={{ width: '200px' }}>
                    Save
                  </ArgonButton>
                </ArgonBox>
              </>
            </Collapse>
          </ArgonBox>
        </Card>
      </ArgonBox>

      {/* ########################### AUTO INVITATION ########################### */}
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setAutoInvitationsExpanded(!autoInvitationsExpanded)}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              Automatic Invitations
            </ArgonTypography>

            <ExpandMore
              expand={autoInvitationsExpanded}
              onClick={() => () =>
                setAutoInvitationsExpanded(!autoInvitationsExpanded)}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </ArgonBox>

          <Collapse in={autoInvitationsExpanded}>
            <ArgonBox px={3}>
              <ArgonTypography variant="regular" color="text">
                <ArgonBox color="inherit">
                  The tool can invite participants automatically from previous
                  event instances within this event to this event instance
                  according to their ranking (e.g. from a qualifier to a
                  finale). Configure automatic instances according to their
                  ranking. Configure automatic invitations here after the
                  ranking of the previous event instance is complete.
                </ArgonBox>
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox p={3} display="flex" flexDirection="column" gap={2}>
              {/* <ArgonTypography variant="caption" color="text">
                The tool can invite participants automatically from previous
                event instances according to their ranking. Configure automatic
                invitations here after the ranking of the previous event
                instance is complete.
              </ArgonTypography> */}
              <ArgonBox>
                <ArgonButton
                  id="open_auto_invitation_wizard"
                  name="open_auto_invitation_wizard"
                  size="small"
                  variant="outlined"
                  color="dark"
                  onClick={() =>
                    setAutoInvitationWizardOpen(!autoInvitationWizardOpen)
                  }>
                  Setup Auto Invitations
                </ArgonButton>
              </ArgonBox>
              {(data.eventInstance?.autoInvitationData ||
                showInvitationProgress) && (
                <ArgonBox mt={2}>
                  <Table>
                    {/* Empty table head required to get rid of warning in dev tools */}
                    <TableHead></TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          InvitationProgress
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <ArgonBox mt={2} />

                  <AutoInvitationStatistics
                    eventInstanceId={data.eventInstance?._id}
                    invitationsPerDivision={
                      data.eventInstance?.autoInvitationData
                        ?.invitationsPerDivision
                    }
                  />
                </ArgonBox>
              )}
            </ArgonBox>
          </Collapse>
        </Card>
      </ArgonBox>
      <AutoInvitationSettingsWizard
        eventInstanceId={eventInstanceId}
        open={autoInvitationWizardOpen}
        onClose={async () => {
          setAutoInvitationWizardOpen(!autoInvitationWizardOpen);
          await refetch();
        }}
        onSaved={async () => {
          setAutoInvitationWizardOpen(!autoInvitationWizardOpen);
          setShowInvitationProgress(true);
          await refetch();
        }}
      />
    </>
  );
}

export default EventInstanceDetails;
