import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

import * as Yup from 'yup';

/**
 * Ruft eine Liste von Konversationen ab, gefiltert nach verschiedenen Kriterien
 * @param {Object} params - Die Filterparameter
 * @param {string} [params.organizationId] - ID der Organisation
 * @param {string} [params.eventId] - ID des Events
 * @param {string} [params.eventInstanceId] - ID der Event-Instanz
 * @param {boolean} [params.isRead] - Lesestatus der Konversationen
 * @param {string} [params.channel] - Kommunikationskanal (email, instagram, whatsapp, sms, other)
 * @param {string} [params.dateFrom] - Startdatum für die Filterung (ISO 8601)
 * @param {string} [params.dateTo] - Enddatum für die Filterung (ISO 8601)
 * @param {number} [params.page=1] - Seitennummer für die Paginierung
 * @param {number} [params.limit=20] - Anzahl der Konversationen pro Seite
 * @returns {Promise<Object>} - Ein Promise, das die Liste der Konversationen zurückgibt
 */

const basePath = 'conversations';

export const getConversations = ({
  organizationId,
  eventId,
  eventInstanceId,
  isRead,
  channel,
  dateFrom,
  dateTo,
  page = 1,
  limit = 20
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession();
      console.log('Fetching conversations from REST API...');

      // Erstellen der Query-Parameter
      const queryParams = new URLSearchParams();
      if (organizationId) queryParams.append('organizationId', organizationId);
      if (eventId) queryParams.append('eventId', eventId);
      if (eventInstanceId)
        queryParams.append('eventInstanceId', eventInstanceId);
      if (isRead !== undefined) queryParams.append('isRead', isRead);
      if (channel) queryParams.append('channel', channel);
      if (dateFrom) queryParams.append('dateFrom', dateFrom);
      if (dateTo) queryParams.append('dateTo', dateTo);
      if (page) queryParams.append('page', page);
      if (limit) queryParams.append('limit', limit);

      const config = {
        method: 'get',
        url: `${
          process.env.REACT_APP_BACKEND_URI
        }/${basePath}/conversations?${queryParams.toString()}`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        }
      };

      axios(config)
        .then(function (response) {
          console.log('Conversations data received', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error fetching conversations:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in getConversations:', error);
      reject(error);
    }
  });

/**
 * Ruft eine bestimmte Konversation anhand ihrer ID ab
 * @param {Object} params - Die Parameter
 * @param {string} params.conversationId - ID der Konversation
 * @returns {Promise<Object>} - Ein Promise, das die Konversation zurückgibt
 */
export const getConversationById = ({ conversationId }) =>
  new Promise(async (resolve, reject) => {
    if (!conversationId) reject(new Error('Conversation ID is required'));

    try {
      const session = await getSession();
      console.log('Fetching conversation details from REST API...');

      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/conversations/${conversationId}`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        }
      };

      axios(config)
        .then(function (response) {
          console.log('Conversation data received', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error fetching conversation:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in getConversationById:', error);
      reject(error);
    }
  });

/**
 * Aktualisiert den Lese-/Ungelesen-Status einer Konversation
 * @param {Object} params - Die Parameter
 * @param {string} params.conversationId - ID der Konversation
 * @param {boolean} params.isRead - Neuer Lesestatus der Konversation
 * @returns {Promise<Object>} - Ein Promise, das das Ergebnis der Aktualisierung zurückgibt
 */
export const updateConversationStatus = ({ conversationId, isRead }) =>
  new Promise(async (resolve, reject) => {
    if (!conversationId) reject(new Error('Conversation ID is required'));
    if (isRead === undefined) reject(new Error('isRead status is required'));

    try {
      const session = await getSession();
      console.log('Updating conversation status in REST API...');

      const config = {
        method: 'put',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/conversations/${conversationId}/status`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          isRead
        })
      };

      axios(config)
        .then(function (response) {
          console.log('Conversation status updated', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error updating conversation status:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in updateConversationStatus:', error);
      reject(error);
    }
  });

/**
 * Sendet eine Antwort auf eine bestehende Konversation
 * @param {Object} params - Die Parameter
 * @param {string} params.conversationId - ID der Konversation
 * @param {string} params.content - Inhalt der Antwort
 * @param {string} [params.format='html'] - Format des Antworttexts (text, html, markdown)
 * @param {Array} [params.attachments] - Anhänge für die Antwort
 * @returns {Promise<Object>} - Ein Promise, das das Ergebnis des Sendens zurückgibt
 */
export const sendResponse = ({
  conversationId,
  content,
  format = 'html',
  attachments = []
}) =>
  new Promise(async (resolve, reject) => {
    if (!conversationId) reject(new Error('Conversation ID is required'));
    if (!content) reject(new Error('Response content is required'));

    try {
      const session = await getSession();
      console.log('Sending response to conversation via REST API...');

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/conversations/${conversationId}/respond`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          content,
          format,
          attachments
        })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });

export const markConversationAsRead = ({ conversationId }) =>
  new Promise(async (resolve, reject) => {
    if (!conversationId) reject(new Error('Conversation ID is required'));

    try {
      const session = await getSession();
      console.log('Sending response to conversation via REST API...');

      const config = {
        method: 'put',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/conversations/${conversationId}/status`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          isRead: true
        })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });
