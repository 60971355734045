import React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableCell from 'components/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonInput from 'components/ArgonInput';
import ArgonSelect from 'components/ArgonSelect';
import selectData from 'options/selectData';
import { timeZones } from 'options/timeZones';
import FormikCountrySelector from 'components/FormikCountrySelector';

function Location({ formik, formMinWidth }) {
  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  return (
    <Grid item xs={12} sm={12}>
      <Collapse in={!formik?.values?.isVirtualEvent} timeout="auto">
        <Table>
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                Event Location
              </TableCell>
              <TableCell
                width="100%"
                padding={[1, 1, 1, 0.5]}
                sx={{ minWidth: formMinWidth }}>
                {' '}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Address line 1
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    E.g. the event location name
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                <ArgonInput
                  id="address1"
                  name="address1"
                  placeholder="Address line 1"
                  size="small"
                  type="text"
                  value={formik.values.address1}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address1 && Boolean(formik.errors.address1)
                  }
                  helperText={formik.touched.address1 && formik.errors.address1}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Address line 2
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    E.g. the street address
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                <ArgonInput
                  id="address2"
                  name="address2"
                  placeholder="Address line 2"
                  sx={{ minWidth: '250px' }}
                  size="small"
                  type="text"
                  value={formik.values.address2}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address2 && Boolean(formik.errors.address2)
                  }
                  helperText={formik.touched.address2 && formik.errors.address2}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Postal code
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    Should be self explanatory
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                <ArgonInput
                  id="zip"
                  name="zip"
                  placeholder="Postal Code"
                  sx={{ minWidth: '250px' }}
                  size="small"
                  type="text"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    City
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    Let your participants know where they need to go
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                <ArgonInput
                  id="city"
                  name="city"
                  placeholder="City"
                  sx={{ minWidth: '250px' }}
                  size="small"
                  type="text"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Country
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    Let your participants know where they need to go
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                {/* <ArgonSelect
                  id="country"
                  name="country"
                  size="small"
                  placeholder="Please choose"
                  options={selectData.countries.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  value={selectData.countries.filter(
                    (option) => option.value === formik.values?.country
                  )}
                  onChange={(option) =>
                    handleSelectMenuChanged('country', option.value)
                  }
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                /> */}

                <FormikCountrySelector
                  id="country"
                  name="country"
                  size="small"
                  placeholder="Please choose"
                  formik={formik}
                  // onChange={(option) =>
                  //   handleSelectMenuChanged('country', option.value)
                  // }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                <ArgonBox lineHeight={1.4}>
                  <ArgonTypography
                    display="block"
                    variant="caption"
                    fontWeight="bold">
                    Time zone
                  </ArgonTypography>
                  <ArgonTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular">
                    Important to set dates right
                  </ArgonTypography>
                </ArgonBox>
              </TableCell>
              <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                <ArgonSelect
                  id="timeZone"
                  name="timeZone"
                  size="small"
                  placeholder="Please choose"
                  options={timeZones}
                  value={timeZones.filter(
                    (option) => option.value === formik.values?.timeZone
                  )}
                  onChange={(option) =>
                    handleSelectMenuChanged('timeZone', option.value)
                  }
                  error={
                    formik.touched.timeZone && Boolean(formik.errors.timeZone)
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </Grid>
  );
}

export default Location;
