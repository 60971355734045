/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Dropzone components
import Dropzone from 'dropzone';

// Dropzone styles
import 'assets/css/dropzone_button_only.css';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Custom styles for the ArgonDropzone
import ArgonDropzoneRoot from './ArgonDropzoneRoot';
import axios from 'axios';
import { getSession } from 'services/cognito/cognitoAccount';
import ArgonTypography from 'components/ArgonTypography';
import { Container } from '@mui/system';
import { Image } from '@mui/icons-material';
import { Icon } from '@mui/material';
import ArgonButton from 'components/ArgonButton';

// ! prop {metaData} is stored in S3 with the file and required for the post-upload lambda function to match the file to the correct use / databse entity
// ! prop payloadPurpose tells the post-upload lambda function what to do with the file
// e.g.: metadata: { "eventId": "1234" } payloadPurpose: "event-image"
// metaData: { "eventId": "1234", workout: "5678" } payloadPurpose: "event-image"
const ArgonDropzoneS3ButtonOnly = ({
  onFileAdded,
  image,
  payloadPurpose,
  metaData,
  identifier,
  options,
  pathToPresignedUrl,
  label = 'Upload',
  variant = 'outlined',
  color = 'secondary',
  size = 'small'
}) => {
  const dropzoneRef = useRef();
  const [currentImage, setCurrentImage] = useState(image);
  const [uploading, setUploading] = useState(false);

  const [dz, setDz] = useState({});
  console.log('Payload Type: ', payloadPurpose);

  async function getPresignedS3Link(file, identifier, payloadPurpose, idToken) {
    let url = pathToPresignedUrl;

    // use axios to get presigned link from api
    return await axios.post(
      url,
      {
        filename: file.name,
        filetype: file.type,
        identifier: identifier,
        contentType: file.type,
        payloadPurpose: payloadPurpose || 'unknown',
        // metaData: JSON.stringify(metaData)
        metaData: metaData
      },
      {
        headers: {
          Authorization: 'Bearer ' + idToken
        }
      }
    );
  }

  useEffect(() => {
    console.log('getPresignedS3Link - identifier: ', identifier);

    // get id token from session
    console.log('ArgonDropzoneS3 - useEffect');

    // const session = await getSession();

    // setIdToken(session.idToken.jwtToken);

    // if not logged in, redirect to login

    getSession().then((session) => {
      Dropzone.autoDiscover = false;

      function createDropzone() {
        return new Dropzone(dropzoneRef.current, { ...options });
      }

      function removeDropzone() {
        if (Dropzone.instances.length > 0)
          Dropzone.instances.forEach((dz) => dz.destroy());
      }

      const dz = createDropzone();
      setDz(dz);

      dz.accept = function (file, done) {
        setUploading(true);

        // get presigned link from api
        // console.log("accepted file", file);
        setCurrentImage(undefined);
        getPresignedS3Link(
          file,
          identifier,
          payloadPurpose,
          session.idToken.jwtToken
        )
          .then((result) => {
            console.log('Presigned URL', result.data.url);
            file.uploadURL = result.data.url;
            done();
            // And process each file immediately
            setTimeout(() => dz.processFile(file));
          })
          .catch((err) => {
            done('Failed to get an S3 signed upload URL', err);
          });
      };

      // if (disabled) dz.disable();

      dz.on('sending', (file, xhr, formData) => {
        console.log('sending', file);

        let _send = xhr.send;
        xhr.send = () => _send.call(xhr, file);
      });

      dz.on('processing', (file) => {
        // dz.options.url = file.uploadURL;

        // console.log("processing", file);
        dz.options.url = file.uploadURL;
        dz.options.headers = {
          ...dz.options.headers,
          'Content-Type': file.type,
          'Content-Encoding': 'base64'
          // "X-Amz-Acl": "public-read",
        };
      });

      dz.on('success', (file) => {
        setUploading(false);
        console.log('success', file);
        console.log('New url', file.uploadURL.split('?')[0]);
        onFileAdded && onFileAdded(file.uploadURL.split('?')[0], file.name);
      });
      dz.on('successMultiple', (file) => {
        setUploading(false);
        onFileAdded && onFileAdded(file.uploadURL.split('?')[0], file.name);
        console.log('success multiple', file);
      });

      return () => removeDropzone();
    });
  }, []);

  return (
    <>
      <ArgonDropzoneRoot
        component="form"
        action="/file-upload"
        ref={dropzoneRef}
        className="form-control dropzone"
        style={{ height: '100%', display: 'none' }}>
        {/* <ArgonBox className="fallback">
        <ArgonBox component="input" name="file" type="file" multiple></ArgonBox>
      </ArgonBox> */}
      </ArgonDropzoneRoot>
      <ArgonButton
        variant={variant}
        color={color}
        size={size}
        onClick={() => {
          dropzoneRef.current.click();
        }}
        loading={uploading}
        loadingColor="secondary">
        {label}
      </ArgonButton>
    </>
  );
};

// Typechecking props for the ArgonDropzone
ArgonDropzoneS3ButtonOnly.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  imagePurpose: PropTypes.oneOf([
    'event-logo',
    'event-image',
    'event-banner',

    'user-avatar',
    'event-instance-attachment',
    'event-attachment',
    'workout-attachment',
    'score-video',

    'product-media'
  ]),
  pathToPresignedUrl: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired
};

export default ArgonDropzoneS3ButtonOnly;
