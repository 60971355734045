// services/auth/TokenService.js
// Zentrale Token-Verwaltung mit Caching
import { logger } from './Logger';

export class TokenService {
  constructor(options = {}) {
    // Token-Caching-Optionen
    this.tokenCache = {};
    this.ttl = options.ttl || {
      idToken: 15 * 60 * 1000, // 15 Minuten
      accessToken: 5 * 60 * 1000, // 5 Minuten
      refreshToken: 14 * 24 * 60 * 60 * 1000 // 14 Tage
    };

    // Token-Erneuerung
    this.refreshThreshold = options.refreshThreshold || 5 * 60 * 1000; // 5 Minuten vor Ablauf
    this.refreshCallbacks = [];

    // Backoff-Optionen für Wiederholungsversuche
    this.backoffOptions = {
      initialBackoff: options.initialBackoff || 1000,
      maxBackoff: options.maxBackoff || 30000,
      maxRetries: options.maxRetries || 3
    };

    // Status
    this.isRefreshing = false;
    this.pendingRequests = [];

    logger.debug('TokenService initialized');
  }

  // Token in Cache setzen mit Ablaufzeit
  setToken(type, token, payload = null) {
    if (!token) return;

    try {
      // Token-Payload extrahieren und Ablaufzeit berechnen
      const tokenPayload = payload || this._parseJwt(token);
      const expiryTime = tokenPayload.exp
        ? tokenPayload.exp * 1000
        : Date.now() + this.ttl[type];

      this.tokenCache[type] = {
        token,
        payload: tokenPayload,
        expiryTime
      };

      logger.debug(
        `${type} token set in cache, expires in ${Math.round(
          (expiryTime - Date.now()) / 1000
        )}s`
      );
      this._dispatchTokenEvent(`${type}Updated`);

      return tokenPayload;
    } catch (error) {
      logger.error(`Error setting ${type} token in cache`, error);
      return null;
    }
  }

  // Token aus Cache holen
  getToken(type) {
    const cachedToken = this.tokenCache[type];
    if (!cachedToken) return null;

    // Prüfen, ob Token abgelaufen ist
    if (Date.now() >= cachedToken.expiryTime) {
      logger.debug(`${type} token expired, removing from cache`);
      delete this.tokenCache[type];
      return null;
    }

    return cachedToken.token;
  }

  // Token-Payload aus Cache holen
  getTokenPayload(type) {
    const cachedToken = this.tokenCache[type];
    if (!cachedToken) return null;

    // Prüfen, ob Token abgelaufen ist
    if (Date.now() >= cachedToken.expiryTime) {
      logger.debug(`${type} token expired, removing from cache`);
      delete this.tokenCache[type];
      return null;
    }

    return cachedToken.payload;
  }

  // Prüft, ob mindestens ein Token vorhanden ist
  hasValidTokens() {
    return (
      this.getToken('idToken') !== null || this.getToken('accessToken') !== null
    );
  }

  // Prüft, ob ein bestimmter Token erneuert werden sollte
  shouldRefreshToken(type) {
    const cachedToken = this.tokenCache[type];
    if (!cachedToken) return true;

    const timeUntilExpiry = cachedToken.expiryTime - Date.now();
    return timeUntilExpiry < this.refreshThreshold;
  }

  // Alle Tokens aus Cache löschen
  clearAllTokens() {
    this.tokenCache = {};
    this._dispatchTokenEvent('tokensCleared');
    logger.debug('All tokens cleared from cache');
  }

  // Callback für Token-Erneuerung registrieren
  onTokenRefresh(callback) {
    this.refreshCallbacks.push(callback);
    return () => {
      this.refreshCallbacks = this.refreshCallbacks.filter(
        (cb) => cb !== callback
      );
    };
  }

  // Token-Erneuerung starten
  async refreshTokens(forceRefresh = false) {
    if (this.isRefreshing) {
      logger.debug('Token refresh already in progress, waiting for completion');
      return new Promise((resolve) => {
        this.pendingRequests.push(resolve);
      });
    }

    this.isRefreshing = true;
    logger.debug('Starting token refresh process');

    try {
      // Alle Callbacks für Token-Erneuerung ausführen
      const results = await Promise.allSettled(
        this.refreshCallbacks.map((callback) => callback(forceRefresh))
      );

      // Prüfen, ob mindestens ein Callback erfolgreich war
      const success = results.some(
        (result) => result.status === 'fulfilled' && result.value
      );

      // Alle ausstehenden Anfragen mit dem Ergebnis fortsetzen
      this.pendingRequests.forEach((resolve) => resolve(success));
      this.pendingRequests = [];

      logger.debug(`Token refresh ${success ? 'successful' : 'failed'}`);
      return success;
    } catch (error) {
      logger.error('Error during token refresh', error);

      // Alle ausstehenden Anfragen mit Fehlschlag fortsetzen
      this.pendingRequests.forEach((resolve) => resolve(false));
      this.pendingRequests = [];

      return false;
    } finally {
      this.isRefreshing = false;
    }
  }

  // JWT dekodieren
  _parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      logger.error('Error parsing JWT token', error);
      return {};
    }
  }

  // Token-Event auslösen
  _dispatchTokenEvent(eventType) {
    window.dispatchEvent(
      new CustomEvent(`auth:${eventType}`, {
        detail: {
          timestamp: Date.now()
        }
      })
    );
  }

  // Hilfsfunktion für Retry-Logik mit exponentiellem Backoff
  async retryWithBackoff(operation, errorCheck = () => true) {
    let backoff = this.backoffOptions.initialBackoff;
    let retries = 0;

    while (retries < this.backoffOptions.maxRetries) {
      try {
        return await operation();
      } catch (error) {
        if (!errorCheck(error)) throw error;

        logger.warn(
          `Operation failed (attempt ${retries + 1}/${
            this.backoffOptions.maxRetries
          }), retrying in ${backoff}ms`,
          error
        );

        await new Promise((resolve) => setTimeout(resolve, backoff));
        backoff = Math.min(backoff * 2, this.backoffOptions.maxBackoff);
        retries++;
      }
    }

    // Letzter Versuch
    return await operation();
  }
}
