import { gql } from '@apollo/client';
import { WORKOUT_FRAGMENT } from '../Workouts/fragments';

export const SCORES_GET = gql`
  ${WORKOUT_FRAGMENT}
  query getScores($query: ScoreQueryInput) {
    scores(query: $query) {
      _id
      division
      postedBy {
        _id
        address1
        address2
        city
        cognitoId
        country
        dateOfBirth
        email
        firstName
        gender
        lastName
        pic_url
        zip
      }
      registration {
        _id
        agreeOrganizerTerms
        agreeToS
        amountDue
        divisionId
        paymentReference
        paymentStatus
        status
        registrationDate
        requiredTeamSize
        systemFee
        teamName
        createdByName
        createdByEmail
        affiliate
      }
      workout {
        ...WorkoutFragment
      }
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        # organization
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
      }
      videoUrl
      videoKey
      videoSize
      videoContentType
      postedScore
      postedTimeCapReached

      judgedScore
      judgedTimeCapReached
      judgedBy
      judgedByName
      judgingDate
      judgingNotes
      judgingPenalties

      status
      assignedJudge
      assignedJudgeName

      selectedForJudgement

      reuploadStatus
      reuploadDeadline
    }
  }
`;

export const SCORE_GET = gql`
  ${WORKOUT_FRAGMENT}
  query getScore($data: ScoreQueryInput) {
    score(query: $data) {
      _id
      division
      postedBy {
        _id
        address1
        address2
        city
        cognitoId
        country
        dateOfBirth
        email
        firstName
        gender
        lastName
        pic_url
        zip
      }
      registration {
        _id
        agreeOrganizerTerms
        agreeToS
        amountDue
        divisionId
        paymentReference
        paymentStatus
        status
        registrationDate
        requiredTeamSize
        systemFee
        teamName
        createdByName
        createdByEmail
        affiliate
      }
      workout {
        ...WorkoutFragment
      }
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        # organization
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
      }
      videoUrl
      videoKey
      videoSize
      videoContentType
      postedScore
      postedTimeCapReached
      judgedScore
      judgedTimeCapReached
      judgedBy
      judgedByName
      judgingDate
      judgingNotes
      judgingPenalties
      status
      assignedJudge
      assignedJudgeName

      selectedForJudgement

      reuploadStatus
      reuploadDeadline
    }
  }
`;

// paginate scores without using the custom function implemented in REALM, instead use original graphql query
export const SCORES_GET_NEXT_SCOREID_FOR_JUDGE = gql`
  query getScoreIdForJudge(
    $judgeId: ObjectId
    $lastItemId: ObjectId
    $limit: Int
  ) {
    scores(
      query: { _id_gt: $lastItemId, assignedJudge: $judgeId }
      limit: $limit
    ) {
      _id
    }
  }
`;

// paginate scores by using the custom paginate function in the backend
export const SCORES_GET_PAGINATED = gql`
  query getScoresPaginated($data: GetScoresPaginatedInput) {
    GetScoresPaginated(input: $data) {
      searchResults {
        _id
        event
        eventInstance
        registration
        workout
        workoutName
        workoutResultType
        division
        divisionName
        postedBy
        postedByName
        postedScore
        judgedScore
        judgedBy
        judgedByName
        judgingDate
        # judgingNotes
        # judgingPenalties
        videoUrl
        videoKey
        videoSize
        status
        assignedJudge
        assignedJudgeName
        timeCapReached
        # selectedForJudgement
      }
      totalCount {
        count
      }
    }
  }
`;
