import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

const basePath = 'aikb';

export const createNewClient = ({
  name,
  eventId,
  eventInstanceId,
  organizationId
}) =>
  new Promise(async (resolve, reject) => {
    if (!name) reject(new Error('Name is required'));
    //   if (!eventId) reject(new Error('Event ID is required'));
    //   if (!eventInstanceId) reject(new Error('Event Instance ID is required'));
    if (!organizationId) reject(new Error('Organization ID is required'));

    try {
      const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/clients`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          name,
          eventId,
          eventInstanceId,
          organizationId
        })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });

export const createNewApiKeyForClient = ({ clientId }) =>
  new Promise(async (resolve, reject) => {
    if (!clientId) reject(new Error('Client ID is required'));

    try {
      const session = await getSession();
      console.log('Sending request to aikb via REST API...');

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URI}/${basePath}/api/clients/${clientId}/keys`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        }
        //   data: JSON.stringify({
        //   clientId
        // })
      };

      axios(config)
        .then(function (response) {
          console.log('Response sent successfully', response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error('Error sending response:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error in sendResponse:', error);
      reject(error);
    }
  });
