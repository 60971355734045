import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Korrekter Import der ReportsDoughnutChart-Komponente
import ReportsDoughnutChart from '../ReportsDoughnutChart';
// import ReportsDoughnutChart from 'examples/Charts/DoughnutCharts/ReportsDoughnutChart';

import { useAuth } from 'context/AuthContext';
import { useLazyQuery } from '@apollo/client';
import { SCORES_GET } from 'GraphQL/Scores/queries';
import { TASKS_GET } from 'GraphQL/Tasks/queries';

// Korrekte Props-Deklaration als Objekt
function TasksByStatusDoughnut({
  height = '12.125rem',
  statusFilter,
  width = '100%',
  chartWidth = '100%',
  chartHeight = '100%',
  hasCard = true
}) {
  const { userId } = useAuth();

  const [preparedData, setPreparedData] = useState({
    labels: [],
    datasets: []
  });
  const [totalTasks, setTotalTasks] = useState(0);

  const aggregateTasksByStatus = (scores) => {
    const statusCounts = {};

    scores?.forEach((task) => {
      const status = task.status;
      if (!statusCounts[status]) {
        statusCounts[status] = 0;
      }
      statusCounts[status]++;
    });

    return Object.entries(statusCounts).map(([status, count]) => ({
      status,
      count
    }));
  };

  const prepareChartData = (data) => {
    console.log('TasksByStatus: prepareChartData', data);

    // const labels = data.map((item) => item.status);
    // const counts = data.map((item) => item.count);

    // // Standardfarben für das Doughnut-Chart
    // const backgroundColors = [
    //   'info', // Blau
    //   'secondary', // Grau
    //   'success', // Grün
    //   'warning', // Gelb
    //   'error', // Rot
    //   'primary', // Lila
    //   'dark' // Schwarz
    // ];

    // return {
    //   labels,
    //   datasets: {
    //     label: 'Status',
    //     backgroundColors: backgroundColors.slice(0, counts.length),
    //     data: counts
    //   }
    // };

    const labels = [];
    const counts = [];

    const backgroundColors = [];

    console.log('Data', data);

    // status === toBeJudged
    const open = data?.find((item) => item.status === 'open');
    labels.push('open');
    counts.push(open?.count || 0);
    backgroundColors.push('info');
    // status === inProgress
    const inProgress = data?.find((item) => item.status === 'inProgress');
    labels.push('in progress');
    counts.push(inProgress?.count || 0);
    backgroundColors.push('secondary');
    // status === completed / wasJudged
    const done = data?.find((item) => item.status === 'done');
    labels.push('done');
    counts.push(done?.count || 0);
    backgroundColors.push('success');

    console.log(
      'TasksByStatus: prepareChartData',
      labels,
      counts,
      backgroundColors
    );

    return {
      labels,
      datasets: {
        label: 'Status',
        data: counts,
        backgroundColors
      }
    };
  };

  const [
    fetchTasks,
    { loading: tasksLoading, data: tasksData, error: tasksError }
  ] = useLazyQuery(TASKS_GET, {
    onCompleted: (data) => {
      console.log('TasksByStatus: tasks fetched from database:', data);
      const aggregatedData = aggregateTasksByStatus(data?.tasks);
      setPreparedData(prepareChartData(aggregatedData));
      setTotalTasks(data?.tasks?.length || 0);
    },
    onError: (error) => {
      console.log('TasksByStatus: error fetching scores from database:', error);
    },
    fetchPolicy: 'cache-and-network'
  });

  // fetchScores aus der Abhängigkeitsliste entfernt, um Endlosschleifen zu vermeiden
  useEffect(() => {
    fetchTasks({
      assignedToUser: userId
    });
  }, [userId, statusFilter]);

  console.log('TasksByStatus: preparedData', preparedData);

  return (
    <div style={{ width: width, height: height }}>
      {preparedData?.datasets?.data?.length > 0 && (
        <ReportsDoughnutChart
          title="Tasks assigned to me"
          count={{ number: totalTasks, text: 'Tasks' }}
          chart={preparedData}
          width={width}
          height={height}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          hasCard={hasCard}
          // tooltip="Übersicht meiner Bewertungsaufgaben nach Status"
        />
      )}
    </div>
  );
}

TasksByStatusDoughnut.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusFilter: PropTypes.string
};

export default TasksByStatusDoughnut;
