import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import Pool from './userPool';

import { groupsWithOrganizerAccess } from './groupsWithOrganizerAccess';

// Cache für Benutzerattribute
let attributesCache = null;
let attributesCacheExpiry = 0;
const ATTRIBUTES_CACHE_TTL = 5 * 60 * 1000; // 5 Minuten

// Backoff-Mechanismus für Wiederholungsversuche
const INITIAL_BACKOFF = 1000; // 1 Sekunde
const MAX_BACKOFF = 30000; // 30 Sekunden
const MAX_RETRIES = 3;

// Hilfsfunktion zum Warten
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Hilfsfunktion zum Abrufen von Benutzerattributen mit Caching
const getUserAttributesWithCache = async (user) => {
  const now = Date.now();

  // Wenn der Cache gültig ist, verwenden wir ihn
  if (attributesCache && attributesCacheExpiry > now) {
    console.log('Using cached user attributes');
    return attributesCache;
  }

  console.log('Fetching fresh user attributes');

  // Implementierung des exponentiellen Backoff
  let backoff = INITIAL_BACKOFF;
  let retries = 0;

  while (retries < MAX_RETRIES) {
    try {
      const attributes = await new Promise((resolve, reject) => {
        user.getUserAttributes((err, attributes) => {
          if (err) {
            console.error(
              `Error getting user attributes (attempt ${
                retries + 1
              }/${MAX_RETRIES}): `,
              err
            );
            reject(err);
          } else {
            const results = {};
            for (let attribute of attributes) {
              const { Name, Value } = attribute;
              results[Name] = Value;
            }
            resolve(results);
          }
        });
      });

      // Aktualisiere den Cache
      attributesCache = attributes;
      attributesCacheExpiry = now + ATTRIBUTES_CACHE_TTL;

      return attributes;
    } catch (error) {
      // Wenn wir ein Rate-Limit-Fehler erhalten, warten wir und versuchen es erneut
      if (error.name === 'TooManyRequestsException') {
        console.log(
          `Rate limit exceeded, retrying in ${backoff}ms (attempt ${
            retries + 1
          }/${MAX_RETRIES})`
        );
        await wait(backoff);
        backoff = Math.min(backoff * 2, MAX_BACKOFF); // Exponentieller Backoff
        retries++;
      } else {
        // Bei anderen Fehlern geben wir den Fehler weiter
        throw error;
      }
    }
  }

  // Wenn wir hier ankommen, haben wir die maximale Anzahl von Versuchen erreicht
  throw new Error(
    `Failed to get user attributes after ${MAX_RETRIES} attempts`
  );
};

const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      console.log('Current user found in local storage');
      user.getSession(async (err, session) => {
        if (err) {
          console.error('Error getting user session: ', err);
          // Prüfe, ob das Token abgelaufen ist
          if (
            err.toString().includes('Token expired') ||
            err.toString().includes('ID Token has expired')
          ) {
            reject(new Error('ID Token has expired'));
          } else {
            reject(err);
          }
        } else {
          try {
            // Benutzerattribute abrufen mit Caching
            const attributes = await getUserAttributesWithCache(user).catch(
              (attributeError) => {
                console.error(
                  'Error getting cached user attributes:',
                  attributeError
                );
                // Bei Fehlern geben wir ein leeres Objekt zurück
                return {};
              }
            );

            // Vollständige Sitzungsdaten zurückgeben
            resolve({
              user,
              ...session,
              ...attributes
            });
          } catch (attributeError) {
            console.error('Error processing user attributes:', attributeError);
            // Auch wenn Attribute nicht abgerufen werden können, geben wir die Sitzung zurück
            resolve({
              user,
              ...session
            });
          }
        }
      });
    } else {
      console.log('User is not logged in.');
      reject(new Error('User is not logged in'));
    }
  });

// const forwardToLogin = () => {
//   window.sessionStorage.removeItem('hash');
//   window.location.href = '/login';
// };

const authenticate = async (Username, Password) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        data.authChallenge = 'none';
        console.log('Successfully authenticated in cognito.', data);
        resolve(data);
      },

      onFailure: (err) => {
        console.error('onFailure:', err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        //console.log('newPasswordRequired:', data);
        data.authChallenge = 'newPasswordRequired';
        resolve(data);
      }
    });
  });

const verifyEmail = async (Username, Code) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });

    user.confirmRegistration(Code, true, function (err, result) {
      if (err) {
        reject(err);
      }
      console.log('verification result: ' + result);
      resolve(result);
    });
  });

const resendVerificationCode = async (Username) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });

    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.error('resendConfirmationCode error:', err);
        reject(err);
      } else {
        console.log('resendConfirmationCode success:', result);
        resolve(result);
      }
    });
  });

const completeNewPasswordChallenge = (Username, Password, newPassword) =>
  new Promise((resolve, reject) => {
    console.log('Username', Username);
    console.log('Old Password', Password);
    console.log('New Password', newPassword);
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        data.authChallenge = 'none';
        resolve(data);
      },

      onFailure: (err) => {
        console.error('onFailure:', err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        console.log('newPasswordRequired:', data);

        user.completeNewPasswordChallenge(newPassword, [], {
          onSuccess: (session) => {
            // login
            console.log('Password changed succesfully.', session);
            resolve(session);
          },
          onFailure: (err) => {
            console.error('Failed changing password:', err);
            reject(err);
          }
        });

        // resolve(data);
      }
    });
  });

const logout = () => {
  const user = Pool.getCurrentUser();

  if (user) {
    user.signOut();
  }
};

const getUserId = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          resolve(session.idToken.payload['custom:userId']);
        }
      });
    } else {
      reject('User is not logged in.');
    }
  });

const getIdToken = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          resolve(session.idToken.jwtToken);
        }
      });
    } else {
      reject('User is not logged in.');
    }
  });

const getUserGroups = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          resolve(session.idToken.payload['cognito:groups']);
        }
      });
    } else {
      resolve([]);
    }
  });

const checkIfUserHasOrganizerAccess = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          // reject(err);
          console.log('Checking for organizer access: user is not logged in.');
          resolve(false);
        } else {
          const userGroups = session.idToken.payload['cognito:groups'];

          // check if userGroups contains any of the group prefixes with organizer access
          const hasOrganizerAccess = Boolean(
            userGroups?.some((userGroup) =>
              groupsWithOrganizerAccess?.some((prefix) =>
                userGroup?.startsWith(prefix)
              )
            )
          );
          console.log('User has organizer access:', hasOrganizerAccess);
          resolve(hasOrganizerAccess);
        }
      });
    } else {
      resolve(false);
    }
  });

const checkIfUserIsLoggedIn = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

const getEventIdsWithAdminAccess = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          // reject(err);
          console.log('Checking for organizer access: user is not logged in.');
          resolve(false);
        } else {
          const userGroups = session.idToken.payload['cognito:groups'];

          // // check if userGroups contains any of the group prefixes with organizer access
          // const hasOrganizerAccess = Boolean(
          //   userGroups?.some((userGroup) =>
          //     groupsWithOrganizerAccess?.some((prefix) =>
          //       userGroup?.startsWith(prefix)
          //     )
          //   )
          // );
          // console.log('User has organizer access:', hasOrganizerAccess);

          const eventIds = userGroups
            ?.filter((userGroup) => userGroup?.startsWith('event-admin-'))
            .map((eventGroup) => eventGroup.split('-').pop());

          console.log('EventIds with admin access:', eventIds);
          resolve(eventIds);
        }
      });
    } else {
      resolve(false);
    }
  });

const getEventIdsWithScoringAccess = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          // reject(err);
          console.log('Checking for organizer access: user is not logged in.');
          resolve(false);
        } else {
          const userGroups = session.idToken.payload['cognito:groups'];

          // // check if userGroups contains any of the group prefixes with organizer access
          // const hasOrganizerAccess = Boolean(
          //   userGroups?.some((userGroup) =>
          //     groupsWithOrganizerAccess?.some((prefix) =>
          //       userGroup?.startsWith(prefix)
          //     )
          //   )
          // );
          // console.log('User has organizer access:', hasOrganizerAccess);

          const eventIds = userGroups
            ?.filter(
              (userGroup) =>
                userGroup?.startsWith('event-admin-') ||
                userGroup?.startsWith('event-scoring-')
            )
            .map((eventGroup) => eventGroup.split('-').pop());

          console.log('EventIds with scoring access to user:', eventIds);
          resolve(eventIds);
        }
      });
    } else {
      resolve(false);
    }
  });

// Token-Erneuerung mit exponentiellen Backoff und Fehlerbehandlung
const refreshIdToken = async () => {
  let backoff = INITIAL_BACKOFF;
  let retries = 0;

  while (retries < MAX_RETRIES) {
    try {
      return await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        console.log(
          `refreshIdToken: Attempting to refresh token (attempt ${
            retries + 1
          }/${MAX_RETRIES}), user exists:`,
          !!user
        );

        if (user) {
          // 1) get current session => refreshToken
          user.getSession(async (err, session) => {
            if (err) {
              console.error('Error getting session for refresh:', err);
              console.log('refreshIdToken: Session error details:', {
                errorMessage: err.message || 'No message',
                errorName: err.name || 'No name',
                errorCode: err.code || 'No code'
              });
              reject(err);
            } else {
              console.log(
                'refreshIdToken: Got session, refresh token exists:',
                !!session.refreshToken
              );

              // 2) use refreshtoken to refresh the session
              user.refreshSession(
                session.refreshToken,
                async (err, refreshedSession) => {
                  if (err) {
                    console.error('Error refreshing session:', err);
                    console.log('refreshIdToken: Refresh error details:', {
                      errorMessage: err.message || 'No message',
                      errorName: err.name || 'No name',
                      errorCode: err.code || 'No code'
                    });
                    reject(err);
                  } else {
                    console.log('Successfully refreshed session');
                    console.log(
                      'refreshIdToken: New token expiration:',
                      refreshedSession.idToken.payload.exp
                        ? new Date(
                            refreshedSession.idToken.payload.exp * 1000
                          ).toISOString()
                        : 'Unknown'
                    );
                    resolve(refreshedSession);
                  }
                }
              );
            }
          });
        } else {
          console.error('No current user found for token refresh');
          reject(new Error('User is not logged in'));
        }
      });
    } catch (error) {
      // Wenn wir ein Rate-Limit-Fehler erhalten oder ein Token-Fehler, warten wir und versuchen es erneut
      if (
        error.name === 'TooManyRequestsException' ||
        (error.message && error.message.includes('token'))
      ) {
        console.log(
          `Token refresh failed, retrying in ${backoff}ms (attempt ${
            retries + 1
          }/${MAX_RETRIES})`
        );
        await wait(backoff);
        backoff = Math.min(backoff * 2, MAX_BACKOFF); // Exponentieller Backoff
        retries++;
      } else {
        // Bei anderen Fehlern geben wir den Fehler weiter
        throw error;
      }
    }
  }

  // Wenn wir hier ankommen, haben wir die maximale Anzahl von Versuchen erreicht
  throw new Error(`Failed to refresh token after ${MAX_RETRIES} attempts`);
};

export {
  getSession,
  authenticate,
  verifyEmail,
  resendVerificationCode,
  logout,
  getIdToken,
  getUserId,
  getUserGroups,
  checkIfUserIsLoggedIn,
  checkIfUserHasOrganizerAccess,
  refreshIdToken,
  completeNewPasswordChallenge,
  getEventIdsWithAdminAccess,
  getEventIdsWithScoringAccess
};
