/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled } from '@mui/material/styles';

export default styled('div')(({ theme, pr, pl, pt, pb }) => {
  const { palette, borders, typography } = theme;

  const { borderRadius } = borders;
  const { size } = typography;
  const { text, dark } = palette;

  return {
    '& .ql-toolbar': {
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingRight: pr,
      paddingLeft: pl,
      paddingTop: pt,
      paddingBottom: pb
    },

    '& .ql-container': {
      // borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`
      border: 'none',
      paddingRight: pr,
      paddingLeft: pl,
      paddingTop: pt,
      paddingBottom: pb
    },

    '& .ql-editor': {
      '& p': {
        fontSize: size.md,
        color: dark.main
      },

      '& ul li': {
        color: dark.main,
        fontSize: size.md
        // fontWeight: 400,
        // marginLeft: '-15px'
      },

      '& ol li': {
        color: dark.main,
        fontSize: size.md
        // fontWeight: 400,
        // marginLeft: '-15px'
      },

      '& li:before': {
        paddingRight: '8px'
      }
    }
  };
});
